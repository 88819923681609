import { Document, Packer, Paragraph, Table, TableRow, TableCell, WidthType, PageBreak, AlignmentType, TableLayoutType, StringContainer } from 'docx'
import { MinutesToHoursMinutes } from './MinutesToHoursMinutes.ts'

export function GetSessionData(sessionTimes:any, length:any){
        let tableData:any = []

        //filter out null abstracts
        let times = sessionTimes.filter(t => t.abstract)
        
        let headerRow = 
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({text:"Time"})],
                    }),
                    new TableCell({
                        children: [new Paragraph({text: "Presenters"})],
                    }),
                    new TableCell({
                        children: [new Paragraph({text: "Advisors"})],
                    }),
                    new TableCell({
                        children: [new Paragraph({text: "Abstract Title"})],
                    })
                ]
            })

        tableData.push(headerRow)

        times.map((t) => {
            // if(t.abstract.title !== "null"){console.log(t.abstract.title)}
            const time:string = MinutesToHoursMinutes(t.time)
            const title:string = JSON.stringify(t.abstract.title)
            //if (isUndefined(title)) {console.log(t)}
            const filter:string = title
            const rows:TableRow = new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({text: time})],
                    }),
                    new TableCell({
                        children: [new Paragraph({text: peopleString(t.abstract.presenters)})],
                    }),
                    new TableCell({
                    children: [new Paragraph({text: peopleString(t.abstract.advisors)})],
                    }),
                    new TableCell({
                        children: [new Paragraph({text: `${title}`})], //t.abstract?.title
                    })
                ]
            })
            tableData.push(rows)
        })

        const table = new Table({
            width: {
                size: 9000,
                type: WidthType.DXA,
            },
            indent: {
                size: 900,
                type: WidthType.DXA,
            },
            alignment:  AlignmentType.CENTER, //'center'
            layout: TableLayoutType.AUTOFIT , //'autofit'
            rows: tableData
        })
        //console.log(table)
        return table
}

function peopleString(people){
    if(!people) return "";
    const peopleString = people.map(p => p.first_name + " " + p.last_name).join(", ");
    return peopleString;
}