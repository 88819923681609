import React, { useEffect, useState, useReducer } from "react";

import { useToast, 
	Heading, 
	Center,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button, } from "@chakra-ui/react";

import Header from "./components/Header";
import Footer from "./scenes/Footer";
import PageManager from "./scenes/PageManager";
import MainContainer from "./components/MainContainer";
import { CLIENT_ID } from "./constants";
import axios from "axios";

import { UserContext, UserDispatchContext } from "./UserContext";
import { useQuery } from "@tanstack/react-query";

export default function App() {

	function userReducer(state, action){
		if (action.type === 'updated'){
			if(!action.user) return null;
			localStorage.setItem('user', JSON.stringify(action.user));
			return {...action.user}
		}
		else if(action.type === "logout"){
			localStorage.removeItem('user');
			localStorage.removeItem('bearerToken');
			return null;
		}
	}
	const [user, userDispatch] = useReducer(userReducer, null);
	const [bearerToken, setBearerToken] = useState(null);
	const [backendOnline, setBackendOnline] = useState(true);

	const toast = useToast();

	const { data: sessionExpired } = useQuery({
		queryKey: ["sessionExpired"],
		// the ternary gets rid of an off-by-one render error related to side effects. useEffect is so much fun.
		queryFn: async () => !!axios.defaults.headers.common["Authorization"] ? (await axios.get("/api/session-expiration")).data?.session_expired : false,
		enabled: !!bearerToken,
		refetchInterval: 5 * 60 * 1000, // refresh every n milliseconds
	})

	function siteRoot(){
        const host = window.location.hostname;
        let clientId = CLIENT_ID.LOCALHOST;
        let uri = "http://localhost:3000";
        if (host?.includes("test5")){
            clientId = CLIENT_ID.BETA;
            uri = "http://test5.cpms.byu.edu:3000";
        } else if (host?.includes("src") || host?.includes("web3")){
            clientId = CLIENT_ID.PRODUCTION;
            uri = "https://src.byu.edu";
        }
		return {
			uri,
			clientId,
		}
	}

    /**
     * Logs in a user
     * See: https://developer.byu.edu/docs/consume-api/use-api/choose-grant-type/authorization-code-grant-type
     */
	function loginUser() {
		const {uri, clientId} = siteRoot();
		window.location = `https://api.byu.edu/authorize?response_type=code&client_id=${clientId}&redirect_uri=${uri}&scope=openid`;
	}

	function logoutUser() {
		delete axios.defaults.headers.common["Authorization"];
		localStorage.removeItem('bearerToken');
		localStorage.removeItem('user');
		userDispatch({type: "logout"});
		setBearerToken(null);
		// window.location = siteRoot().uri;
	}

	function setupAxiosInterceptors() {
		axios.interceptors.response.use(
			function (response) {
				// Handle successful response
				return response;
			},
			function (error) {
				if (error.response && error.response.data instanceof Blob) {
					// If the response is a Blob, read it as text
					return new Promise((resolve, reject) => {
						const reader = new FileReader();
						reader.onload = () => {
							const text = reader.result;
							toastError(text);
							reject(error);
						};
						reader.onerror = () => {
							toastError("Error reading error response.");
							reject(error);
						};
						reader.readAsText(error.response.data);
					});
				} else {
					// Handle non-blob error response
					const description = error.response.data.text ?? error.response.data.message ?? error.response.data.detail;
					console.log(description)
					toastError(description);
					return Promise.reject(error);
				}
			}
		);
	}
	
	function toastError(description) {
		toast({
			title: "The server returned an error.",
			description,
			status: "error",
			duration: 5000,
			isClosable: true,
		});
	}
	

	async function authorizeIfPossible(){
		var urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has("code")) {
			const code = urlParams.get("code");
			const res = await axios.post("/api/authorize", {code})
			const { bearerToken, user } = res.data;
			localStorage.setItem('bearerToken', bearerToken);
			localStorage.setItem('user', JSON.stringify(user));
			window.location = siteRoot().uri
		}
		loadAuth()
		setToggle(false)
	}

	async function checkBackendHealth(){
		try {
			await axios.get("/api/setting");
			setBackendOnline(true);
		}
		catch {
			setBackendOnline(false);
		}
	}

	function loadAuth(){
		const bearer = localStorage.getItem('bearerToken')
		// setting axios auth in two places is not on accident, it needs to happen to avoid errors on page load
		axios.defaults.headers.common["Authorization"] = `Bearer ${bearer}`;
		setBearerToken(bearer);
		const user = JSON.parse(localStorage.getItem('user'));
		userDispatch({type: "updated", user});
	}

	useEffect(() => {
		checkBackendHealth();
		setupAxiosInterceptors();
		authorizeIfPossible();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(sessionExpired === true){
			logoutUser()
			setToggle(true)
		}
	}, [sessionExpired])

	useEffect(() => {
		if(bearerToken){
			axios.defaults.headers.common["Authorization"] = `Bearer ${bearerToken}`;
		}
	}, [bearerToken])

	const [bearerTokenToggle, setToggle] = useState(false)
	const handleClose = () => {
		setToggle(false)
	}

	if (!backendOnline)
		return (
			<MainContainer>
				<Center>
					<Heading as="h1" size="md">
						Server is offline.
					</Heading>
				</Center>
			</MainContainer>
		);
	
	return (
		<UserContext.Provider value={user}>
			<UserDispatchContext.Provider value={userDispatch}>
				<Header loginUser={loginUser} logoutUser={logoutUser} />
				<Modal isCentered blockScrollOnMount={false} isOpen={bearerTokenToggle} onClose={handleClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Session Expired</ModalHeader>
						<ModalCloseButton />
						<ModalBody>You have been logged out. Please log in again.</ModalBody>
						<ModalFooter>
							<Button variant="ghost" onClick={handleClose}>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<PageManager />
				<Footer />
		</UserDispatchContext.Provider>
		</UserContext.Provider>

	);
}