import React from "react";

import { Select } from "@chakra-ui/react";

export default function RightField({
	rights = [],
	selected = -1,
	onRightChange = null,
	hide = false,
	style = {},
}) {
	if (hide) return "";
	return (
		<Select
			style={style}
			w="12em"
			onChange={(e) => onRightChange(parseInt(e.target.value) || -1)}
			value={selected}
		>
			<option disabled>Select a Right</option>
			{Object.keys(rights).map((key) => (
				<option key={rights[key].id} value={rights[key].id}>
					{rights[key].name}
				</option>
			))}
		</Select>
	);
}
