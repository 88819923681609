import { List, Spinner, Box, Container, PinInputDescendantsProvider } from "@chakra-ui/react";
import React from "react";

import RoomItem from "./RoomItem";

export default function ListRoom({
	rooms = [],
	showEdit = false,
	onDeleteClick = null,
	loading = false,
}) {
	if (loading)
		return (
			<Box>
				<Spinner color="blue.500" size="md" />
			</Box>
		);
	return (
		<Container border={"2px solid black"} overflowY={"auto"} maxH={"300px"}>
		<List>
			{
			rooms.map((room, i) => (
				<RoomItem
					key={i}
					id={room.id}
					showEdit={showEdit}
					number={room.number}
					building={room.building.short_name}
					onDeleteClick={onDeleteClick}
				/>
			))}
		</List>
		</Container>
	);
}
