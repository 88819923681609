import { HStack, Table, Thead, Tbody, Tr, Th, Td, Spinner, Box, Avatar } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import {APP_PAGES} from "../../../page-constants";

import FacultyListSkeleton from "./FacultyListSkeleton"

export default function ListFaculty({
	faculties = [],
	departments = [],
	loading = false,
})
{
	const navigate = useNavigate();

	// if (loading)
	// 	return (
	// 		<Box>
	// 			<Spinner color="blue.500" size="md" />
	// 		</Box>
	// 	);

	return (
		<Box overflow="hidden auto" overflowX="auto" h="30em" w="100%" flexGrow="1">
		<Table variant="unstyled">
			<Thead className="sticky-table-header">
				<Tr>
					<Th>Name</Th>
					<Th>Net Id</Th>
					<Th>Email</Th>
					<Th>Department</Th>
					<Th>Location</Th>
					<Th>Status</Th>
				</Tr>
			</Thead>
			<Tbody>
			{loading && (<FacultyListSkeleton />)}
			{!loading && (faculties.map((faculty, i) => (
				<Tr
					key={faculty.id}
					_hover={{filter: "brightness(85%)", cursor:"pointer"}}
					bgColor="white"
					transition="0.25s"
					onClick={() => navigate(APP_PAGES.FACULTY_EDIT.path.replace(":id", faculty.id))}
				>
					<Td>
						<HStack>
							<Avatar size="sm" mr={2} name={faculty.first_name + " " + faculty.last_name} />
							<Box>{faculty.first_name}&nbsp;{faculty.last_name}</Box>
						</HStack>
						</Td>
					<Td>{faculty.net_id}</Td>
					<Td>{faculty.email}</Td>
					<Td whiteSpace="nowrap">{departments.find(dep => dep.id === faculty.department_id)?.name ?? "Error"}</Td>
					<Td maxWidth="27ch" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">{faculty.location}</Td>
					<Td>{faculty.status}</Td>
				</Tr>
			)))}
			</Tbody>
		</Table>
		</Box>
	);
}
