import React, { useState } from "react";

import {
	FormControl,
	Input,
	FormErrorMessage,
	FormLabel,
	Stack,
    Button,
} from "@chakra-ui/react";

import Modal from "../../../components/Modal";
import { isValidEmail } from "../../../utils";

export default function CoauthorForm({onAdd}){

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [formError, setFormError] = useState(false);

    function validForm(){
        return firstName && lastName && isValidEmail(email)
    }

    function addPerson(){
        if(!validForm()){
            setFormError(true)
            return;
        }
        onAdd({
            first_name: firstName,
            last_name: lastName,
            email: email,
            full_name: `${firstName} ${lastName}`
        })
        setFirstName("");
        setLastName("");
        setEmail("");
    }

    return (
        <Stack padding={3}>
            <FormControl isRequired isInvalid={formError && firstName === ""}>
                <FormLabel>First Name</FormLabel>
                <Input
                    value={firstName}
                    placeholder="Enter a first name..."
                    onChange={(e) => setFirstName(e.target.value)}
                ></Input>
                <FormErrorMessage>You must enter a first name</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={formError && lastName === ""}>
                <FormLabel>Last Name</FormLabel>
                <Input
                    value={lastName}
                    placeholder="Enter a last name..."
                    onChange={(e) => setLastName(e.target.value)}
                ></Input>
                <FormErrorMessage>You must enter a last name</FormErrorMessage>
            </FormControl>
            <FormControl
                id="email"
                isRequired
                isInvalid={formError && !isValidEmail(email)}
            >
                <FormLabel>Email</FormLabel>
                <Input
                    value={email}
                    placeholder="Enter an email..."
                    onChange={(e) => setEmail(e.target.value)}
                ></Input>
                <FormErrorMessage>You must enter a valid email</FormErrorMessage>
            </FormControl>
            <Button
                onClick={addPerson}
                colorScheme="green"
                w="16ch"
            >
                Add Co-Author
            </Button>
        </Stack>
    )
}