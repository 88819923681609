import React from "react";

import { Stack, } from "@chakra-ui/react";

import DepartmentField from "../../../components/DepartmentField";
import SearchField from "../../../components/SearchField";

export default function SearchFields({
	departments = [],
	selectedDepartmentId = -1,
	onDepartmentChange = null,
    onSearchChange = null,
	placeholder = "Filter by department",
	debounceDuration = 300,
}) {
	return (
		<>
		<Stack spacing={3}>
			<DepartmentField
				placeholder={placeholder}
				departments={departments}
				selected={selectedDepartmentId}
				onDepartmentChange={onDepartmentChange}
				label="Filter by Department"
			/>
			<SearchField
				placeholder="Enter search text..."
				helperText="Search by presenter, advisor, session name, abstract title, etc."
				onSearchChange={onSearchChange}
				debounceDuration={debounceDuration}
				label="Search Sessions"
				w="16em"
				/>
		</Stack>
		</>
	);
}
