import React from "react";

import { ItemTypes } from "./ItemTypes";
import { useDrag } from "react-dnd";
import { ListItem } from "@chakra-ui/layout";

export default function AbstractItem({
    id = -1,
    index = 0,
    user = "",
    degreeLevel = "",
    advisors = [],
    title = ""
}){
  const [{ isDragging }, drag] = useDrag({
    item: { id, index, type: ItemTypes.SCHEDULED, },
    type: ItemTypes.SCHEDULED,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <ListItem
      style={{
        whiteSpace: "nowrap",
        opacity: isDragging ? 0.5 : 1,
        cursor: "pointer",
      }}
      ref={drag}
    >
      {`${user} (${degreeLevel}) (${advisors.join(', ')}) "${title}"`}
    </ListItem>
  );
};