import React from "react";

import { ItemTypes } from "./ItemTypes";
import { useDrag } from "react-dnd";
import { Box } from "@chakra-ui/layout";

export default function UnscheduledItem({
	index = -1,
	owner = "",
	degreeLevel = "",
	title = "",
	style = {},
	advisors = "",
}) {
	const [{ isDragging }, drag] = useDrag({
		item: { index, type: ItemTypes.UNSCHEDULED },
		type: ItemTypes.UNSCHEDULED,
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	});
	return (
		<Box
			style={{
				...style,
				whiteSpace: "nowrap",
				opacity: isDragging ? 0.5 : 1,
				cursor: "pointer",
			}}
			ref={drag}
		>
			{`${owner.last_name}, ${owner.first_name} (${degreeLevel.name}) (${advisors.map(ad => ad.first_name + " " + ad.last_name).join(', ')}) "${title}"`}
		</Box>
	);
}
