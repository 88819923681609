import React from "react";

import { 
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Stack,
	Heading,
	Skeleton,
	Tag,
	Flex,
	IconButton,
	Button,
	Box,
	StackDivider,
	Link,
	Text,
} from '@chakra-ui/react'

import {
	EditIcon
} from "@chakra-ui/icons"

import { formatDateForDisplay, minutesToTimeString } from "../../../utils";

import { InlineTex, Tex } from "react-tex";

import AbstractMenu from "./AbstractMenu";
import { useNavigate } from "react-router-dom";
import APP_PAGES from "../../../page-constants";

export default function Abstract({
	loading = false,
	abstract = {},
	canDelete = false,
	canEdit = false,
	canShare = false,
	canTransfer = false,
	judgeComments = [],
	canViewJudgeNames = false,
	onOpenSubmenu,
}) {

	const navigate = useNavigate();

	if (loading) return <Skeleton h="60em" w="35em" my={4}/>;

    const body = abstract.body.replace(/\n/g, "<br />");

	return (
	<Card variant="outline" size="lg" maxWidth="75ch">
	<CardHeader pb={0}>
		<Flex justifyContent="space-between" alignItems="center">
			<Heading size="md">
				Abstract Details
				{canEdit && (
					<IconButton
						icon={<EditIcon />}
						color="orange.700"
						size="sm"
						variant="link"
						onClick={() => navigate(`${APP_PAGES.ABSTRACT_EDIT.redirect}/${abstract.id}`)}
					/>
				)}
			</Heading>
			<AbstractMenu
				abstract={abstract}
				canEdit={canEdit}
				canDelete={canDelete}
				canShare={canShare}
				canTransfer={canTransfer}
				onOpenSubmenu={onOpenSubmenu}
			/>
		</Flex>
	</CardHeader>
	
	<CardBody>
		<Stack divider={<StackDivider />} spacing={4}>
			<Box>
				<Heading size="xs" textTransform="uppercase">
					Title
				</Heading>
				{abstract.title}
			</Box>

			<Box>
				<Heading size="xs" textTransform="uppercase">
					Department
				</Heading>
				{abstract.department.name}
				{abstract.data_science_session && (
				<Tag
					mx={1}
					colorScheme="red"
					borderRadius="full"
				>
					Data Science
				</Tag>
				)}
			</Box>

			<Box>
				<Heading size="xs" textTransform="uppercase">
					Presenter{abstract.presenters.length !== 1 ? "s" : ""}
				</Heading>
				{abstract.presenters.map(p => p.full_name).join(", ")}
				{canShare && (
					<Button
						display="block"
						color="blue.500"
						size="sm"
						variant="link"
						onClick={() => onOpenSubmenu("presenter")}
					>
						Add Presenter
					</Button>
				)}

			</Box>
			<Box>
				<Heading size="xs" textTransform="uppercase">
					Advisor{abstract.advisors.length > 1 ? "s" : ""}
				</Heading>
				{abstract.advisors.map(a => a.full_name).join(", ")}
			</Box>

			{(canShare || abstract.coauthors.length > 0) && (
				<Box>
				<Heading size="xs" textTransform="uppercase">
					Co-Author{abstract.coauthors.length !== 1 ? "s" : ""}
				</Heading>
				{abstract.coauthors.map(c => c.full_name).join(", ")}
				{canShare && (
					<Button
						display="block"
						variant="link"
						color="blue.500"
						size="sm"
						onClick={() => onOpenSubmenu("coauthor")}
					>
						Add Co-Author
					</Button>
				)}
				</Box>
			)}

			<Box>
				<Heading size="xs" textTransform="uppercase">
					Degree
				</Heading>
				{abstract.degree_level.name}
			</Box>
			
			{abstract.session && (
			<Box>
				<Heading size="xs" textTransform="uppercase">
					Presentation
				</Heading>
				{abstract.session.name} at {minutesToTimeString(abstract.start_time)}
			</Box>
			)}

			<Box>
				<Heading size="xs" textTransform="uppercase">
					Duration
				</Heading>
				{abstract.three_minute_session ? "3" : "15"} minutes
			</Box>

			<Box>
				<Heading size="xs" textTransform="uppercase">
					Abstract
				</Heading>
				<InlineTex texContent={body} />
			</Box>

			{(judgeComments?.length ?? 0) > 0 && (
				<Box>
					<Heading size="xs" textTransform="uppercase">
						Judge Comments
					</Heading>
					<Text fontSize="xs" color="gray">
						Only presenters and advisors can see this
					</Text>
					{judgeComments.map((c, index) => (
						<Text key={c.id}>
							{ canViewJudgeNames ? c.judge?.full_name ?? `Unknown Judge` : `Comment ${index+1}`}:&nbsp;
							{c.comment}
						</Text>
					))}
				</Box>
			)}
		</Stack>
	</CardBody>

	<CardFooter color="gray.600" fontSize="0.9rem">
		Submitted {formatDateForDisplay(new Date(abstract.date_submitted))}
		<br />
		Modified {formatDateForDisplay(new Date(abstract.date_modified))}
	</CardFooter>
	</Card>
	);
}
