import React from "react";
import PropTypes from "prop-types";

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
} from "@chakra-ui/react";

export default function Model({
	children,
	title = "",
	openName = "",
	saveName = "",
	w = "",
	save,
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	function handleSaveClick(onClose, save) {
		if (save()){
			onClose();
		}
	}

	return (
		<>
			<Button mt={4} size="md" colorScheme="green" onClick={onOpen} w={w}>
				{openName}
			</Button>

			<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{title}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>{children}</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="green"
							mr={3}
							onClick={() => handleSaveClick(onClose, save)}
						>
							{saveName}
						</Button>
						<Button variant="ghost" onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

Modal.propTypes = {
	title: PropTypes.string,
	openName: PropTypes.string,
	saveName: PropTypes.string,
	save: PropTypes.func,
};
