import React, { useState, useEffect } from "react";

import {
	Button,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	useToast,
	Spinner,
	Box,
} from "@chakra-ui/react";
import Feature from "../../../components/Feature";
import { settingsQuery } from "../../../api/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { editSetting } from "../../../api/mutationFns";
import { simpleDateFormat } from "../../../utils";

export default function OpenSRC() {
	const toast = useToast();
	const queryClient = useQueryClient()
    const { isPending, data: settings } = useQuery(settingsQuery)
	const editApplicationOpenMutation = useMutation({
		mutationFn: (value) => editSetting("application_open", value),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: settingsQuery.queryKey })
			toast({
				title: "Acceptance date updated",
				description: "The application acceptance period has been updated.",
				status: "success",
				duration: 5000,
				isClosable: true,
			})
		}
	})

	// "YYYY-MM-DD" format
	const [newDate, setNewDate] = useState(settings?.application_open);
	useEffect(() => {
		if(!newDate) setNewDate(settings?.application_open)
	}, [settings, newDate])

	return isPending ? <Spinner /> : (
		<Feature>
			<Stat>
				<StatLabel fontSize={20}>Application Status: {new Date(settings.application_open) <= new Date() ? "Open" : "Closed"}</StatLabel>
				<StatNumber fontSize="1.2em">{simpleDateFormat(settings.conference_date)}</StatNumber>
				<StatHelpText>This date is when abstract submissions begin.</StatHelpText>
			</Stat>
			<Box>
				<input type="date" value={newDate} onChange={(val) => {
					setNewDate(val.target.value);
				}} />
				<br />
				<Button mt="2" colorScheme="green" size="sm" onClick={() => editApplicationOpenMutation.mutate(newDate)}>
					Update Conference Date
				</Button>
			</Box>
		</Feature>
	);
}
