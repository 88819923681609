import React, { useState } from "react";

import {
	FormControl,
	Input,
	FormErrorMessage,
	FormLabel,
	Stack,
    Button,
    HStack,
} from "@chakra-ui/react";

import Modal from "../../../components/Modal";
import { isValidEmail } from "../../../utils";

export default function UserForm({
    onCancel,
    onSubmit,
    submitLabel,
    isSubmitting = false,
    colorScheme = "green",
}){
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [netId, setNetId] = useState("");
    const [email, setEmail] = useState("");

    const [formError, setFormError] = useState(false);

    function validForm(){
        return firstName && lastName && netId && isValidEmail(email)
    }

    async function submit(){
        if(!validForm()){
            setFormError(true)
            return;
        }
        const success = await onSubmit({
            first_name: firstName,
            last_name: lastName,
            net_id: netId,
            email: email,
            full_name: `${firstName} ${lastName}`
        })
        // only treat "false" as false, not null
        if(success !== false){
            setFirstName("");
            setLastName("");
            setNetId("");
            setEmail("");
        }
    }

    return (
        <Stack padding={3} justifyContent="flex-end">
            <FormControl isRequired isInvalid={formError && firstName === ""}>
                <FormLabel>First Name</FormLabel>
                <Input
                    value={firstName}
                    placeholder="Enter a first name..."
                    onChange={(e) => setFirstName(e.target.value)}
                ></Input>
                <FormErrorMessage>You must enter a first name</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={formError && lastName === ""}>
                <FormLabel>Last Name</FormLabel>
                <Input
                    value={lastName}
                    placeholder="Enter a last name..."
                    onChange={(e) => setLastName(e.target.value)}
                ></Input>
                <FormErrorMessage>You must enter a last name</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={formError && netId === ""}>
                <FormLabel>Net ID</FormLabel>
                <Input
                    value={netId}
                    placeholder="Enter a net id..."
                    onChange={(e) => setNetId(e.target.value.toLowerCase())}
                ></Input>
                <FormErrorMessage>You must enter a net id</FormErrorMessage>
            </FormControl>
            <FormControl
                id="email"
                isRequired
                isInvalid={formError && !isValidEmail(email)}
            >
                <FormLabel>Email</FormLabel>
                <Input
                    value={email}
                    placeholder="Enter an email..."
                    onChange={(e) => setEmail(e.target.value)}
                ></Input>
                <FormErrorMessage>You must enter a valid email</FormErrorMessage>
            </FormControl>
            <HStack style={{marginLeft: "auto"}}>
                <Button
                    onClick={onCancel}
                    isDisabled={isSubmitting}
                >
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    colorScheme={colorScheme}
                    w="17ch"
                    isLoading={isSubmitting}
                >
                    {submitLabel ?? "Submit"}
                </Button>
            </HStack>
        </Stack>
    )
}