/* These functions are used by React-Query to make changes to app data */


import axios from "axios";

export async function editDepartment(department){
    department.coordinator_id = department.coordinator_id ?? department.coordinator.id;
    const res = await axios.put('/api/department/' + department.id, department);
    return res.data;
}

// Faculty

export async function deleteFaculty(facultyId) {
    const res = await axios.delete(`/api/faculty/${facultyId}`)
    return res.data;
}

export async function createFaculty(faculty) {
    const res = axios.post("/api/faculty", faculty)
    return (await res).data;
}

export async function editFaculty(faculty) {
    const res = await axios.patch(`/api/faculty/${faculty.id}`, faculty);
    return res.data;
}

// Sessions

export async function editSession(session){
    const res = await axios.patch(`/api/session/${session.id}`, session)
    return res.data; 
}

export async function createSession(session){
    const res = await axios.post(`/api/session`, session)
    return res.data; 
}

// Abstracts

export async function editAbstract(abstract) {
    const res = await axios.patch(`/api/abstract/${abstract.id}`, abstract)
    return res.data;
}

// Users

export async function editUser(user){
    const res = await axios.patch(`/api/user/${user.id}`, user)
    return res.data
}

// Settings

export async function editSetting(key, value){
    const res = await axios.patch(`/api/setting/${key}`, { value })
    return res.data
}