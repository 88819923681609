import React from "react";

import { ButtonGroup, Button } from "@chakra-ui/react";

export default function Buttons({
	showEdit = false,
    showNew = false,
	onNewClick = null,
	onEditClick = null,
	onSaveClick = null,
	onCancelClick = null,
}) {
	return (
		<ButtonGroup mt={3}>
			{(!showEdit && !showNew) && (
				<>
					<Button colorScheme="green" onClick={onNewClick}>
						Create New
					</Button>
					<Button colorScheme="yellow" onClick={onEditClick}>
						Edit
					</Button>
				</>
			)}
			{(showEdit || showNew) && (
				<>
					<Button colorScheme="green" onClick={onSaveClick}>
						Save Changes
					</Button>
					<Button colorScheme="red" onClick={onCancelClick}>
						Cancel
					</Button>
				</>
			)}
		</ButtonGroup>
	);
}
