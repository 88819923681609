import { Skeleton, Box, Heading, Table, Thead, Th, Tbody, Tr, Td, Button, Link, ButtonGroup } from "@chakra-ui/react";
import AdminSideBar from "../../components/AdminSideBar";
import {useState, useContext} from "react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { rightsQuery, sessionChangeRequestsQuery } from "../../api/queries";
import { useMutation, useQuery } from "@tanstack/react-query";

export default function SessionChangeRequests(){

    const { isPending: rightsPending, data: rights } = useQuery(rightsQuery);
    const { isPending: changeRequestsPending, data: changeRequests } = useQuery(sessionChangeRequestsQuery);
    const loading = rightsPending || changeRequestsPending;

    // track which buttons have been clicked but are waiting for a response
    const [pendingRequests, setPendingRequests] = useState(new Set())
    const [processedRequests, setProcessedRequests] = useState(new Set())

    const user = useContext(UserContext)

    const changeRequestMutation = useMutation({
        mutationFn: async (request, approved) => {
            const updatedPending = new Set(pendingRequests).add(request.id)
            setPendingRequests(updatedPending)
            const res = await axios.delete(`/api/session-change-request/${request.id}`, {data: {approved}})
            updatedPending.delete(request.id)
            setPendingRequests(updatedPending)
            request.status = approved ? "Approved" : "Denied"
            const updatedProcessed = new Set(processedRequests).add(request)
            setProcessedRequests(updatedProcessed);
            return res.data;
        }
    })

    function getDescription(changeTypeName, value){
        if(changeTypeName === "data"){
            if(value) return "Change to data science session"
            else return "Revert from data science back to regular session"
        }
        else if(changeTypeName === "3min"){
            if(value) return "Change from 15 minute to 3 minute session"
            else return "Change from 3 minute to 15 minute session"
        }
        return "Unknown change request type"
    }

    // only show change requests that are relevant to the person
    // (don't worry the backend handles authorization stuff)
    function filteredChangeRequests(){
        return [...changeRequests, ...processedRequests].filter(r => r.abstract.department.id === user.faculty?.department_id || user?.right_id === rights.admin.id)
    }

    function isPending(id){
        return pendingRequests.has(id)
    }

    if(loading){
        return <AdminSideBar>
            <Heading size="md" as="h2">
             Session Change Requests
            </Heading>
            <Box py={2}>
                <Skeleton />
            </Box>
        </AdminSideBar>
    }

    const noChangeRequestsMessage = "There are currently no pending change requests in your department."
    const noDepartmentMessage = "You have department coordinator rights but are not associated with a specific department. Contact the site admin at sciencetech@byu.edu to resolve this issue."

    if(filteredChangeRequests().length === 0){
        return <AdminSideBar>
            <Heading size="md" as="h2">
                Session Change Requests
            </Heading>
            <Box py={2}>
                {user.faculty ? noChangeRequestsMessage : noDepartmentMessage}
            </Box>
        </AdminSideBar>
    }

    return (
        <AdminSideBar>
            <Heading size="md" as="h2">
                Session Change Requests
            </Heading>
            <Table variant="simple" mt={4}>
                <Thead>
                    <Tr>
                        <Th>Student Name</Th>
                        <Th>Abstract</Th>
                        <Th>Department</Th>
                        <Th>Request Type</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredChangeRequests().map(r => (
                    <Tr key={r.id}>
                        <Td>
                            {r.abstract.owner.full_name}
                        </Td>
                        <Td>
                            <Link color="blue.400" as={RouterLink} className="text-link" to={`/abstract/detail/${r.abstract.id}`}>
                                {r.abstract.title}
                            </Link>
                        </Td>
                        <Td>
                            {r.abstract.department.name}
                        </Td>
                        <Td>
                            {getDescription(r.request_type, r.requested_value)}
                        </Td>
                        <Td>
                            {processedRequests.has(r) && (
                                <Button colorScheme={r.status === "Approved" ? "blue" : "red"} disabled>{r.status}</Button>
                            )}
                            {!processedRequests.has(r) && (
                                <ButtonGroup>
                                    <Button colorScheme="blue" disabled={isPending(r.id)} onClick={() => changeRequestMutation.mutate(r, true)}>Approve</Button>
                                    <Button colorScheme="red" disabled={isPending(r.id)} onClick={() => changeRequestMutation.mutate(r, false)}>Deny</Button>
                                </ButtonGroup>
                            )}
                        </Td>
                    </Tr>
                    ))}
                </Tbody>
            </Table>
        </AdminSideBar>
    );
}