import React, { useContext } from "react";

import { Link } from "react-router-dom";

import APP_PAGES from "../../page-constants";


import NavLinks from "./components/NavLinks";
import { UserContext } from "../../UserContext";

export default function Header({ loginUser = null, logoutUser = null }) {
	const user = useContext(UserContext)

	return (
		<byu-header>
				<span slot="site-title">
					<Link className="mimic-byu-header-a" to={APP_PAGES.HOMEPAGE.path}>
						Student Research Conference
					</Link>
				</span>

			<byu-user-info slot="user">
				<button className="mimic-byu-header-a" slot="login" onClick={loginUser}>
					Sign In
				</button>
				<button
					className="mimic-byu-header-a"
					slot="logout"
					onClick={logoutUser}
				>
					Logout
				</button>
				{user && <span slot="user-name">{user.full_name}</span>}
			</byu-user-info>
			<byu-menu slot="nav" collapsed>
				<NavLinks />
			</byu-menu>
		</byu-header>
	);
}
