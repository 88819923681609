import React from "react";

import {Box} from "@chakra-ui/react";

export default function Scroll({ style={}, children, scrollStart = 5, numItems = 0, width="100%", height="100%", forceOverflowAuto=false}) {
	
	if(forceOverflowAuto){
		style = {
			overflow: "auto",
			...style,
		}
	}

	else{
		style = {
			overflowY: "visible",
			...style
		};

		if (numItems > scrollStart){
			style = {
				overflowY: "scroll",
				overflowX: "hidden",
				...style
			};
		}
	}

	return <Box style={style} w={width} h={height}>{children}</Box>;
}
