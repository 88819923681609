import React from "react";

import { Flex } from "@chakra-ui/react";

import Session from "./Session";
import SessionSkeleton from "./SessionSkeleton";

export default function SessionList({
	sessions = [],
	canEdit = false,
	loading = false,
    searchText = "",
    showWinnerCheckboxes = false,
    onRefresh,
}) {

	if (loading) {
		return (
			<Flex flexWrap="wrap" pt={5}>
				{Array.from({ length: 2 }).map((_, i) => {
					return (
						<SessionSkeleton
							key={i}
						/>
					);
				})}
			</Flex>
		);

	}
	return (
		<Flex flexWrap="wrap" pt={5}>
			{sessions
                .map((session, i) => {
				return (
					<Session
						key={i}
						session={session}
                        searchText={searchText}
						canEdit={canEdit}
                        onRefresh={onRefresh}
                        showWinnerCheckboxes={showWinnerCheckboxes}
					/>
				);
			})}
		</Flex>
	);
}
