import { List, Spinner, Box } from "@chakra-ui/react";
import React from "react";

import BuildingItem from "./BuildingItem";

export default function ListBuildings({
	buildings = [],
	showEdit = false,
	onDeleteClick = null,
	loading = false,
}) {
	if (loading)
		return (
			<Box>
				<Spinner color="blue.500" size="md" />
			</Box>
		);
	return (
		<List>
			{buildings.map((building, i) => (
				<BuildingItem
					key={i}
					id={building.id}
					showEdit={showEdit}
					name={building.name}
					shortName={building.short_name}
					onDeleteClick={onDeleteClick}
				/>
			))}
		</List>
	);
}
