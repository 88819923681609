import React from "react";

import {
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	StatArrow,
	Spinner,
} from "@chakra-ui/react";
import Feature from "../../../components/Feature";

export default function AbstractsByYear({
	yearCount = 0,
    dayCount = 0,
	loading = false,
    year = 0
}) {
	return (
		<Feature height="100%">
			<Stat>
				<StatLabel fontSize={20}>Abstracts {year}</StatLabel>
				{!loading ? (
					<>
						<StatNumber>{yearCount}</StatNumber>
                        <StatHelpText>
							<StatArrow type="increase" />
							+{dayCount} Today
						</StatHelpText>
					</>
				) : (
					<Spinner color="blue.500" size="sm" />
				)}
			</Stat>
		</Feature>
	);
}
