import { FormControl, Divider, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import BuildingField from "../../../components/BuildingField";
import NumberField from "../../../components/NumberField";
//use this list of rooms for march update
//const rooms = [1002, 1004, 1006, 1008, 1010, 1012, 1013, 1018, 1020, 1101, 1102, 1103, 1104, 1105, 1106, 1107, 1115, 1117, 1119, 1125, 1126, 1127, 2002, 2004, 2009, 2011, 2104, 2105, 2107, 2110, 2111, 2112, 2113, 2114, 3012, 3016, 3024, 3104, 3106, 3108, 3112]

let rooms = [
	{value: 1002},{value: 1004}, {value: 1006}, {value: 1008}, {value: 1010}, {value: 1012}, {value: 1013}, {value: 1018}, {value: 1020}, {value: 1101}, {value: 1102}, {value: 1103}, {value: 1104}, {value: 1105}, {value: 1106}, {value: 1107}, {value: 1115}, {value: 1117}, {value: 1119}, {value: 1125}, {value: 1126}, {value: 1127}, {value: 2002}, {value: 2004}, {value: 2009}, {value: 2011}, {value: 2104}, {value: 2105}, {value: 2107}, {value: 2110}, {value: 2111}, {value: 2112}, {value: 2113}, {value: 2114}, {value: 3012}, {value: 3016}, {value: 3024}, {value: 3104}, {value: 3106}, {value: 3108}, {value: 3112}
]

export default function AddRoom({
	buildings = [],
	buildingId = -1,
	roomNumber = 1000,
	numberError = false,
	buildingError = false,
	onNumberChange = null,
	onBuildingChange = null,
}) {
	return (
		<Stack spacing={3}>
			<Heading as="h3" size="sm">
				Add New
			</Heading>
			<FormControl isInvalid={numberError} isRequired>
			<NumberField
				defaultValue={roomNumber}
				minValue={1000}
				maxValue={4999}
				onNumberChange={onNumberChange}
			/>
			</FormControl>
			<FormControl isInvalid={buildingError} isRequired>
				<BuildingField
					buildings={buildings}
					selectedBuilding={buildingId}
					onBuildingChange={onBuildingChange}
				/>
			</FormControl>
			<Divider />
		</Stack>
	);
}

// Use this to replace manual entry of room numbers
/*
<Select placeholder="Select Room Number" w="14em" onChange={(e) => {childToParent(e.target.value)}}>
					{
						rooms.map((room) => {
							return <option key={room} value={room}>{room}</option>
						})
					}
				</Select>
				*/
