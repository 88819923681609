import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import { Heading, Stack, Spinner } from "@chakra-ui/react";

import SessionList from "./components/SessionList";
import Buttons from "./components/Buttons";

import MainContainer from "../../components/MainContainer";

import SearchFields from "./components/SearchFields";
import { sortSessions, strToDate } from "../../utils";
import { UserContext } from "../../UserContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { departmentsQuery, rightsQuery, sessionsQueryFactory, settingsQuery } from "../../api/queries";
import SessionGrid from "./components/SessionGrid";

export default function Sessions() {

	const { department } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const listView = (searchParams.get("view") ?? "list").toLowerCase() !== "grid";
	const [selectedDepartmentId, setDepartmentId] = useState(-1)
	const [searchText, setSearchText] = useState('')

	const user = useContext(UserContext)
	const loggedIn = !!user

	const navigate = useNavigate();
	
	const queryClient = useQueryClient()
    const { isPending: departmentsPending, data: departments } = useQuery(departmentsQuery)
	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)
	const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)
	const { isPending: sessionsPending, data: sessions } = useQuery({
		...sessionsQueryFactory(settings?.relevant_year),
		enabled: !!settings,
	})
		
	const loading = departmentsPending
					|| rightsPending
					|| settingsPending
					|| sessionsPending

	useEffect(() => {

		function autoSelectDepartmentFilter(){
			if(department === undefined) return
			if(!departments) return
			// remove the hyphens
			// special case: 'data-science' in url is equivalent to 'college'
			let departmentUnSlugged = department.replaceAll('-', ' ');
			if(departmentUnSlugged.toLowerCase() === "data science"){
				departmentUnSlugged = "college";
			}
			// find the id of the selected department
			const new_department = departments.find(dep => dep.name.toLowerCase() === departmentUnSlugged.toLowerCase());
			if(new_department){
				const dep_id = new_department.id;
				setDepartmentId(dep_id)
				//dispatch(setDepartmentId(dep_id));
			}
			else{
				navigate(`/sessions/departments/all?${searchParams.toString()}`, {replace: true});
				setDepartmentId(-1)
				//dispatch(setDepartmentId(-1));
			}
		}

		autoSelectDepartmentFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [department, departments]);

	if(loading) return <Spinner />

	const conference_date = settings ? strToDate(settings.conference_date) : undefined;
	const today = new Date();

	function changeRouteToNewDepartment(department_id){
		if(!departments) return
		setDepartmentId(department_id)
		//dispatch(setDepartmentId(department_id));
		const new_department = departments.find(dep => dep.id === department_id);
		if(new_department) {
			navigate(`/sessions/departments/${new_department.name.toLowerCase().replaceAll(' ', '-')}?${searchParams.toString()}`, {replace: true});
		}
		else{
			navigate(`/sessions/departments/all?${searchParams.toString()}`, {replace: true});
		}
	}

	const canEdit = loggedIn && (user?.right_id >= rights?.department_coordinator.id);

	function filterBySearchText(session, searchText){
		const lowercaseSearch = searchText.toLowerCase()
        return session.name.toLowerCase().includes(lowercaseSearch)
            || (session.chair && session.chair.full_name.toLowerCase().includes(lowercaseSearch))
            || session.judges.some(j => j.full_name.toLowerCase().includes(lowercaseSearch))
            || session.location.toLowerCase().includes(lowercaseSearch)
            || session.abstracts.some(abstract => (
				(abstract.presenters && abstract.presenters.some(p => p.full_name.toLowerCase().includes(lowercaseSearch)))
				|| (abstract.user && abstract.user.full_name.toLowerCase().includes(lowercaseSearch))
				|| (abstract.advisors && abstract.advisors.some(ad => ad.full_name.toLowerCase().includes(lowercaseSearch)))
				|| abstract.title.toLowerCase().includes(lowercaseSearch)

		));
    }

	const filteredSessions = sessions ? sessions
		.filter(s => selectedDepartmentId < 0 || selectedDepartmentId === undefined || s.department_id === selectedDepartmentId)
		.filter(s => filterBySearchText(s, searchText)) : [];

	const filteredSortedSessions = filteredSessions.sort(sortSessions)

	// grid view
	if(!listView) return (
		<MainContainer>
			<Heading as="h1" size="lg">
				Sessions
			</Heading>
			<Stack spacing={3} my="1.5em">
				<Buttons
					listView={listView}
					onViewChange={(newView) => setSearchParams({view: newView})}
					canCreateTime={loggedIn && user?.right_id === rights.admin.id}
					canCreateSession={loggedIn && user?.right_id === rights.admin.id}
					canScheduleAbstracts={
						loggedIn && user?.right_id >= rights.department_coordinator.id
					}
				/>
				<SearchFields 
                    departments={departments}
                    selectedDepartmentId={selectedDepartmentId}
                    onDepartmentChange={(value) => {
						changeRouteToNewDepartment(value);
					}}
                    //onSearchChange={(value) => dispatch(setSearchText(value))}
					onSearchChange={(value) => {
						setSearchText(value);
					}}
					debounceDuration={300}
					placeholder={"All departments"}
                />
				<SessionGrid
					sessions={filteredSortedSessions}
				/>
			</Stack>
		</MainContainer>
	)

	// list view
	return (
		<MainContainer>
			<Heading as="h1" size="lg">
				Sessions
			</Heading>
			<Stack spacing={3} my="1.5em">
				<Buttons
					listView={listView}
					onViewChange={(newView) => setSearchParams({view: newView})}
					canCreateTime={loggedIn && user?.right_id === rights.admin.id}
					canCreateSession={loggedIn && user?.right_id === rights.admin.id}
					canScheduleAbstracts={
						loggedIn && user?.right_id >= rights.department_coordinator.id
					}
				/>
				{// users can currently view any department they want
				}
				<SearchFields 
                    departments={departments}
                    selectedDepartmentId={selectedDepartmentId}
                    onDepartmentChange={(value) => {
						changeRouteToNewDepartment(value);
					}}
                    //onSearchChange={(value) => dispatch(setSearchText(value))}
					onSearchChange={(value) => {
						setSearchText(value);
					}}
					debounceDuration={300}
					placeholder={"All departments"}
                />
				<SessionList
					sessions={filteredSortedSessions}
					searchText={searchText.toLowerCase()}
					selectedDepartmentId={selectedDepartmentId}
					loading={loading}
					//TODO: check statement below to see if it is even necessary. Modding the options component allowed this function to work.
					canEdit={canEdit}
					showWinnerCheckboxes={canEdit && conference_date <= today}
					onRefresh={() => queryClient.invalidateQueries({queryKey: sessionsQueryFactory(settings.relevant_year).queryKey})}
				/>
			</Stack>
		</MainContainer>
	);
}