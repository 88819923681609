import { useState } from "react";

export function useFormState(initialState) {
	const [form, setForm] = useState(initialState);
	return [
		form,
		function (obj = null) {
			if (obj === null) {
                setForm(initialState);
			} else {
				setForm((prevState) => ({
					...prevState,
					...obj
				}));
			}
		},
	];
}