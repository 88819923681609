import { Input, Box, Button, Card, CardBody, CardHeader, Center, Flex, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import MainContainer from "../../components/MainContainer";
import { useQuery } from "@tanstack/react-query";
import { sessionsQueryFactory, settingsQuery } from "../../api/queries";
import { useEffect, useState } from "react";
import { sortSessions } from "../../utils";
import axios from "axios";
import { CheckIcon } from "@chakra-ui/icons";

export default function JudgeComments() {

    const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)
    const { isPending: sessionsPending, data: sessions } = useQuery({
        ...sessionsQueryFactory(settings?.relevant_year),
        enabled: !!settings,
    })


    const loading = settingsPending
                    || sessionsPending;
    
    const [selectedSessionId, setSelectedSessionId] = useState("")
    const [selectedJudgeId, setSelectedJudgeId] = useState("")
    const [comments, setComments] = useState([])
    const [passwordVerified, setPasswordVerified] = useState(false)
    const [password, setPassword] = useState("")
    const [submitted, setSubmitted] = useState({})
    const [submitting, setSubmitting] = useState({})

    const toast = useToast()
    
    useEffect(() => {
        setComments({});
        setSubmitted({});
        setSubmitting({});
    }, [selectedSessionId])

    if(loading) return <Spinner />

    const selectedSession = sessions.find(s => s.id.toString() === selectedSessionId.toString()) ?? null;
    const selectedJudge = selectedSession?.judges.find(j => j.id.toString() === selectedJudgeId)

    async function submitComment(abstractId){
        if(!comments[abstractId]) return
        if(!selectedJudgeId){
            toast({
                title: "No Judge Selected",
                description: "Select your name from the dropdown above",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        try {
            setSubmitting({...submitting, [abstractId]: true})
            const payload = {
                comment: comments[abstractId],
                abstract: abstractId,
                judge_id: selectedJudgeId,
            }
            const res = await axios.post('/api/judge-comment', {...payload, judge_password: password})
            toast({
                title: "Comment Submitted",
                description: "Your comment was successfully submitted.",
                status: "success",
                duration: 10000,
                isClosable: true,
            })
            setSubmitted({...submitted, [abstractId]: true})
        }
        catch {
            // handled by axios globally
        }
        finally {
            setSubmitting({...submitting, [abstractId]: false})
        }
    }

    async function verifyPassword(){
        const res = await axios.post("/api/verify-judge-password/", {judge_password: password})
        if(res.data.verified){
            setPasswordVerified(true)
            toast({
                title: "Password Verified",
                description: "You can now leave judge comments.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        }
        else{
            toast({
                title: "Incorrect Password",
                description: "Please enter the correct password or contact sciencetech@byu.edu for help.",
                status: "error",
                duration: 10000,
                isClosable: true,
            })
        }
    }

    if(!passwordVerified) return (
        <MainContainer>
            <Flex flexDirection="column" mx="auto">
                <form onSubmit={e => e.preventDefault()}>
                    <Heading>Authorization</Heading>
                    <Text mb={3}>
                        Enter the judge password.
                        This password should have been sent to you via email before the conference.
                    </Text>

                    <label htmlFor="judge-password-field">Password</label>
                    <Input type="password" id="judge-password-field" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <Button type="submit" colorScheme="green" my={3} onClick={verifyPassword}>
                        Authorize
                    </Button>
                </form>
            </Flex>
        </MainContainer>
    )


    return (
    <>
    <MainContainer>
        <Flex flexDirection="column" mx="auto">
            <Heading>Judge Comments</Heading>
            <Text py={2} color="gray.700">
                Use this page to give feedback on your session's presentations.<br/>
                If for any reason you make a mistake when submitting, just re-submit.<br/>
                Only the latest submission will be used.
            </Text>

            <HStack>
                <label htmlFor="select-session" style={{whiteSpace: "nowrap"}}>Select Your Session: </label>
                <Select
                    placeholder="Select Session"
                    value={selectedSessionId ?? ""}
                    onChange={(e) => { 
                        setSelectedSessionId(e.target.value) 
                        setSelectedJudgeId("")
                    }}
                    id="select-session">
                    { sessions.sort(sortSessions).map(session => (
                        <option key={session.id} value={session.id}>{session.name}</option>
                    ))}
                </Select>
            </HStack>

            { selectedSession && (
                <HStack mt={2}>
                    <label htmlFor="select-judge" style={{whiteSpace: "nowrap"}}>Select Your Name: </label>
                    <Select
                        placeholder="Select Name"
                        value={selectedJudgeId ?? ""}
                        onChange={(e) => setSelectedJudgeId(e.target.value)}
                        id="select-session">
                        { selectedSession.judges.map(judge => (
                            <option key={judge.id} value={judge.id}>{judge.full_name}</option>
                        ))}
                    </Select>
                </HStack>
            )}

            { selectedJudge && selectedSession?.abstracts
                .sort((a,b) => a.session_position - b.session_position)
                .map(abstract => (
                <Box mt={4} key={abstract.id}>
                    <Card maxWidth="80ch" variant="outline">
                        <CardHeader pb={0}>
                            <Heading size="md" whiteSpace="wrap">
                                {submitted[abstract.id] && (
                                    <CheckIcon color="green.600" mr={2} />
                                )}
                                {abstract.presenters.map(p => p.full_name).join(", ")}
                            </Heading>
                        </CardHeader>
                        <CardBody>
                            <Text>{abstract.title}</Text>
                            <Textarea
                                mt={4}
                                placeholder="Enter judge comments"
                                value={comments[abstract.id] ?? ""}
                                onChange={e => setComments({...comments, [abstract.id]: e.target.value})}
                                />
                            { selectedSession && (
                                <Button
                                    mt="4"
                                    ml="auto"
                                    colorScheme="green"
                                    disabled={
                                        !selectedJudgeId
                                        || !selectedSessionId
                                        || submitting[abstract.id]
                                        || !comments[abstract.id]
                                    }
                                    onClick={() => submitComment(abstract.id)}>
                                    {submitting[abstract.id] ? "Submitting..." : submitted[abstract.id] ? "Update Comment" : "Submit Comment"}
                                </Button>
                            )}
                        </CardBody>
                    </Card>
                </Box>
            ))}
        </Flex>
    </MainContainer>
    </>
    )
}