import React from "react";

import { Stack, HStack, Text, Divider, Link } from "@chakra-ui/react";

export default function SessionInfo({
	chair = "",
	judges = [],
	location = "",
	livestream_link = "",
}) {
	return (
		<Stack spacing={1}>
			<HStack>
				<Text fontWeight="bold">Chair:</Text>
				<Text>{chair}</Text>
			</HStack>
            <Divider/>
			{judges.map(j => (
				<HStack key={j.id}>
					<Text fontWeight="bold">Judge {judges.indexOf(j)+1}:</Text>
					<Text>{j.full_name}</Text>
				</HStack>
			))}
			{/* placeholder judges */}
			{judges.length === 0 && (
			<HStack>
				<Text fontWeight="bold">Judge 1:</Text>
				<Text>None</Text>
			</HStack>
			)}
			{judges.length < 2 && (
			<HStack>
				<Text fontWeight="bold">Judge 2:</Text>
				<Text>None</Text>
			</HStack>
			)}
            <Divider/>
            <Divider/>
			<HStack>
				<Text fontWeight="bold">Location:</Text>
				<Text>{location}</Text>
			</HStack>
			{livestream_link &&
			<>
			<Divider/>
			<HStack>
				<Text fontWeight="bold" whiteSpace="nowrap">Video Stream:</Text>
				<Link w="100%" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" color="blue.400" href={livestream_link}>{livestream_link}</Link>
			</HStack>
			</>
			}
            <Divider/>
		</Stack>
	);
}
