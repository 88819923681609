import { Link, Center, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Alert, AlertIcon } from "@chakra-ui/react";
import { generateTimeSlots, minutesToTimeString } from "../../../utils";
import { Link as RouterLink } from "react-router-dom";
import APP_PAGES from "../../../page-constants";


export default function SessionGrid({
    sessions = [],
}){

    // sessions that start later than this many minutes after midnight
    // are considered "late sessions"
    const earlyThresholdMinutes = 10 * 60

    const earlySessions = sessions
        .filter(s => s.start_time < earlyThresholdMinutes)
        .filter(s => s.minutes_per_abstract > 5) // filter out 3 minute abstracts (they make the table too dang long!)

    const lateSessions = sessions
        .filter(s => s.start_time >= earlyThresholdMinutes)
        .filter(s => s.minutes_per_abstract > 5)

    const sessionBlocks = [
        {
            name: "Early Sessions",
            sessions: earlySessions,
            times: [...new Set(
                earlySessions.map(s => generateTimeSlots(s)
                    .map(ts => ts.time))
                    .flat())
                ]
        },
        {
            name: "Late Sessions",
            sessions: lateSessions,
            times: [...new Set(
                lateSessions.map(s => generateTimeSlots(s)
                    .map(ts => ts.time))
                    .flat())
                ]
        }
    ]

    return (
        <>
        <Alert status="warning" w="fit-content">
            <AlertIcon />
            Grid view does not display 3-minute sessions
        </Alert>
        {sessionBlocks.map(sb => (
            <div key={sb.name}>
            <Center>
                <Heading as="h2" size="lg">{sb.name}</Heading>
            </Center>
            <TableContainer>
                <Table variant="striped" colorScheme="blue">
                    <Thead>
                        <Tr>
                            <Th />
                            {sb.sessions.map(session => (
                                <Th key={session.id}>
                                    <Link as={RouterLink} to={APP_PAGES.SESSION_DETAILS.path.replace(":sessionId", session.id)}>
                                        {session.name}
                                    </Link>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {/* 
                            This one really taxed the ol' noggin.

                            For each session-time, render the abstract
                            from that session at that time.

                            This is O(N^2). Optimization will be left
                            as an exercise for the reader.
                        */}
                        {sb.times.map(t => (
                            <Tr key={t}>
                                <Td>{minutesToTimeString(t)}</Td>
                                {sb.sessions
                                    .map(session => session.abstracts)
                                    .reduce((accumulator, sessionAbstracts) => (
                                        [...accumulator, sessionAbstracts.find(abstract => abstract.start_time === t)]
                                    ), [])
                                    .map((abstract, index)=> (
                                        <Td key={index}>
                                            {abstract && (
                                                <Link color="blue.900" as={RouterLink} to={APP_PAGES.ABSTRACT_DETAIL.path.replace(":id", abstract.id)}>
                                                    {abstract?.presenters?.map(p => p?.full_name)?.join(", ")}
                                                </Link>
                                            )}
                                        </Td>
                                    )
                                )}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            </div>
        ))}
        </>
    )

}