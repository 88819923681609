import { Text, Box } from "@chakra-ui/react";

export function decoratedRankText(number){
    if(number === null || number === undefined) return ""
    if(number === 1) return <Text as="span" color="yellow.700">1<sup>st</sup></Text>;
    else if(number === 2) return <Text as="span" color="gray.600">2<sup>nd</sup></Text>;
    else if(number === 3) return <Text as="span" color="orange.800">3<sup>rd</sup></Text>;
    else if(number%10 === 1 && number%100 !== 11) return <Text as="span" color="blue.600">{number.toString()}<sup>st</sup></Text>;
    else if(number%10 === 2 && number%100 !== 12) return <Text as="span" color="blue.600">{number.toString()}<sup>nd</sup></Text>;
    else if(number%10 === 3 && number%100 !== 13) return <Text as="span" color="blue.600">{number.toString()}<sup>rd</sup></Text>;
    else return <Text as="span" color="blue.600">{number.toString()}<sup>th</sup></Text>;
}

export function rankText(number){
    if(number === null || number === undefined) return ""
    else if(parseInt(number) === 1) return "1st";
    else if(parseInt(number) === 2) return "2nd";
    else if(parseInt(number) === 3) return "3rd";
    else if(parseInt(number)%10 === 1 && parseInt(number)%100 !== 11) return `${number.toString()}st`;
    else if(parseInt(number)%10 === 2 && parseInt(number)%100 !== 12) return `${number.toString()}nd`;
    else if(parseInt(number)%10 === 3 && parseInt(number)%100 !== 13) return `${number.toString()}rd`;
    else return `${number.toString()}th`;
}

// place means 1st, 2nd, etc.
export default function WinnerIcon({place}){
    return (
        <Box p={2}>
            <Text fontSize="2xl">{decoratedRankText(place)}</Text>
        </Box>
    )
}