/* 
THIS PAGE NO LONGER WORKS
BUT IT MIGHT BE USEFUL IN THE NEAR-ISH FUTURE.
*/

import React, { useState } from "react";

import {
	Heading,
	Stack,
	Flex,
	Box,
	FormControl,
	FormLabel,
	Button,
	Input,
	InputGroup,
	InputRightElement,
	useToast,
	Spinner,
} from "@chakra-ui/react";

import FacultyCard from "./components/FacultyCard";

import AdminSideBar from "../../components/AdminSideBar";

import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { departmentsQuery, facultyMembersQuery, facultyStatusesQuery } from "../../api/queries";

export default function Facultyimporter() {

	const { isPending: isDepartmentsPending, data: departments } = useQuery(departmentsQuery)
	const { isPending: isStatusesPending, data: statuses } = useQuery(facultyStatusesQuery)
	const { isPending: isFacultyMembersPending, data: facultyMembers } = useQuery(facultyMembersQuery)
	const isLoading = isDepartmentsPending
						|| isStatusesPending
						|| isFacultyMembersPending

	const queryClient = useQueryClient()

	const facultyMutation = useMutation({
		mutationFn: createFaculty,
		onSuccess: () => queryClient.invalidateQueries({queryKey: facultyMembersQuery.queryKey})
	})

	const [newFaculty, setNewFaculty] = useState([]);
	const [show, setShow] = React.useState(false)
	const handleClick = () => setShow(!show)

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	function RetriveNewFaculty(e){
		e.preventDefault();
		setLoading(true);
		const credentials = {
			"net-id": username,
			"byu-pass": password,
		}
		axios.post("/api/faculty-import", credentials)
			.then(response => {
				setNewFaculty(response.data);
			})
			.catch(response => {
				console.error(response)
			})
			.finally(() => setLoading(false))
	}

	function RemoveSuggestion(id){
		setNewFaculty(newFaculty.filter(f => f.byu_id !== id));
	}

	function createFaculty(id) {
		const faculty = newFaculty.find(f => f.byu_id === id);
		const payload = {
			first_name: faculty.first_name,
			last_name: faculty.last_name,
			net_id: null, // temp
			email: faculty.email,
			department_id: faculty.department_id,
			location: faculty.location,
			status: faculty.status,
		}
		facultyMutation.mutate(payload)
	}

	function saveAll(){
		newFaculty.forEach(f => createFaculty(f.byu_id))
	}

	if(isLoading) return <Spinner />

	return (
		<AdminSideBar w="100%">
			<form onSubmit={(e) => RetriveNewFaculty(e)}>
				<Flex gap={8} m={4} direction={{base: "column", md: "row"}}>
				<Stack spacing={3} maxWidth="25rem">
					<Heading as="h2" size="md">
						Import Faculty
					</Heading>
					<Box>
						Enter your BYU credentials below to retrieve
						the latest faculty from the university's official records.
					</Box>
					<FormControl>
						<FormLabel htmlFor="net-id">Net Id</FormLabel>
						<Input
							type="text"
							value={username}
							id=
							"net-id"
							placeholder="Enter net id"
							onChange={(e) => setUsername(e.target.value)}
						/>
					</FormControl>
					<FormControl>
						<FormLabel htmlFor="password">Password</FormLabel>
						<InputGroup size='md'>
						<Input
							pr='4.5rem'
							type={show ? 'text' : 'password'}
							placeholder='Enter password'
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<InputRightElement width='4.5rem'>
							<Button h='1.75rem' size='sm' onClick={handleClick}>
							{show ? 'Hide' : 'Show'}
							</Button>
						</InputRightElement>
					</InputGroup>
					</FormControl>
					<Button 
						type="submit" 
						colorScheme="green"
						isLoading={loading}
						mt={2}
						loadingText="Retrieving Faculty Data..."
					>
						Retrieve Data
					</Button>
					<Box color="gray.500">
						Your BYU account must be authorized to access the Dremio dataset for this to work.
					</Box>
				</Stack>
				<Stack>
					{newFaculty.length > 0 && (
					<Heading as="h2" size="md" w="100%" display="flex" justifyContent="space-between">
						Suggested Additions
						<Button ml="auto" colorScheme="green" onClick={() => saveAll()}>Save All</Button>
					</Heading>
					)}
				{newFaculty.map(fac => (
						<FacultyCard
							key={fac.byu_id}
							firstName={fac.first_name}
							lastName={fac.last_name}
							email={fac.email}
							statusId={fac.status_id}
							departmentId={fac.department_id}
							netId={fac.net_id}
							statuses={statuses}
							departments={departments}
							hireDate={fac.hire_date}
							id={fac.byu_id}
							onSave={(id) => createFaculty(id)}
							onClose={(id) => RemoveSuggestion(id)}
						></FacultyCard>
					))}
					</Stack>
				</Flex>
			</form>
		</AdminSideBar>
	);
}
