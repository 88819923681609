import React from "react";

import { Link } from "react-router-dom";
import { Button, Stack } from "@chakra-ui/react";

import APP_PAGES from "../../../page-constants";

export default function Buttons({
	userOnlyAbstracts,
	onToggleUserOnly,
	loggedIn,
    showCreate = false,
	applicationOpen = true,
	userIsAdminOrDepCoord = false,
}) {
	return (
		<Stack direction="row" spacing={3} align="center">
			{loggedIn && showCreate && (applicationOpen || userIsAdminOrDepCoord) && (
				<Link to={APP_PAGES.ABSTRACT_CREATE.path}>
					<Button size="md" colorScheme="green">
						Create New
					</Button>
				</Link>
			)}
		</Stack>
	);
}
