import React from "react";

import { Box, Flex } from "@chakra-ui/react";

const MainContainer = ({ children }) => {
	return (
		<Flex padding="1.5em" justify="center" bg="grey.100" h="100%" flex="1">
			<Box bg="white" w="100%" p="1.5em" display="flex" flexDirection="column">
				{children}
			</Box>
		</Flex>
	);
};

export default MainContainer;
