import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    Button,
    ModalBody,
	Tag,
	TagLabel,
	TagCloseButton,
	Wrap,
	WrapItem,
	Divider,
    Avatar,
    IconButton,
    Skeleton,
    Text,
} from "@chakra-ui/react";

import { useState, useEffect } from "react";

import axios from "axios";
import UserForm from "./UserForm";

import {
    AddIcon
} from "@chakra-ui/icons"

export default function ChangeOwnerManager({
    isOpen,
    onClose,
    onSave,
    isSubmitting = false,
    abstract = {},
    canTransfer = false,
}){

    const ownerCandidates = abstract.presenters?.filter(p => p.net_id !== abstract.owner.net_id) ?? [];
    const [selectedOwnerId, setSelectedOwnerId] = useState(-1);

    const handleSave = (id) => {
        if(id === -1) return;
        setSelectedOwnerId(-1);
        onSave(id);
    }

    const handleClose = () => {
        setSelectedOwnerId(-1);
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>
                {selectedOwnerId > 0
                ? `Change Owner to ${abstract.presenters.find(p => p.id === selectedOwnerId).full_name}`
                : "Select New Owner"
                }
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Wrap my={3}>
                {ownerCandidates.length === 0 && (
                    <Text p={1}>
                        There are no other presenters to transfer ownership to.
                        <br />
                        Add a presenter in the Presenter Manager, and then you will be able to transfer ownership.
                    </Text>
                )}
                {ownerCandidates.map((presenter) => (
                    <WrapItem key={presenter.net_id}>
                        <Tag
                            size="lg"
                            borderRadius="full"
                            colorScheme={presenter.id === selectedOwnerId ? "green" : "blue"}
                            h="100%"
                            onClick={() => setSelectedOwnerId(presenter.id)}
                            cursor="pointer"
                            transition="0.1s"
                            _hover={{
                                filter: "saturate(1.5)"
                            }}
                        >
                            <Avatar
                                size="xs"
                                ml={-1}
                                mr={2}
                                colorScheme={presenter.id === selectedOwnerId ? "green" : "blue"}
                            />
                            <TagLabel>
                                {presenter.full_name} ({presenter.net_id})
                            </TagLabel>
                        </Tag>
                    </WrapItem>
                ))}
            </Wrap>


            </ModalBody>
            <ModalFooter>
            <Button variant='ghost' onClick={handleClose}>Cancel</Button>
            <Button
                colorScheme='green'
                mr={3}
                isLoading={isSubmitting}
                onClick={() => handleSave({id: selectedOwnerId})}
                isDisabled={isSubmitting || selectedOwnerId === -1}
            >
                Change Owner
            </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    )
}