import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import APP_PAGES from "../../../page-constants";

import { isPageActive } from "../../../utils";
import { UserContext } from "../../../UserContext";
import { useQuery } from "@tanstack/react-query";
import { settingsQuery } from "../../../api/queries";

export default function NavLinks() {

    const user = useContext(UserContext);
    const loggedIn = !!user; // cast to bool

    const { isPending: settingsPending, data: settings }= useQuery(settingsQuery);
    const relevantYear = settings?.relevant_year ?? "Current";

	const location = useLocation();

    let links = [];
    let dropdownLinks = [];

    const [instructionPages, setInstructionPages] = useState([])

    async function loadInstructionPages(){
        const res = await axios.get("/api/instructions");
        setInstructionPages(res.data.map(i => i.title));
    }

    useEffect(() => {
        loadInstructionPages()
    }, [])

    //Iterate through all the links in the LINKS array
    Object.keys(APP_PAGES).forEach((page, i) => {
        if (APP_PAGES[page].hasOwnProperty("loggedIn") && loggedIn === false)
            return;
        if (APP_PAGES[page].hasOwnProperty("rightId")) {
            if ((user?.right_id ?? 1) < APP_PAGES[page].rightId) return;
        }
        if (APP_PAGES[page].hasOwnProperty("navBarTitle")) {
            // the session page path is /sessions/departments/:department
            // but we want it to show as /sessions/departments/all
            // so the optional attribute default_path allows us
            // to specify what the URL param should be
            const path = APP_PAGES[page].default_path ? APP_PAGES[page].default_path : APP_PAGES[page].path
            const link = (
                <Link
                    key={i}
                    className={isPageActive(APP_PAGES[page], location) ? "active" : ""}
                    to={
                        path
                    }
                >
                    {APP_PAGES[page].navBarTitle.replace('$year', relevantYear)}
                </Link>
            )
            APP_PAGES[page].navBarDropdown === "Abstracts" ? dropdownLinks.push(link) : links.push(link);
        }
    });
    const abstractsDropdown = (
        <div className="dropdown" key="abstracts-dropdown">
            <button className="dropdown-btn">Abstracts</button>
            <div className="dropdown-child">
                {dropdownLinks.map(l => l)}
            </div>
        </div>
    )

    const instructionsDropdown = (
        <div className="dropdown" key="instructions-dropdown">
            <button className="dropdown-btn">Conference Instructions</button>
            <div className="dropdown-child">
                {/*
                    temporarily removing judge/chair pages
                    the judge/chair pages are slightly buggy
                    and we only need student instruction page this year
                    ["presenter", "judge", "chair"].map(name
                    also look into combining judge and chair pages
                */}
                {instructionPages.map(name => (
                    <Link
                        key={name}
                        to={`/instructions/${name}`}
                        style={{textTransform: "capitalize"}}
                    >
                        {name} Instructions
                    </Link>
                ))}
            </div>
        </div>	
    )
    links.splice(1, 0, abstractsDropdown);
    links.splice(3, 0, instructionsDropdown);
    return links;
}