import React, { useEffect, useState } from "react";

import SubmissionsByDepartment from "./SubmissionsByDepartment";
import Feature from "../../../components/Feature";
import { useQuery } from "@tanstack/react-query";
import { departmentsQuery } from "../../../api/queries";

export default function Submissions({ abstracts = [] }) {
	const departments = useQuery(departmentsQuery)

	const [data, setData] = useState({
		degree: [],
		department: [],
	});

	function findDepartmentData() {
		if(departments.isPending) return
        return departments.data.map(department => {
            const abstractCount = abstracts.filter(abstract => abstract.department.name.localeCompare(department.name) === 0).length;
            return {
                ...department,
                abstractCount
            }
        }).filter(department => department.id !== 1);
	}

	useEffect(() => {
		setData({ department: findDepartmentData() });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [abstracts, departments.data]);

	return (
		<Feature display="flex" w="100%">
			<SubmissionsByDepartment data={data.department} />
		</Feature>
	);
}
