import React, { useContext } from "react";
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	Flex,
	useDisclosure,
	Link,
	UnorderedList,
	ListItem,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import APP_PAGES from "../../page-constants";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { isPageActive } from "../../utils";
import { UserContext } from "../../UserContext";

export default function AdminSideBar({
    children = "",
	showSideBar = true,
}) {
    const location = useLocation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();
	const user = useContext(UserContext)

    const SidebarLinks = () => (
		Object.entries(APP_PAGES)
			.filter(([key, value]) => value.adminToolsTitle)
			.filter(([key, value]) => value.rightId <= user?.right_id)
			.map(([key, value]) => (
				<ListItem key={key}>
					<Link
						as={RouterLink}
						className={
							isPageActive(value, location) ? "active" : ""
						}
						to={value.path}
					>
						{value.adminToolsTitle}
					</Link>
				</ListItem>
			)
		)
	);

	return (
		<Flex bg="grey.100" flex="1" w="100%">
			{ showSideBar &&
			<Flex
				pt="3em"
				justify="center"
				w="50px"
				_hover={{ bg: "rgba(0,0,0,0.2)" }}
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				onClick={onOpen}
			>
				<HamburgerIcon ref={btnRef} w={4} h={6} title="expand admin tools" />
			</Flex>
			}
			{ !showSideBar && 
			<Flex
				pt="3em"
				justify="center"
				w="50px"
			/>
			}
			<Flex bg="grey.100" pt="1.5em" pb="1.5em" pr="1.5em" flex="1" w="100%">
				<Box bg="white" flex="1" padding="1.5em" w="0">
					{children}
				</Box>
			</Flex>
			{ showSideBar &&
			<Drawer
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay>
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader>Admin Tools</DrawerHeader>
						<DrawerBody>
							<UnorderedList listStyleType="none" spacing="0.25em" ml="0">
								<SidebarLinks />
							</UnorderedList>
						</DrawerBody>
					</DrawerContent>
				</DrawerOverlay>
			</Drawer>
			}
		</Flex>
	);
}
