import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    Button,
    ModalBody,
} from "@chakra-ui/react";

export default function AboutAbstract({
    isOpen,
    onClose,
    abstract = {},
}){
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>
                About Abstract
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                This abstract is owned by {abstract.owner?.full_name}
            </ModalBody>
            <ModalFooter>
                <Button variant='ghost' onClick={onClose}>Close</Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    )
}