import { Select } from "@chakra-ui/react";
import { rankText } from "../../Winners/components/WinnerIcon";

export function adjustedWinnersPerSession(session){
    const defaultWinners = 3; // higher than needed (most departments only need 1) just in case
    // CS 3-minute sessions have 3 winners, which is why this function exists.
    return session.minutes_per_abstract === 15 ? session.department.winners_per_session : defaultWinners;
}

export default function WinnerSelect({
    value = "",
    onChange = () => {},
    session,
}){
    return <Select
        value={value}
        onChange={onChange}
        maxW="18ch"
        minW="14ch"
        borderColor={"gray.600"}
    >
        <option value="">Not Winner</option>
        { [...Array(adjustedWinnersPerSession(session)).keys()].map(num => (
            <option key={num+1} value={num+1}>{rankText(num+1)} Place</option>
        )) }
    </Select>
}