import {
	FormControl,
	Divider,
	Heading,
	Stack,
	Select,
	FormLabel,
	useToast,
	Button,
	ButtonGroup,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Spinner,
	Center,
} from "@chakra-ui/react";

import React, { useContext, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DepartmentField from "../../components/DepartmentField";
import NameField from "../../components/NameField";
import { useFormState } from "../../hooks";
import axios from "axios";
import AdminSideBar from "../../components/AdminSideBar";
import {APP_PAGES} from "../../page-constants.js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { departmentsQuery, facultyMembersQuery, facultyStatusesQuery, rightsQuery } from "../../api/queries";
import { createFaculty, editFaculty, deleteFaculty } from "../../api/mutationFns";
import { UserContext } from "../../UserContext";

export default function FacultyForm(){
	const toast = useToast();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const user = useContext(UserContext)

	// determine if we're editing or creating based on the URL
	const editPath = APP_PAGES.FACULTY_EDIT.path.replace(":id", "");
	const editMode = location.pathname.includes(editPath);
	
	const { isPending: facultyStatusPending, data: statuses } = useQuery(facultyStatusesQuery);
	const { isPending: departmentsPending, data: departments } = useQuery(departmentsQuery)
	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)
	const { isPending: facultyPending, data: faculty } = useQuery({
		queryKey: ["faculty", params.id],
		queryFn: loadFacultyMember,
		enabled: editMode,
	})

	useEffect(() => initializeForm(faculty), [faculty])

	// for the delete modal
	const { isOpen, onOpen, onClose } = useDisclosure()

	const queryClient = useQueryClient()

	const [form, setForm] = useFormState({
		firstName: "",
		lastName: "",
		netId: "",
        email: "",
		departmentId: -1,
		location: "BYU",
		status: "",
	});

	const [formError, setFormError] = useFormState({
		firstName: false,
		lastName: false,
		department: false,
        email: false,
		location: false,
		status: false,
	});

	const createFacultyMutation = useMutation({
		mutationFn: async (faculty) => {
			await createFaculty(faculty);
			setFormError();
			setForm();
			navigate(APP_PAGES.FACULTY_LIST.path);
		},
		onSuccess: () => queryClient.invalidateQueries({queryKey: facultyMembersQuery.queryKey})
	})
	const editFacultyMutation = useMutation({
		mutationFn: async (faculty) => {
			await editFaculty(faculty);
			setFormError();
			setForm();
			navigate(APP_PAGES.FACULTY_LIST.path);
		},
		onSuccess: () => queryClient.invalidateQueries({queryKey: facultyMembersQuery.queryKey})
	})
	const deleteFacultyMutation = useMutation({
		mutationFn: async (faculty) => {
			await deleteFaculty(faculty);
			navigate(APP_PAGES.FACULTY_LIST.path);
		},
		onSuccess: () => queryClient.invalidateQueries({queryKey: facultyMembersQuery.queryKey})
	})

	async function loadFacultyMember(){
		if(isNaN(parseInt(params.id)) && editMode){
			toast({
				title: "Invalid URL.",
				description: "Parameter is not a valid ID.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return null;
		}
	
		const res = await axios.get(`/api/faculty/${params.id}`)
		return res.data;
	}

	function initializeForm(data){
		if(!data) return;
		setForm({
			firstName: data.first_name,
			lastName: data.last_name,
			netId: data.net_id,
			email: data.email,
			departmentId: data.department_id,
			location: data.location,
			status: data.status,
			id: data.id,
		});
	}
	
	const loading = (facultyPending && editMode)
					|| departmentsPending
					|| facultyStatusPending
					|| rightsPending

	if(loading) return <Spinner />
	
	function validateForm() {
		if (
			form.firstName !== "" &&
			form.lastName !== "" &&
			form.departmentId >= 0 &&
			form.location !== "" &&
			form.status !== ""
		)
			return true;
		const errors = {
			firstName: false,
			lastName: false,
			department: false,
			location: false,
			status: false,
			email: false,
		};
		if (form.firstName === "") errors.firstName = true;
		if (form.lastName === "") errors.lastName = true;
		if (form.departmentId < 0) errors.department = true;
		if (form.status < 0) errors.status = true;
		if (form.location === "") errors.location = true;
        if (form.email === "") errors.email = true;
		setFormError(errors);
		return false;
	}
	
	function handleSave() {
		if (!validateForm()) {
			toast({
				title: `Cannot ${editMode ? "update" : "add"} faculty.`,
				description: "Please fill out all required fields.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return;
		}
		if(editMode){
			editFacultyMutation.mutate({
				first_name: form.firstName,
				last_name: form.lastName,
				net_id: form.netId,
				email: form.email,
				department_id: form.departmentId,
				location: form.location,
				status: form.status,
				id: form.id,
			});
		}
		else{
			createFacultyMutation.mutate({
				first_name: form.firstName,
				last_name: form.lastName,
				net_id: form.netId,
				email: form.email,
				department_id: form.departmentId,
				location: form.location,
				status: form.status,
			});
		}
	}

	return (
		<>
		<AdminSideBar>
		<Center>
			<Stack spacing={3}>
				<Heading as="h3" size="sm">
					{editMode ? "Edit" : "Add New"} Faculty
					{loading && editMode && (<Spinner ml={2} size="sm" color="blue" />)}
				</Heading>
				<FormControl isInvalid={formError.firstName} isRequired>
					<NameField
						name={form.firstName}
						label="First Name"
						placeholder="Enter a first name..."
						onNameChange={(e) => setForm({firstName: e})}
					/>
				</FormControl>
				<FormControl isInvalid={formError.lastName} isRequired>
					<NameField
						name={form.lastName}
						label="Last Name"
						placeholder="Enter a last name..."
						onNameChange={(e) => setForm({lastName: e})}
					/>
				</FormControl>
				<FormControl>
					<NameField
						name={form.netId}
						label="Net ID"
						placeholder="Enter net id..."
						onNameChange={(e) => setForm({netId: e})}
					/>
				</FormControl>
				<FormControl isInvalid={formError.email} isRequired>
					<NameField
						name={form.email}
						label="Email"
						placeholder="Enter an email..."
						onNameChange={(e) => setForm({email: e})}
					/>
				</FormControl>
				<FormControl isInvalid={formError.department} isRequired>
					<FormLabel>Department</FormLabel>
					<DepartmentField
						departments={departments}
						selected={form.departmentId}
						onDepartmentChange={(e) => setForm({departmentId: e})}
					/>
				</FormControl>
				<FormControl isInvalid={formError.status} isRequired>
					<FormLabel>Faculty Status</FormLabel>
					<Select
					onChange={(e) => {
						setForm({status: e.target.value || ""})
					}}
					w="16em"
					value={form.status}>
						<option value="">Select Faculty Status</option>
						{statuses.map(status => (
							<option value={status} key={status}>{status.toLowerCase() === "guest" ? "Guest (judge or chair)" : status}</option>
						))}
					</Select>
				</FormControl>
				<FormControl isInvalid={formError.location} isRequired>
					<NameField
						name={form.location}
						label="Location"
						placeholder="Enter a location..."
						onNameChange={(e) => setForm({location: e})}
					/>
				</FormControl>
				<Divider />
				<ButtonGroup>
					<Button colorScheme="blue" onClick={() => navigate(-1)}>
						Go back
					</Button>
					<Button
						w="8em"
						colorScheme="green"
						onClick={handleSave}
					>
						{editMode ? "Save Changes" : "Create Faculty"}
					</Button>
					{editMode &&
						<Button
							w="7em"
							variant="link"
							colorScheme="red"
							onClick={onOpen}
						>
							<Text color='red.600'>Delete Faculty</Text>
						</Button>
					}
				</ButtonGroup>
			</Stack>
		</Center>

		</AdminSideBar>

		<Modal isOpen={isOpen} onClose={onClose}>
		<ModalOverlay />
		<ModalContent>
			<ModalHeader>Delete Confirmation</ModalHeader>
			<ModalCloseButton />
			<ModalBody>
			<Text>
				Are you sure you want to delete 
				<strong> {faculty?.first_name} {faculty?.last_name} </strong>
				from the faculty list?
				<br /><br />
				This faculty member	will no longer appear as an advisor on any abstracts.
				<br /><br />
				This cannot be undone.
				</Text>
			</ModalBody>

			<ModalFooter>
			<Button colorScheme='blue' mr={3} onClick={onClose}>
				Go back
			</Button>
			<Button variant='ghost' colorScheme="red" onClick={() => deleteFacultyMutation.mutate(faculty.id)}>Delete Permanently</Button>
			</ModalFooter>
		</ModalContent>
		</Modal>


		</>
	);
}
