import React from "react";
import AbstractForm from "../../components/AbstractForm";
import {useParams} from "react-router-dom";
import MainContainer from "../../components/MainContainer";
import { Center, Stack } from "@chakra-ui/react";

// quite pleased with myself for repurposing the AbstractCreate
// component for editing as well. Huge time saver
export default function AbstractEdit(){
    const {id} = useParams();
    return (
        <MainContainer>
            <Center>
                <Stack>
                    <AbstractForm editMode={true} abstractId={id}/>
                </Stack>
            </Center>
        </MainContainer>
    )
}