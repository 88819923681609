import React from "react";

import { ItemTypes } from "./ItemTypes";
import { useDrop } from "react-dnd";
import { ListItem } from "@chakra-ui/layout";

export default function UnassignedItem({ index = 0, onScheduledDrop = null }) {
	const [{ isOver }, drop] = useDrop({
		accept: [ItemTypes.SCHEDULED, ItemTypes.UNSCHEDULED],
		drop: (item) => {
			onScheduledDrop(item.type, item.index, index)},
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	});
	return (
		<ListItem
			style={{
				opacity: isOver ? 0.5 : 1,
			}}
			ref={drop}
		>
			Unassigned
		</ListItem>
	);
}
