import React from "react";

import { Link } from "react-router-dom";
import APP_PAGES from "../../../page-constants";

import { Button, ButtonGroup } from "@chakra-ui/react";

export default function SessionButton({
	canCreateSession = false,
	canCreateTime = false,
	canScheduleAbstracts = false,
	listView = true,
	onViewChange,
}) {
	return (
		<ButtonGroup size="md" spacing={3} direction="row">
			{canCreateSession && (
				<Link to={APP_PAGES.SESSION_CREATE.path}>
					<Button size="md" colorScheme="green">
						New Session
					</Button>
				</Link>
			)}
			{canScheduleAbstracts && (
				<Link to={APP_PAGES.SCHEDULE_ABSTRACTS.path}>
				<Button size="md" colorScheme="blue">
					Schedule Abstracts
				</Button>
			</Link>
			)}
			<Button variant="solid" colorScheme="blue" onClick={() => onViewChange && onViewChange(listView ? "grid" : "list")}>
				View as {listView ? "Grid" : "List"}
			</Button>
		</ButtonGroup>		
	);
}
