import React from "react";

import { ListItem, IconButton, Text } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

export default function BuildingItem({
    id = -1,
	showEdit = false,
	name = "",
	shortName = "",
    onDeleteClick = null,
}) {
	return (
		<ListItem display="flex" justifyContent="space-between">
			<Text>{name} ({shortName})</Text>
			{showEdit && (
				<IconButton
                    w={2}
                    h={4}
                    size="xs"
					colorScheme="red"
					aria-label="Delete building"
					icon={<DeleteIcon />}
                    onClick={() => onDeleteClick(id)}
				/>
			)}
		</ListItem>
	);
}
