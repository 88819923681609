import _ from "lodash";
import { matchPath } from "react-router-dom";
import hash from "object-hash";

export const isValidEmail = (emailAddress) => {
    let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(emailAddress).toLowerCase());
}

export const isPageActive = (page, location) => {
	return matchPath({
		path: page.path,
		exact: true,
		strict: false,
	},location.pathname);
};

export const addUniqueIdToItems = (items, label, count = 0) => {
	return items.map((item) => {
		return {
			...item,
			unique_id: label + (item.id + count),
		};
	});
};

export const filterItemsByFullName = (items, text) => {
	return items.filter((item) => {
		const fullName = item.first_name + " " + item.last_name;
		if (fullName.toLowerCase().includes(text.toLowerCase())) return true;
		return false;
	});
};

export const findItemById = (items, id, propName = "id") => {
	const filteredItems = items.filter((item) => item[propName] === id);
	if (filteredItems.length === 1) return filteredItems[0];
	return {};
};

export const findItemsById = (items, id, propName = "id") => {
	return items.filter((item) => item[propName] === id);
};

export const formatForDraggable = (obj, content = null) => {
	return {
		...obj,
		uniqueId: hash(obj),
		content: content === null ? `${obj.first_name} ${obj.last_name}` : content,
	};
};

export const sortByProp = (items, propName, reverse = false) => {
	if (reverse) return items.sort((a, b) => b[propName] - a[propName]);
	return items.sort((a, b) => a[propName] - b[propName]);
};

export const hasOnlyDigits = (string) => {
	const regexp = /^\d+$/;
	return regexp.test(string);
};

export const filterBySearchText = (items, text, contentFunc = undefined) => {
	return items.filter((item) => {
		if (contentFunc) {
			if (contentFunc(item).toLowerCase().includes(text.toLowerCase())) {
				return true;
			}
		} else {
			if (item.content.toLowerCase().includes(text.toLowerCase())) {
				return true;
			}
		}
		return false;
	});
};

// actual year at present time
export const getCurrentYear = () => {
	return new Date().getFullYear();
};

export const deleteItem = (items, item, itemsDeleted) => {
	const itemsCopy = [...items];

	itemsCopy.splice(itemsCopy.indexOf(item), 1);

	const itemsDeletedCopy = [...itemsDeleted];
	itemsDeletedCopy.push(item);

	return {
		items: itemsCopy,
		itemsDeleted: itemsDeletedCopy,
	};
};

export const move = (array, src, dest) => {
	//Create a new copy of the array
	const newArray = array.slice();
	//Remove the src item from the array
	const [removed] = newArray.splice(src, 1); //remove that index from the array
	//Insert the src item into the array
	newArray.splice(dest, 0, removed); //Insert into the new location
	return newArray;
};

export const moveToArray = (
	srcArray,
	destArray,
	srcIndex,
	destIndex,
	draggable,
	deleteCount = 0
) => {
	//Create a new copy of the source array
	const srcCopy = srcArray.slice();
	//Remove the src item from the array
	srcCopy.splice(srcIndex, 1);
	//Create a new copy of the destination array
	const destCopy = destArray.slice();
	//Insert the item into the array
	destCopy.splice(destIndex, deleteCount, draggable);

	return {
		srcArray: srcCopy,
		destArray: destCopy,
	};
};

export const sortNames = (
	items,
	byLastName = false,
	prop1 = "first_name",
	prop2 = "last_name"
) => {
	return items.sort((a, b) => {
		if (byLastName) {
			const nameA = _.get(a, prop2).toUpperCase();
			const nameB = _.get(b, prop2).toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			if (nameA === nameB) {
				const nameC = _.get(a, prop1).toUpperCase();
				const nameD = _.get(b, prop1).toUpperCase();
				if (nameC < nameD) {
					return -1;
				}
				if (nameC > nameD) {
					return 1;
				}
			}
			return 0;
		} else {
			const nameA = _.get(a, prop1).toUpperCase();
			const nameB = _.get(b, prop1).toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			if (nameA === nameB) {
				const nameC = _.get(a, prop2).toUpperCase();
				const nameD = _.get(b, prop2).toUpperCase();
				if (nameC < nameD) {
					return -1;
				}
				if (nameC > nameD) {
					return 1;
				}
			}
			return 0;
		}
	});
};

// this function formats a datetime with time zone adjustment
// update: this was a total waste of time and I should have used 
// tolocaledatestring instead
export const formatDateForDisplay = (date, showTime=true) => {
	const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
	];

	let dateDisplay = date.toLocaleString("en-US", {
		timeZone: "America/Denver",
	});

	dateDisplay = dateDisplay.replace(",", " ");

	const split = dateDisplay.split(" ");
	// split[0] looks like 2/4/2022
	const rawDate = split[0].split('/');
	const month = rawDate[0];
	const day = rawDate[1];
	const year = rawDate[2];

	const time = split[2];
	const am_pm = split[3];

	let result = monthNames[month - 1] + ' ' + day + ', ' + year;

	if(showTime) result += " at " +
	time.substring(0, split[2].length - 3) +
	" " +
	am_pm?.toLowerCase();

	return result;
};

// this function takes a date string "YYYY-MM-DD" (date only, no time) and formats it nicely
export const simpleDateFormat = (date, showWeekDay=false) => {
	const monthNames = ["January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
	];

	const weekDayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

	const split = date.split('-');
	const year = split[0];
	const month = split[1];
	const day = split[2];

	const weekday = showWeekDay ? weekDayNames[new Date(date).getDay()] + ', '  : ""

	return weekday + monthNames[month-1] + " " + day + ", " + year;
}

export const isValidNumber = (num) => {
	return /^\d+$/.test(num);
};

export const isValidTimeString = (timeString) => {
	let { hours, minutes, period } = parseTimeString(timeString);

	hours = parseInt(hours);
	minutes = parseInt(minutes);

	if (period !== "PM" && period !== "AM") return false;
	if (hours < 0 || hours > 12) return false;
	if (minutes < 0 || minutes > 59) return false;
	return true;
};

export const formatParsedTime = (hours, minutes, period) => {
	return `${hours}:${minutes} ${period}`;
};

export const parseTimeString = (timeString) => {
	const formatted = timeString.replace(" ", ":");
	const split = formatted.split(":");
	return {
		hours: split[0],
		minutes: split[1],
		period: split[2],
	};
};

/**
 * Converts minutes past midnight to a time string
 */
export const minutesToTimeString = (min) => {
	min = min % 1440;

	let hours = Math.floor(min / 60);
	let minutes = min - hours * 60;

	let period = "AM";
	if (hours >= 12) period = "PM";

	if (hours === 0) hours = 12;

	if (hours > 12) hours -= 12;
	return formatParsedTime(hours, minutes.toString().padStart(2, "0"), period);
};

/**
 * Converts a time string to minutes past midnight
 */
export const timeStringToMinutes = (timeString) => {
	let { hours, minutes, period } = parseTimeString(timeString);

	hours = parseInt(hours);
	minutes = parseInt(minutes);

	if (hours !== 12 && period === "PM") hours += 12;
	return hours * 60 + minutes;
};

export const sortTimes = (times) => times.sort((a, b) => a.time - b.time);

// for some horrible cursed reason
// js interprets dates with hypens as ~8 hours sooner than dates with slashes
// so we have to convert to slashes
// days like this are when I question all my life choices 
export const strToDate = (dateStr) => {
	if(dateStr === undefined || dateStr === null) throw new Error("dateStr should not be " + dateStr)
	return new Date(dateStr.replace(/-/g, '/'))
}

// takes a session with some abstracts
// and generates a list of time slots
// (so that there are blank time slots between abstracts if necessary)
export function generateTimeSlots(session){
	return Array.from(Array(session.num_slots).keys())
		.map(index => {
			return {
				time: session.start_time + (index * session.minutes_per_abstract),
				abstract: session.abstracts.find(abstract => abstract.session_position === index)
			}
		})
}

// Function to extract the department and session number from the session name
const getSessionDetails = (sessionName) => {
	// This regex matches the department name and an optional number, with or without a preceding '#'
	const match = sessionName.match(/^(.*?)(?:\s*#?\s*(\d+))?$/);
	return {
		department: match ? match[1].trim() : sessionName,
		number: match && match[2] ? parseInt(match[2], 10) : 0 // Use zero for sessions without a number
	};
};


// Custom sort function
// First, sort by name
// Second, sort by session number
// (based on the regex above)
export const sortSessions = (a, b) => {
	const aDetails = getSessionDetails(a.name);
	const bDetails = getSessionDetails(b.name);

	// Compare by department name first
	if (aDetails.department < bDetails.department) return -1;
	if (aDetails.department > bDetails.department) return 1;

	// If department names are equal, compare by session number
	return aDetails.number - bDetails.number;
};