import React, { useState, useEffect, useContext } from "react";

import axios from "axios";
import { useNavigate, } from "react-router-dom";

import AbstractInfo from "./AbstractInfo";
import AbstractContent from "./AbstractContent";
import Advisors from "./Advisors";
import StudentInfo from "./StudentInfo";
import { isValidEmail } from "../../../utils";
import { useFormState } from "../../../hooks";

import { UserContext, UserDispatchContext } from "../../../UserContext";

import { useQuery } from "@tanstack/react-query";

import {
	Heading,
	Button,
	ButtonGroup,
	useToast,
	Stack,
	Skeleton,
	FormHelperText,
	FormControl,
	Checkbox,
	Link,
} from "@chakra-ui/react";

import {
	degreeLevelsQuery,
	departmentsQuery,
	facultyMembersQuery,
	rightsQuery,
	settingsQuery
} from "../../../api/queries";
import Feature from "../../Feature";

export default function AbstractForm({
    editMode = false,
    abstractId = -1,
	afterSubmit,
}){

	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)
	const { isPending: facultyPending, data: facultyMembers } = useQuery(facultyMembersQuery)
	const { isPending: degreeLevelsPending, data: degreeLevels } = useQuery(degreeLevelsQuery)
	const { isPending: departmentsPending, data: departments } = useQuery(departmentsQuery)
	const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)
	const { isPending: abstractPending, data: existingAbstract } = useQuery({
		queryKey: ["abstract", abstractId],
		queryFn: async () => {
			const res = await axios.get(`/api/abstract/${abstractId}`)
			return res.data
		},
		enabled: editMode
	})

	const loading = rightsPending
					|| facultyPending
					|| degreeLevelsPending
					|| departmentsPending
					|| settingsPending
					|| (editMode && abstractPending)

	const user = useContext(UserContext);
	const userDispatch = useContext(UserDispatchContext);

	//if editing an abstract, the abstract data is populated in UseEffect
	const [abstract, updateAbstract] = useFormState({
		degree_level: -1,
		department: -1,
		three_minute_session: false,
		data_science_session: false,
		title: "",
		body: "",
		advisor_ids: [],
	});

	const [studentInfo, updateStudentInfo] = useFormState({
		email: user?.email,
		us_citizen: user?.us_citizen,
		student_employee: user?.student_employee,
	})

	useEffect(() => {
		if(editMode && abstract.department === -1) updateAbstract(existingAbstract)
	}, [existingAbstract])

	useEffect(() => {
		updateStudentInfo({
			email: user?.email,
			us_citizen: user?.us_citizen,
			student_employee: user?.student_employee})
	}, [user])

	// true if the user is editing and has changed something big, like title
	const [majorEdit, setMajorEdit] = useState(false);

	const handleAbstractChange = (data) => {
		updateAbstract(data);
		if(!editMode) return;
		// force notify others if editing anything except abstract body
		const majorProperties = ['advisor_ids', 'department', 'degree_level', 'data_science_session', 'three_minute_session']
		majorProperties.forEach(property => {
			if(data.hasOwnProperty(property)){
				setMajorEdit(true);
				setNotifyOthers(true);
			}
		})
	}
	
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [notifyOthers, setNotifyOthers] = useState(true);

	const navigate = useNavigate();
	const toast = useToast();

	async function loadData() {
		if(editMode){
			const res = await axios.get(`/api/abstract/${abstractId}`)
			updateAbstract(res.data)
		}
	}

	//On component mount
	useEffect(() => {
		loadData();
	}, [abstractId]);

	const [formError, setFormError] = useState(false);

	// todo: refactor for readability
	function validateForm() {
		if (!isValidEmail(studentInfo.email)) {
			toast({
				title: "Invalid email.",
				description: "You must set a valid email before you can submit an abstract." + editMode ? " You can do this from the Profile page." : "",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setFormError(true);
			return false;
		}
		else if (
			abstract.degree_level.id === -1 || isNaN(abstract.degree_level.id) ||
			abstract.department.id === -1 || isNaN(abstract.department.id) ||
			abstract.title === "" ||
			abstract.body === ""
		) {
			toast({
				title: "Cannot create abstract.",
				description: "Please fill out all required fields",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setFormError(true);
			return false;
		} else if(
			abstract.advisor_ids.length === 0
		){
			toast({
				title: "Cannot create abstract.",
				description: "Please select at least one advisor",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setFormError(true);
			return false;
		}
		return true;
	}

	async function submitAbstract() {
		if(!validateForm()) return;

		setIsSubmitting(true);
		const today = new Date();
		const date_submitted = editMode ? abstract.date_submitted : today;
		const result = await postAbstract({
			title: abstract.title,
			body: abstract.body,
			date_submitted: date_submitted,
			date_modified: editMode ? today : date_submitted,
			degree_level_id: abstract.degree_level.id,
			data_science_session: abstract.data_science_session,
			three_minute_session: abstract.three_minute_session,
			department_id: abstract.department.id,
			year: editMode ? abstract.year : settings.relevant_year,
			advisor_ids: abstract.advisor_ids,
			notify_others: editMode ? notifyOthers : true,
		});
		setIsSubmitting(false);
		if(result) afterSubmit(result);
	}

	async function postAbstract(data) {
		setFormError(false);

		// update the user's info if it was changed on this form
		if(!editMode){
			if(studentInfo.email !== user.email && isValidEmail(abstract.email) || studentInfo.us_citizen !== user.us_citizen || studentInfo.student_employee !== user.student_employee){
				const res = await axios.patch(`/api/user/${user.id}`, {
					email: studentInfo.email,
					us_citizen: studentInfo.us_citizen ?? false,
					student_employee: studentInfo.student_employee ?? false,
				});
				userDispatch({ type: 'updated', user: res.data })
			}
		}

		// create/update the abstract
		const url = editMode ? `/api/abstract/${abstractId}` : `/api/abstract`;
		const apiMethod = editMode ? axios.patch : axios.post;
		try{
			const res = await apiMethod(url, data);
			return res.data;
		}
		catch(e){
			console.error(e);
			return null;
		}
	}

	function handleAdvisorAdd(id){
		const ids = new Set(abstract.advisor_ids);
		ids.add(id);
		handleAbstractChange({advisor_ids: Array.from(ids)});
		return true;
	}

	function handleAdvisorRemove(id){
		handleAbstractChange({advisor_ids: abstract.advisor_ids.filter(i => i !== id)})
	}

    if(loading) return <Skeleton mt={3} h="20em" w="40em" />

	const allowsCopresenters = departments.find(d => d.id === abstract.department.id)?.allows_copresenters ?? false;

    return (
        <>
        <Heading as="h1" size="lg">
			{editMode ? "Edit" : "Create"} Abstract
        </Heading>
        <Stack spacing={5} mt={5}>
			{/* the email is not part of the user account, not abstract */}
			{!editMode &&
                <StudentInfo
                    studentInfo={studentInfo}
					updateStudentInfo={updateStudentInfo}
                    formError={formError}
                />
            }
            <AbstractInfo
                abstract={abstract}
                degreeLevels={degreeLevels}
                departments={departments}
                formError={formError}
                advisors={facultyMembers}
                editMode={editMode}
                onDepartmentChange={(value) => handleAbstractChange({ department: {...abstract.department, id: value}})}
                onDegreeChange={(value) => handleAbstractChange({ degree_level: {...abstract.degree_level, id: value}})}
                onDataScienceChange={(value) => handleAbstractChange({ data_science_session: value })}
                onThreeMinChange={(value) => handleAbstractChange({ three_minute_session: value })}
                onChangeRequestsChange={(value) => handleAbstractChange({session_change_requests: value})}
            />
            <Advisors
                selectedAdvisors={abstract.advisor_ids}
                onAdvisorRemove={handleAdvisorRemove}
                onAdvisorAdd={handleAdvisorAdd}
            />
			<Feature title="Co-Presenters and Co-Authors">
				Co-presenters and co-authors can be added after submitting your abstract.
			</Feature>
			{settings.t_shirt_form && (
				<Feature title="SRC T-Shirt">
					<Link href={settings.t_shirt_form} color="blue.400" isExternal>Sign up for an SRC T-Shirt here</Link>
				</Feature>
			)}
            <AbstractContent
                title={abstract.title}
                body={abstract.body}
                formError={formError}
                onTitleChange={(value) => handleAbstractChange({ title: value })}
                onBodyChange={(value) => handleAbstractChange({ body: value })}
            />
            <FormControl>
                {!editMode && (
                    <FormHelperText>
                        Advisors will be notified about your submission via email.
                    </FormHelperText>
                )}
                {editMode && (
                    <Checkbox isChecked={notifyOthers} onChange={(event) => setNotifyOthers(event.target.checked)} disabled={majorEdit}>
                        <>Notify advisors and copresenters about my changes.</>
                        {majorEdit && (<><br/>(Mandatory for major changes)</>)}
                    </Checkbox>
                )}
            </FormControl>
            <ButtonGroup variant="solid" spacing="4" display="flex" justifyContent="end">
				{editMode && (
					<Button
						onClick={() => navigate(-1)}
					>
					Cancel
				</Button>
				)}
				<Button
                    colorScheme="green" onClick={submitAbstract}
                    isLoading={isSubmitting}
                    loadingText="Submitting"
                >
                    {editMode ? "Update" : allowsCopresenters ? "Submit and Continue" : "Submit"}
                </Button>
            </ButtonGroup>
        </Stack>
        </>
    );
}