import React from "react";

import {
	FormControl,
	FormLabel,
	Stack,
	Input,
	FormHelperText,
	Checkbox,
} from "@chakra-ui/react";

import { isValidEmail } from "../../../utils";
import Feature from "../../Feature";

export default function StudentInfo({
	studentInfo,
	formError = false,
	updateStudentInfo,
}) {
	return (
		<Feature title="Student Info">
			<Stack spacing={3}>
				<FormControl
					id="email"
					isRequired
					isInvalid={formError && !isValidEmail(studentInfo.email)}
				>
					<FormLabel>Email</FormLabel>
					<Input
						value={studentInfo.email}
						placeholder="Enter your email..."
						onChange={(e) => updateStudentInfo({email: e.target.value})}
					></Input>
				</FormControl>
				<FormControl id="us-citizen">
					<FormLabel>U.S. Citizen</FormLabel>
					<Checkbox
						isChecked={studentInfo.us_citizen} 
						onChange={(e) => updateStudentInfo({us_citizen: e.target.checked})}>
							Check this box if you are a U.S. Citizen
					</Checkbox>
				</FormControl>
				<FormControl id="student-employee">
					<FormLabel>BYU Employee</FormLabel>
					<Checkbox
						isChecked={studentInfo.student_employee}
						onChange={(e) => updateStudentInfo({student_employee: e.target.checked})}>
						Check this box if you get a paycheck from BYU
					</Checkbox>
				</FormControl>
			</Stack>
		</Feature>
	);
}