export function MinutesToHoursMinutes(mins:number) {
    // getting the hours.
    let hrs:any = Math.floor(mins / 60); 
    // getting the minutes.
    let min:any = mins % 60; 
    // formatting the hours.
    //hrs = hrs < 10 ? '0' + hrs : hrs; 
    // formatting the minutes.
    //min = min < 10 ? '0' + min : min; 
    min = min < 10 ? '0' + min : min;
    // returning them as a string.
    let time = `${hrs}:${min}`
    hrs < 12 ? time += ' am': time += ' pm'
    hrs > 12 ? hrs -= 12 : hrs -= 0

  return time; 
}