import React, { useEffect } from "react";
import {useState} from "react";

import {
	Button,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	useToast,
	Box,
	Spinner,
} from "@chakra-ui/react";
import Feature from "../../../components/Feature";
import { simpleDateFormat } from "../../../utils";
import { settingsQuery } from "../../../api/queries";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { editSetting } from "../../../api/mutationFns";

export default function SubmissionDeadline() {
	const toast = useToast();
	const queryClient = useQueryClient()
    const { isPending, data: settings } = useQuery(settingsQuery)

	const editSubmissionDeadlineMutation = useMutation({
		mutationFn: (value) => editSetting("submission_deadline", value),
		onSuccess: (newSettings) => {
			queryClient.invalidateQueries({queryKey: settingsQuery.queryKey})
			toast({
				title: "Deadline Updated",
				description:
					"The submission deadline has been successfully updated.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
		}
	})

	// "YYYY-MM-DD" format
	const [newDate, setNewDate] = useState(settings?.submission_deadline);
	useEffect(() => {
		if(!newDate) setNewDate(settings?.submission_deadline)
	}, [settings, newDate])

	return isPending ? <Spinner /> : (
		<Feature>
			<Stat>
				<StatLabel fontSize={20}>Submission Deadline</StatLabel>
				<StatNumber fontSize="1.2em">{simpleDateFormat(settings.submission_deadline)}</StatNumber>
				<StatHelpText>
					This date is shown on the SRC homepage.
					<br />But there is a one-day grace period before the true deadline.
				</StatHelpText>
			</Stat>
			<Box>
				<input type="date" value={newDate} onChange={(val) => {
					setNewDate(val.target.value);
				}} />
				<br />
				<Button mt={2} colorScheme="green" size="sm" onClick={() => editSubmissionDeadlineMutation.mutate(newDate)}>
					Update Deadline
				</Button>
			</Box>
		</Feature>
	);
}
