import React from "react";
import { useEffect, useState } from "react";

import {
	Button,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	useToast,
	Box,
	Spinner,
} from "@chakra-ui/react";
import Feature from "../../../components/Feature";
import { simpleDateFormat } from "../../../utils";
import { settingsQuery } from "../../../api/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { editSetting } from "../../../api/mutationFns";

export default function EditabilityDeadline() {
	const toast = useToast();
	const queryClient = useQueryClient()
    const { isPending, data: settings } = useQuery(settingsQuery)
	const editEditabilityDeadlineMutation = useMutation({
		mutationFn: (value) => editSetting("editability_deadline", value),
		onSuccess: () => {
			toast({
				title: "Deadline Updated",
				description:
					"The editability deadline has been successfully updated.",
				status: "success",
				duration: 5000,
				isClosable: true,
			})
			queryClient.invalidateQueries({queryKey: settingsQuery.queryKey})
		}
	})

	// "YYYY-MM-DD" format
	const [newDate, setNewDate] = useState(settings.editability_deadline);
	useEffect(() => {
		if(!newDate) setNewDate(settings?.editability_deadline)
	}, [settings, newDate])

	return isPending ? <Spinner /> : (
		<Feature>
			<Stat>
				<StatLabel fontSize={20}>Editability Deadline</StatLabel>
				<StatNumber fontSize="1.2em">{simpleDateFormat(settings.editability_deadline)}</StatNumber>
				<StatHelpText>
					Students cannot edit abstracts after this date.
				</StatHelpText>
			</Stat>
			<Box>
				<input type="date" value={newDate} onChange={(val) => {
					setNewDate(val.target.value);
				}} />
				<br />
				<Button mt={2} colorScheme="green" size="sm" onClick={() => editEditabilityDeadlineMutation.mutate(newDate)}>
					Update Deadline
				</Button>
			</Box>
		</Feature>
	);
}
