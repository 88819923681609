import React from "react";

import { Box, Spinner } from "@chakra-ui/react";

import SessionTitle from "./SessionTitle";
import SessionInfo from "./SessionInfo";
import ScheduleTable from "./ScheduleTable";
import { useQuery } from "@tanstack/react-query";
import { departmentsQuery } from "../../../api/queries";

export default function SessionRow({
	session = {},
	canEdit = false,
	loading = false,
	showWinnerCheckboxes = false,
	onRefresh,
}) {
	const departments = useQuery(departmentsQuery)
	return departments.isPending ? <Spinner /> : (
		<Box mb={8} width="100%">
            <Box ml={2}>
			<SessionTitle id={session.id} name={session.name} canEdit={canEdit} />
			<SessionInfo
				chair={session.chair ? session.chair.first_name + " " + session.chair.last_name : "None"}
				judges={session.judges}
				location={session.location}
				livestream_link={departments.data.find(d => d.id === session.department.id).livestream_link}
			/>
            </Box>
			<ScheduleTable
				session={session}
				loading={loading}
				onRefresh={onRefresh}
				showWinnerCheckboxes={showWinnerCheckboxes}
			/>
		</Box>
	);
}
