import React from "react";
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
    Button,
    Box,
    UnorderedList,
    ListItem,
    Skeleton
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { departmentsQuery } from "../../../api/queries";

export default function AbstractDeleteModal({
    isDeleteOpen=false,
    onDeleteClose,
    isCentered=true,
    isAdminOrDepCoord,
    isApplicationOpen,
    onDelete,
}){

	const cancelRef = React.useRef();

    const { isPending, data: departments } = useQuery(departmentsQuery)
    if(isPending) return <Skeleton />

    const depCoords = departments.filter(d => d.coordinator).map(d => d.coordinator);

    return (
        <AlertDialog
            isOpen={isDeleteOpen}
            leastDestructiveRef={cancelRef}
            onClose={onDeleteClose}
            isCentered={isCentered}
        >
            <AlertDialogOverlay>
                <AlertDialogContent mx="1em">
                    {
                    // version that appears normally
                    (isAdminOrDepCoord || isApplicationOpen) && 
                        <>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Abstract
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onDeleteClose}>
                                Cancel
                            </Button>
                        <Button colorScheme="red" onClick={onDelete} ml={3}>
                            Delete
                        </Button>
                        </AlertDialogFooter>
                        </>
                    }
                    {
                    // version that appears when user can't delete due to close application period
                    (!isAdminOrDepCoord && !isApplicationOpen) && 
                        <>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Cannot Delete Abstract
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Box>
                                Deleting abstracts is not permitted at this time
                                because the conference schedule is currently being organized.
                            </Box>
                            <Box mt="1em">
                                Please contact your department coordinator
                                if you'd still like to delete your application:
                            </Box>
                            <UnorderedList mt="1em">
                                {depCoords.map(coordinator => (
                                    <ListItem key={coordinator.id}>
                                        {coordinator.first_name + " " + coordinator.last_name + " (" + 
                                        departments.find(element => element.id === coordinator.department_id).name + ")"}
                                    </ListItem>
                                ))}
                            </UnorderedList>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onDeleteClose}>
                                Cancel
                            </Button>
                        <Button colorScheme="red" isDisabled={true} ml={3}>
                            Delete
                        </Button>
                        </AlertDialogFooter>
                        </>
                    }
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}