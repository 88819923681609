export const filterBySearchText = (abstracts, text) => {
	return abstracts.filter((abstract) => {
		if (abstract.title.toLowerCase().includes(text.toLowerCase())) return true;

		if ((abstract.owner.full_name).toLowerCase().includes(text.toLowerCase())) return true;

		if (abstract.department.name.toLowerCase().includes(text.toLowerCase()))
			return true;

		if (abstract.advisors.map(ad => `${ad.first_name} ${ad.last_name}`).join(", ").toLowerCase().includes(text.toLowerCase())) return true;

		if (abstract.presenters.map(p => p.first_name.toLowerCase() + " " + p.last_name.toLowerCase()).join(", ").includes(text.toLowerCase())) return true;
		return false;
	});
};

export const sortByColumn = (abstracts, sortColumn, sortDescending) => {
	const sortedAbstracts = abstracts.sort((a, b) => {
		const titleA = a.title.replace(/"/g, "").replace(/'/g, "").replace(/“/g, "").replace(/”/g, "");
		const titleB = b.title.replace(/"/g, "").replace(/'/g, "").replace(/“/g, "").replace(/”/g, "");
		const advisorA = a.advisors.map(ad => `${ad.first_name} ${ad.last_name}`).join(" ");
		const advisorB = b.advisors.map(ad => `${ad.first_name} ${ad.last_name}`).join(" ");
		switch (sortColumn) {
			case "title":
				if (titleA.toLowerCase() < titleB.toLowerCase()) return -1;
				if (titleA.toLowerCase() > titleB.toLowerCase()) return 1;
				return a.id - b.id;
			case "user":
                if ((a.owner.full_name).toLowerCase().localeCompare((b.owner.full_name).toLowerCase()) !== 0)
                    return (a.owner.full_name).toLowerCase().localeCompare((b.owner.full_name).toLowerCase());
                return titleA.toLowerCase().localeCompare(titleB.toLowerCase());
			case "department":
                if (a.department.name.toLowerCase().localeCompare(b.department.name.toLowerCase()) !== 0)
                    return a.department.name.toLowerCase().localeCompare(b.department.name.toLowerCase());
                return titleA.toLowerCase().localeCompare(titleB.toLowerCase());
			case "advisor":
                if (advisorA.toLowerCase().localeCompare(advisorB.toLowerCase()) !== 0)
                    return advisorA.toLowerCase().localeCompare(advisorB.toLowerCase());
                return titleA.toLowerCase().localeCompare(titleB.toLowerCase());
			case "degree_level":
				if (a.degree_level.name.toLowerCase().localeCompare(b.degree_level.name.toLowerCase()) !== 0)
					return a.degree_level.name.toLowerCase().localeCompare(b.degree_level.name.toLowerCase());
				return titleA.toLowerCase().localeCompare(titleB.toLowerCase());
			default:
				throw Error("Unhandled sort column id");
		}
	});

	if (sortDescending) sortedAbstracts.reverse();

	return sortedAbstracts;
};

export const filterByUser = (abstracts, netID) => {
	return abstracts.filter((abstract) =>
	abstract.owner.net_id === netID
	|| abstract.presenters.map(p => p.net_id).includes(netID)
	|| abstract.advisors.map(advisor => advisor.net_id).includes(netID));
};
