import { Button, Heading, Text } from "@chakra-ui/react";
import PresenterManager from "../../PresenterManager/PresenterManager";

export default function AddCopresenters({
    abstract,
    isSubmitting,
    onPresentersChange,
    onSubmit,
}){

    return (
        <>
        <Heading>Add additional presenters</Heading>
        <Text>
            If you will be presenting with other people, add them below.
            If you are presenting by yourself, you can skip this step.
        </Text>
        <PresenterManager
            abstract={abstract}
            isSubmitting={isSubmitting}
            afterSubmit={onPresentersChange}
        />
        <Button
            onClick={onSubmit}
            colorScheme="green"
            style={{marginLeft: "auto"}}
        >
            Finish
        </Button>
        </>
    )
}