import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    Button,
    ModalBody,
    Tag,
    TagLabel,
    TagCloseButton,
    Wrap,
    WrapItem,
    Divider,
    Avatar,
    IconButton,
    Skeleton,
    Text,
} from "@chakra-ui/react";

import { useState, useEffect } from "react";

import axios from "axios";

import CoauthorForm from "./CoauthorForm";

import {
    AddIcon
} from "@chakra-ui/icons"

export default function CoauthorManager({
    isOpen,
    onClose,
    onSave,
    isSubmitting = false,
    abstractId=-1
}){
    const [coauthors, setCoauthors] = useState([]);
    const [owner, setOwner] = useState({});
    const [loading, setLoading] = useState(false);
    const [addingNew, setAddingNew] = useState(false);   

    // fetch the abstract
    // (we don't pass it in as a prop because
    // we might not want to save changes)
    useEffect(() => {
        async function loadCoauthors(){
            if(isOpen === false) return;
            setLoading(true);
            const res = await axios.get(`/api/abstract/${abstractId}`);
            setCoauthors(res.data.coauthors);
            setOwner(res.data.owner);
            setLoading(false);
        }
        loadCoauthors();
    },[abstractId, isOpen]);

    function addCoauthor(newCoauthor){
        setCoauthors(
            [...coauthors, newCoauthor]
        );
        setAddingNew(false);
    }

    function handleClose(){
        setAddingNew(false);
        onClose();
    }

    function hash(coauthor){
        return `${coauthor.first_name} ${coauthor.last_name} ${coauthor.email}`.toLowerCase();
    }

    function removeCoauthor(coauthor){
        setCoauthors(coauthors.filter(c => hash(c) !== hash(coauthor)))
    }

    if(abstractId === -1) return;

    return (
        <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Manage Co-Authors</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Wrap my={3}>
                {loading && (
                    <Skeleton />
                )}
                {coauthors.length === 0 && (
                    <Text p={1}>No co-authors</Text>
                )}
                {coauthors.map((coauthor) => (
                    <WrapItem key={hash(coauthor)}>
                        <Tag
                            size="lg"
                            borderRadius="full"
                            colorScheme="blue"
                            h="100%"
                        >
                            <Avatar
                                size="xs"
                                ml={-1}
                                mr={2}
                                colorScheme="blue"
                            />
                            <TagLabel>
                                {coauthor.full_name}
                            </TagLabel>
                            <TagCloseButton
                                onClick={() => removeCoauthor(coauthor)}
                            />
                        </Tag>
                    </WrapItem>
                ))}
                {!addingNew && (
                <WrapItem>
                    <IconButton
                        icon={<AddIcon />}
                        colorScheme="blue"
                        bgColor="blue.100"
                        variant="outline"
                        borderRadius="full"
                        onClick={() => setAddingNew(true)}
                    />
                </WrapItem>
                )}
            </Wrap>
            {addingNew && (
                <>
                <Divider orientation="horizontal" />
                <CoauthorForm onAdd={addCoauthor} />
                </>
            )}
            </ModalBody>
            <ModalFooter>
            <Button variant='ghost' onClick={handleClose}>Cancel</Button>

            <Button colorScheme='green' mr={3} isLoading={isSubmitting} onClick={() => onSave(coauthors)}>
                Save Changes
            </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    )
}