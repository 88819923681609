import { List, ListItem } from "@chakra-ui/layout";
import React from "react";
import { minutesToTimeString } from "../../../utils";

export default function SessionTimeColumn({ sessionTimes = [] }) {
	return (
		<List>
			{sessionTimes.map((sessionTime, i) => (
				<ListItem key={i}>{minutesToTimeString(sessionTime)}</ListItem>
			))}
		</List>
	);
}
