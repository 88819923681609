import React, { useContext, useEffect, useState } from "react";

import axios from "axios";

import MainContainer from "../../components/MainContainer";

import {isValidEmail} from '../../utils'

import {
	Heading,
	FormControl,
	FormLabel,
	Stack,
	Input,
	Button,
	useToast,
	FormErrorMessage,
	Box,
	Checkbox,
} from "@chakra-ui/react";
import { UserContext, UserDispatchContext } from "../../UserContext";

export default function Profile() {
	
	const user = useContext(UserContext)
	const userDispatch = useContext(UserDispatchContext)
	
	const toast = useToast();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [studentEmployee, setStudentEmployee] = useState(false);
	const [USCitizen, setUSCitizen] = useState(false);
	const [formError, setFormError] = useState(false);


	useEffect(() => {
		setFirstName(user.first_name);
		setLastName(user.last_name);
		setEmail(user.email !== null ? user.email : "");
		setStudentEmployee(user.student_employee);
		setUSCitizen(user.us_citizen)
	}, [user]);

	async function saveProfile() {
		if (firstName === "" || lastName === "" || email === "") {
			toast({
				title: "Cannot save profile.",
				description: "Please fill out all required fields.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setFormError(true);
			return;
		}
		if (isValidEmail(email) === false){
			toast({
				title: "Invalid email address.",
				description: "Please enter a valid email.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setFormError(true);
			return;
		}
		setFormError(false);
		const data = {
			first_name: firstName,
			last_name: lastName,
			email: email,
			us_citizen: USCitizen ?? false,
			student_employee: studentEmployee ?? false,
		};

		const res = await axios.patch(`/api/user/${user.id}`, data)
		userDispatch({type: "updated", user: res.data});
		toast({
			title: "Profile saved.",
			description: "We've saved your profile for you.",
			status: "success",
			duration: 5000,
			isClosable: true,
		});
	}

	return (
		<MainContainer>
			<Heading as="h1" size="lg">
				Profile
			</Heading>
			<Stack spacing={3} py={3}>
				<FormControl id="net-id">
						<FormLabel>Net ID</FormLabel>
						<Box size="md"
						borderRadius="md"
						padding=".5em"
						paddingLeft="1em">
							{user.net_id}
						</Box>
				</FormControl>
				<FormControl id="byu-id">
						<FormLabel>BYU ID</FormLabel>
						<Box size="md"
						borderRadius="md"
						padding=".5em"
						paddingLeft="1em">
							{user.byu_id ?? "Unknown"}
						</Box>
				</FormControl>
				<FormControl
					id="first-name"
					isInvalid={formError && firstName === ""}
					isRequired
				>
					<FormLabel>First name</FormLabel>
					<Input
						size="md"
						placeholder="First name"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
					<FormErrorMessage>You must enter a first name</FormErrorMessage>
				</FormControl>
				<FormControl
					id="last-name"
					isInvalid={formError && lastName === ""}
					isRequired
				>
					<FormLabel>Last name</FormLabel>
					<Input
						size="md"
						placeholder="Last name"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
					<FormErrorMessage>You must enter a last name</FormErrorMessage>
				</FormControl>
				<FormControl
					id="email"
					isInvalid={formError && email === ""}
					isRequired
				>
					<FormLabel>Email</FormLabel>
					<Input
						size="md"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<FormErrorMessage>You must enter an email</FormErrorMessage>
				</FormControl>
				<FormControl id="us-citizen">
					<FormLabel>U.S. Citizen</FormLabel>
					<Checkbox
						isChecked={USCitizen} 
						onChange={(e) => setUSCitizen(e.target.checked)}>
							Check this box if you are a U.S. Citizen
					</Checkbox>
				</FormControl>
				<FormControl id="student-employee">
					<FormLabel>BYU Employee</FormLabel>
					<Checkbox
						isChecked={studentEmployee}
						onChange={(e) => setStudentEmployee(e.target.checked)}>
						Check this box if you get a paycheck from BYU
					</Checkbox>
				</FormControl>
				<Button mt={2} w="6em" colorScheme="green" onClick={saveProfile}>
					Save
				</Button>
			</Stack>
		</MainContainer>
	);
}
