import React, { useState, useEffect, useContext } from "react";

import { Heading, Skeleton, HStack, SimpleGrid } from "@chakra-ui/react";

import AbstractsByYear from "./components/AbstractsByYear";
import OpenSRC from "./components/OpenSRC";
import Submissions from "./components/Submissions";
import SubmissionDeadline from "./components/SubmissionDeadline";
import ConferenceDateSetter from "./components/ConferenceDateSetter";
import SetSRCYear from "./components/SetSRCYear";
import Downloads from "../Downloads"
import EditabilityDeadline from "./components/EditabilityDeadline";
import SessionChangeRequests from "./components/SessionChangeRequests";
import { UserContext } from "../../UserContext";
import { abstractsQueryFactory, rightsQuery, settingsQuery } from "../../api/queries";
import { useQuery } from "@tanstack/react-query";
import FacultyManagerLink from "./components/FacultyManagerLink";
import SubmissionsSpecial from "./components/SubmissionsSpecial";
import Participants from "./components/Participants";
import Feature from "../../components/Feature";
import TaTrainingCheckIn from "./components/TaTrainingCheckIn";

export default function Stats() {	

	const [abstractsDay, setDayAbstracts] = useState([]);

	const user = useContext(UserContext);
	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)
	const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)
	const { isPending: abstractsPending, data: abstracts } = useQuery({
		...abstractsQueryFactory(settings?.relevant_year),
		enabled: !!settings, // depends on settings
	})

	const loading = rightsPending
					|| settingsPending
					|| abstractsPending;

	function filterAbstractsByDay(abstracts) {
		const currentDate = new Date();
		return abstracts.filter((abstract) => {
			const date = new Date(abstract.date_submitted);
			if (
				currentDate.getYear() === date.getYear() &&
				currentDate.getMonth() === date.getMonth() &&
				currentDate.getDate() === date.getDate()
			) {
				return true;
			}
			return false;
		});
	}

	useEffect(() => {
		if (abstracts) {
			setDayAbstracts(filterAbstractsByDay(abstracts));
		}
	}, [abstracts]);

	return (
		<>
			<Heading textAlign={'center'} as="h1" size="md">
				Admin Tools
			</Heading>
			{!loading ? (
				<HStack mt={3} spacing={5} w="100%">
					<SimpleGrid maxWidth="100%" columns={{sm: 1, md:2, lg:3, xl:4}} spacing={6} wrap="True">
						{
							// only don't let dep coordinators
							// see the openSRC button
							// (this is also protected on the backend)
							user?.right_id === rights.admin.id && (
								<>
								<OpenSRC />
								<SetSRCYear />
								<ConferenceDateSetter />
								<SubmissionDeadline />
								<EditabilityDeadline />
								</>
							)

						}
						<Submissions abstracts={abstracts} loading={loading} />	
						<SubmissionsSpecial abstracts={abstracts} loading={loading}/>		
						<AbstractsByYear
							year={settings.relevant_year}
							dayCount={abstractsDay.length}
							yearCount={abstracts.length}
							loading={loading}
						/>
						<Participants abstracts={abstracts} loading={loading}/>	
						<Downloads />
						{/* {<TaTrainingCheckIn/>} */}
						
						
						{user?.right_id >= rights.department_coordinator.id && (
							<>
								<SessionChangeRequests />
								<FacultyManagerLink />
							</>
						)}

					</SimpleGrid>
				</HStack>
			) : (
				// Loading Skeleton Page
				<HStack mt={3} spacing={5} w="100%">
					<SimpleGrid width="100%" columns={{sm: 1, md:2, lg:3, xl:4}} spacing={6} wrap="True">
						{[...Array(6)].map((_, idx) => (
							<Feature key={idx}>
								<Skeleton>
								<Heading as="h2" size="md" fontSize={20}>
									Placeholder Text
								</Heading>
								</Skeleton>
								<br/>
								<Skeleton>
									Placeholder Text<br/>Placeholder<br/>Ph<br/>P<br/>
								</Skeleton>
							</Feature>
						))}
					</SimpleGrid>
				</HStack>
			)}

		</>
	);
}
