import React from "react";
import { Box, Table, Th, Td, Tr, Thead, Tbody, Heading, Editable, EditableInput, EditablePreview, Checkbox, Spinner } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import AdminSideBar from "../../components/AdminSideBar";
import FacultyDropdown from "../../components/FacultyDropdown";
import { departmentsQuery, facultyMembersQuery } from "../../api/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { editDepartment } from "../../api/mutationFns";

export default function DepartmentManager(){
    const { isPending: isDepartmentsPending, data: departments } = useQuery(departmentsQuery)
    const { isPending: isFacultyPending, data: facultyMembers } = useQuery(facultyMembersQuery)
    const isLoading = isDepartmentsPending || isFacultyPending;
    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: editDepartment,
        onSettled: (data, error, variables, context) => {
            const title = error ? "Department Update Failed" : "Department Updated Successfully";
            const description = error ? `Error: ${error}` : `The information for "${data.name}" has been updated`
            const status = error ? "error" : "success";
            toast({
                title,
                description,
                status,
                duration: 5000,
                isClosable: true,
            });
            if(status == "success"){
                queryClient.invalidateQueries(departmentsQuery.queryKey)
            }
        },
    })

    const toast = useToast();

    if(isLoading) return <Spinner />
    
    return (
        <AdminSideBar>
            <Heading as="h1" size="md">
				Department Manager
			</Heading>
            <Box overflow="hidden scroll" overflowX="auto" w="100%">
            <Table variant="striped" colorScheme="grey" mt={4} maxW="120ch">
                <Thead>
                    <Tr>
                        <Th w="50ch">Department Name</Th>
                        <Th>Livestream Link</Th>
                        <Th textAlign="center">Allows Copresenters</Th>
                        <Th textAlign="center">Allows Three-min Sessions</Th>
                        <Th textAlign="center">Winners Per Session</Th>
                        <Th textAlign="center">Coordinator</Th>
                    </Tr>
                </Thead>
                <Tbody>
                {departments.slice(1).map(dep => (
                    <Tr key={dep.id}>
                        <Td>
                            {dep.name}
                        </Td>
                        <Td>
                            <Editable
                                defaultValue={dep.livestream_link}
                                placeholder="(no link)"
                                onSubmit={(newLink) => mutation.mutate({...dep, livestream_link: newLink})}
                            >
                                <EditablePreview color={dep.livestream_link ? "black" : "grey"} />
                                <EditableInput />
                            </Editable>
                        </Td>
                        <Td textAlign="center">
                            <Checkbox
                                ml="auto"
                                size="lg"
                                defaultChecked={dep.allows_copresenters}
                                backgroundColor="white"
                                borderColor="gray.400"
                                onChange={(newVal) => mutation.mutate({...dep, allows_copresenters: newVal.target.checked})}
                                px="auto"
                            >

                            </Checkbox>
                        </Td>
                        <Td textAlign="center">
                            <Checkbox
                                ml="auto"
                                size="lg"
                                defaultChecked={dep.allows_three_min_sessions}
                                backgroundColor="white"
                                borderColor="gray.400"
                                onChange={(newVal) => mutation.mutate({...dep, allows_three_min_sessions: newVal.target.checked})}
                                px="auto"
                            >
                            </Checkbox>
                        </Td>
                        <Td>
                            <Editable
                                defaultValue={dep.winners_per_session}
                                onSubmit={(val) => mutation.mutate({...dep, winners_per_session: val})}
                            >
                                <EditablePreview />
                                <EditableInput />
                            </Editable>
                        </Td>
                        <Td>
                            <FacultyDropdown
                                label={null}
                                placeholder="Select Coordinator"
                                faculties={facultyMembers}
                                selectedFaculty={dep.coordinator?.id}
                                onFacultyChange={id => mutation.mutate({...dep, coordinator_id: id})}
                            />
                        </Td>
                    </Tr>
                ))}
                </Tbody>
            </Table>
            </Box>
        </AdminSideBar>
    )
}