import { FormControl, Divider, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import NameField from "../../../components/NameField";

export default function AddBuilding({
	name = "",
	shortName = "",
	nameError = false,
	shortNameError = false,
	onNameChange = null,
	onShortNameChange = null,
}) {
	return (
		<>
			<Stack spacing={3}>
				<Heading as="h3" size="sm">
					Add New
				</Heading>
				<FormControl isInvalid={nameError} isRequired>
					<NameField
						name={name}
						placeholder="Enter a name..."
						onNameChange={onNameChange}
					/>
				</FormControl>
				<FormControl isInvalid={shortNameError} isRequired>
					<NameField
						name={shortName}
						placeholder="Enter a short name..."
						onNameChange={onShortNameChange}
					/>
				</FormControl>
				<Divider />
			</Stack>
		</>
	);
}
