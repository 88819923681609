import React, { useContext } from "react";

import {
	Route,
	Routes,
} from "react-router-dom";

import { UserContext } from "../../UserContext";

import Page404 from "../Page404";

import APP_PAGES from "../../page-constants";

export default function PageManager() {

	function hasRights(user, page){
		if(page?.rightId && (user?.right_id ?? 1) >= page.rightId){
			return true
		}
		if(page.loggedIn && loggedIn) return true;
		if(!page.rightId && !page.loggedIn) return true;
		return false;
	}

	const user = useContext(UserContext)
	const loggedIn = !!user; // convert to bool

	function renderRoutes() {
		return Object.keys(APP_PAGES).map((page, i) => {
			const Element = APP_PAGES[page].component;
			return hasRights(user, APP_PAGES[page])
			? <Route
				key={i}
				path={APP_PAGES[page].path}
				element={<Element />}
				exact={true}
			/>
			: <Route
				key={i}
				path={APP_PAGES.HOMEPAGE.path}
				element={<Element />}
				exact={true}
			/>
		});
	}
	
	return (
		<Routes>
			{renderRoutes()}
			<Route component={Page404} />
		</Routes>
	);
}
