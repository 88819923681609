import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    Button,
    ModalBody,
	Tag,
	TagLabel,
	TagCloseButton,
	Wrap,
	WrapItem,
	Divider,
    Avatar,
    IconButton,
    Spinner,
    Text,
    useDisclosure,
} from "@chakra-ui/react";

import { useState, useEffect, useContext } from "react";

import axios from "axios";
import UserForm from "../../scenes/AbstractDetail/components/UserForm";

import {
    AddIcon
} from "@chakra-ui/icons"
import { UserContext } from "../../UserContext";
import { useQuery } from "@tanstack/react-query";
import { rightsQuery } from "../../api/queries";

export default function PresenterManager({
    afterSubmit,
    abstract,
}){

    const { isPending, data: rights } = useQuery(rightsQuery)

    const user = useContext(UserContext)

    const [addingNew, setAddingNew] = useState(false); 
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [toDeleteId, setToDeleteId] = useState(-1);

    async function addPresenter(newPresenter){
        if(!newPresenter.net_id) return;
        setIsSubmitting(true);
        try {
            const res = await axios.post(`/api/abstract/${abstract.id}/add_presenter`, newPresenter);
            afterSubmit(res.data, {closeWindow: false});
            setAddingNew(false);
        }
        finally{
            resetState();
        }
    }

    async function removeSelectedPresenter(){
        if(toDeleteId === -1) return;
        const payload = {id: toDeleteId};
        setIsSubmitting(true);
        try{
            const res = await axios.post(`/api/abstract/${abstract.id}/remove_presenter`, payload)
            afterSubmit(res.data, {closeWindow: false})
        } finally {
            resetState()
            onConfirmClose();
        }
    }

    function resetState(){
        setToDeleteId(-1);
        setIsSubmitting(false);
    }

    async function confirmRemovePresenter(id){
        setToDeleteId(id);
        onConfirmOpen();
    }

    function handleConfirmCancel(){
        setToDeleteId(-1);
        onConfirmClose();
    }

    const {
        isOpen: isConfirmOpen,
        onOpen: onConfirmOpen,
        onClose: onConfirmClose,
    } = useDisclosure();

    if(!abstract.presenters) return;
    if(isPending) return;

    const isDepartmentCoordinator = user?.right_id >= rights.department_coordinator.id;

    return (
        <>
            <Wrap my={3}>
                {abstract.presenters?.length === 0 && (
                    <Text p={1}>No presenters</Text>
                )}
                {abstract.presenters.map((presenter) => (
                    <WrapItem key={presenter.net_id}>
                        <Tag
                            size="lg"
                            borderRadius="full"
                            colorScheme="blue"
                            h="100%"
                        >
                            <Avatar
                                size="xs"
                                ml={-1}
                                mr={2}
                                colorScheme="blue"
                            />
                            <TagLabel>
                                {presenter.full_name} ({presenter.net_id})
                            </TagLabel>
                            {(isDepartmentCoordinator || presenter.net_id !== abstract.owner.net_id) && (
                            <TagCloseButton
                                onClick={() => confirmRemovePresenter(presenter.id)}
                            />
                            )}
                        </Tag>
                    </WrapItem>
                ))}
                {!addingNew && (
                <WrapItem>
                    <IconButton
                        icon={<AddIcon />}
                        colorScheme="blue"
                        bgColor="blue.100"
                        variant="outline"
                        borderRadius="full"
                        onClick={() => setAddingNew(true)}
                    />
                </WrapItem>
                )}
            </Wrap>

            {addingNew && (
                <>
                <Divider orientation="horizontal" />
                <UserForm
                    onSubmit={addPresenter}
                    submitLabel="Add Presenter"
                    isSubmitting={isSubmitting}
                    onCancel={() => setAddingNew(false)}
                />
                </>
            )}

            <Modal isOpen={isConfirmOpen} onClose={handleConfirmCancel} isCentered>
                <ModalOverlay>
                    <ModalContent>
                    <ModalHeader>Remove Presenter Confirmation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            You are about to remove
                            <strong> {abstract.presenters.find(p => p.id === toDeleteId)?.full_name} </strong>
                            as a presenter.
                        </Text>
                        <Text>
                            Are you sure you want to do this?
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={handleConfirmCancel}>Cancel</Button>
                        <Button colorScheme="red" onClick={removeSelectedPresenter}>Remove Presenter</Button>
                    </ModalFooter>
                    </ModalContent>
                </ModalOverlay>
            </Modal>
        </>
    )
}