import React, { useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Heading, Stack, Button, useToast, Center, Spinner } from "@chakra-ui/react";

import MainContainer from "../../components/MainContainer";
import DataFields from "./components/DataFields";

import APP_PAGES from "../../page-constants";
import axios from "axios";
import { useFormState } from "../../hooks";
import { buildingsQuery, departmentsQuery, facultyMembersQuery, roomsQuery, sessionsQueryFactory, settingsQuery } from "../../api/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createSession, editSession } from "../../api/mutationFns";

export default function SessionCreate({
	editMode = false
}) {
	const { id } = useParams(); // id of existing session

	const queryClient = useQueryClient()
	const { isPending: departmentsPending, data: departments } = useQuery(departmentsQuery);
	const { isPending: facultyMembersPending, data: facultyMembers } = useQuery(facultyMembersQuery)
	const { isPending: roomsPending, data: rooms } = useQuery(roomsQuery);
	const { isPending: buildingsPending, data: buildings } = useQuery(buildingsQuery);
	const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)

	const { isPending: sessionPending, data: initialSession } = useQuery({
		queryKey: ["session", id],
		queryFn: async () => {
			const res = await axios.get(`/api/session/${id}`);
			return res.data
		},
		enabled: editMode,
		staleTime: Infinity,
	})

	// be very careful modifying the useEffects below,
	// it is very easy to introduce race conditions,
	// overwriting data with old data

	useEffect(() => {
		// this makes it auto-select the Jesse Knight Building
		// if it exists in the system
		// since that's where all the meetings are held
		const defaultBuildingName = "Jesse Knight Building".toLowerCase();
		const defaultBuilding = (buildings ?? []).find((element) => (
			element.name.toLowerCase() === defaultBuildingName)
		);
		if(!editMode) setData({
			...data,
			building_id: defaultBuilding ? defaultBuilding.id : data.building_id,
			year: settings?.relevant_year,
		});
	}, [settings])

	useEffect(() => {
		if(!editMode) return
		if(!initialSession) return
		setData({
			...initialSession,
			start_time: minutesToTime(initialSession.start_time),
			building_id: initialSession.room?.building.id ?? -1,
		})
	}, [initialSession])

	const createSessionMutation = useMutation({
		mutationFn: createSession,
		onSuccess: () => queryClient.invalidateQueries({queryKey: sessionsQueryFactory(settings.relevant_year).queryKey}),
	})

	const editSessionMutation = useMutation({
		mutationFn: editSession,	
		onSuccess: () => queryClient.invalidateQueries({queryKey: sessionsQueryFactory(settings.relevant_year).queryKey}),
	})

	const loading = departmentsPending
					|| facultyMembersPending
					|| roomsPending
					|| buildingsPending
					|| settingsPending
					|| (sessionPending && editMode)

	const navigate = useNavigate();
	const toast = useToast();

	const [data, setData] = useFormState({
		name: "",
		department_id: -1,
		room_id: -1,
		building_id: -1,
		year: null, // will auto-update once backend data becomes available
		minutes_per_abstract: 15,
		start_time: "08:00",
		num_slots: 10,
		chair_id: null,
		judge_ids: [],
	});

	function validateData() {
		// judges and chair can be empty
		if (
			data.name.length > 0 &&
			data.department_id !== -1 &&
			data.room_id !== -1 &&
			data.building_id !== -1 &&
			data.minutes_per_abstract > 0
		) {
			return true;
		}

		toast({
			title: "Cannot create session.",
			description: "Please fill out all required fields.",
			status: "error",
			duration: 5000,
			isClosable: true,
		});
		return false;
	}

	async function publishData() {
		const mutation = editMode ? editSessionMutation : createSessionMutation
		const payload = {
			...data,
			start_time: timeToMinutes(data.start_time),
			judge_ids: data.judge_ids.filter(id => id > 0) // not falsey
		}
		if (validateData()) {
			await mutation.mutate(payload)
			navigate(APP_PAGES.SESSION_DETAILS.path.replace(":sessionId", id));
		};
	}

	function timeToMinutes(timeStr){
		const [hoursStr, minutesStr] = data.start_time.split(':');
		const hours = parseInt(hoursStr);
		const minutes = parseInt(minutesStr);
		return hours * 60 + minutes;
	}

	function minutesToTime(totalMinutes){
		const hours = totalMinutes % 60;
		const minutes = totalMinutes - (hours * 60);
		const date = new Date();
		date.setHours(hours);
		date.setMinutes(minutes);
		const formattedTime = date.toLocaleString('en-US', {
			hour: "2-digit",
			minute: "2-digit",
			hour12: false
		})
		return formattedTime;
	}

	// figure out when the session will end
	function endTime(){
		const [hoursStr, minutesStr] = data.start_time.split(':');
		const hours = parseInt(hoursStr);
		const minutes = parseInt(minutesStr);
		const startTimeMinutes = (60 * hours) + minutes;
		const endTimeMinutes = startTimeMinutes + data.num_slots * data.minutes_per_abstract
		
		const endHour = endTimeMinutes % 60;
		const endMinute = endTimeMinutes - (endHour * 60);

		const date = new Date();
		date.setHours(endHour);
		date.setMinutes(endMinute);
		
		const formattedTime = date.toLocaleString('en-US', {
		  hour: 'numeric',
		  minute: 'numeric',
		  hour12: true
		});
		
		return formattedTime
	}

	//judges and chairs are assigned later
	return loading ? <Spinner /> : (
		<MainContainer>
			<Center>
				<Stack>
					<Heading as="h1" size="lg">
						{editMode ? "Edit" : "Create"} Session
					</Heading>
					<DataFields
						buildings={buildings}
						departments={departments}
						faculties={facultyMembers}
						rooms={rooms}
						data={data}
						loading={loading}
						setData={setData}
						endTime={endTime()}
						originalData={initialSession}
					/>
					<Stack direction="row" spacing={3} align="center">
						<Button colorScheme="green" onClick={publishData}>
							Submit
						</Button>
						<Link to={APP_PAGES.SCHEDULE.path}>
							<Button colorScheme="blue">Return</Button>
						</Link>
					</Stack>
				</Stack>
			</Center>
		</MainContainer>
	);
}
