// by sharing the react-query data like this,
// different pages can rely on the same cached data
// without any work on our part

import axios from "axios";

export const rightsQuery = {
    queryKey: ["rights"],
    queryFn: async () => {
        const rights = await axios.get("/api/right");
        // convert rights from array to a single object
        // with right names as keys (for convenience)
        // (settings is also like this but the backend handles it)
        const rightsObj = rights.data.reduce((acc, right) => (acc[right.name.toLowerCase().replace(' ', '_')] = right, acc), {})
        return rightsObj;
    },
}

export const facultyMembersQuery = {
    queryKey: ["facultyMembers"],
    queryFn: async () => (await axios.get("/api/faculty")).data,
}

export const facultyMembersForDepartmentQueryFactory = (department_id) => {
    return department_id
    ? {
        queryKey: ["facultyMembers", department_id],
        queryFn: async () => (await axios.get(`/api/faculty?department=${department_id}`)).data,
    }
    : facultyMembersQuery // if they don't specify a department, use all
    
}

export const facultyStatusesQuery = {
    queryKey: ["facultyStatuses"],
    queryFn: async () => (await axios.options("/api/faculty")).data.faculty_statuses,
}

export const degreeLevelsQuery = {
    queryKey: ["degreeLevels"],
    queryFn: async () => (await axios.get("/api/degree-level")).data,
}

export const departmentsQuery = {
    queryKey: ["departments"],
    queryFn: async () => (await axios.get("/api/department")).data,
}

export const settingsQuery = {
    queryKey: ["settings"],
    queryFn: async () => (await axios.get("/api/setting")).data,
}

export const yearsQuery = {
    queryKey: ["years"],
    queryFn: async () => (await axios.get("/api/year")).data,
}

export const abstractsQueryFactory = (year, department = "all") => {
    const url = department === "all" ? `/api/abstract?year=${year}` : `/api/abstract?year=${year}&department=${department}`
    return {
        queryKey: ["abstracts", year, department],
        queryFn: async () => (await axios.get(url)).data,
    }
}

export const sessionsQueryFactory = (year) => {
    return {
        // this query key is shared between winners page and session list page
        // even though it probably shouldn't be.
        // but for simplicity, I'm leaving it that way until it causes a problem
        queryKey: ["sessions", year],
        queryFn: async () => (await axios.get(`/api/session?year=${year}`)).data,
    }
}

export const sessionChangeRequestsQuery = {
    queryKey: ["sessionChangeRequests"],
    queryFn: async () => (await axios.get('/api/session-change-request')).data,
}

export const roomsQuery = {
    queryKey: ["rooms"],
    queryFn: async () => (await axios.get(`/api/room`)).data,
}

export const buildingsQuery = {
    queryKey: ["buildings"],
    queryFn: async () => (await axios.get(`/api/building`)).data
}

export const usersQuery = {
    queryKey: ["users"],
    queryFn: async () => (await axios.get("/api/user")).data
}