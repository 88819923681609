import React, { useEffect, useState } from "react";

import DepartmentRow from "./DepartmentRow";
import Feature from "../../../components/Feature";
import { Heading, List, Box } from "@chakra-ui/react";

export default function Participants({ abstracts = [] }) {

	const [data, setData] = useState({
        owners: 0,
        presenters: 0,
        coauthors: 0,
        participants: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!abstracts || abstracts.length === 0) {
              return; // Handle empty abstracts scenario gracefully
            }
        
            const uniqueOwners = new Set(abstracts.map(abstract => abstract.owner.full_name));
            const uniquePresenters = new Set(
              abstracts.flatMap(abstract => abstract.presenters.map(presenter => presenter.full_name))
            );
            const uniqueCoauthors = new Set(
              abstracts.flatMap(abstract => abstract.coauthors.map(coauthor => coauthor.full_name))
            );
            const uniqueParticipants = new Set(
              [...uniqueOwners, ...uniquePresenters, ...uniqueCoauthors]
            )
            setData({
              owners: uniqueOwners.size,
              presenters: uniquePresenters.size,
              coauthors: uniqueCoauthors.size,
              participants: uniqueParticipants.size,
            });
          };
        
          fetchData();
    }, [abstracts]);

	return (
		<Feature display="flex" w="100%">
			<Box width="100%">
                <Heading as="h2" size="sm" fontSize={20}>
                    Participants
                </Heading>
                <br/>
                <List spacing={1.2}>
                    <DepartmentRow
                        key={0}
                        name="Abstract Owners"
                        count={data.owners}
                    />
                    <DepartmentRow
                        key={1}
                        name="Presenters & Copresenters"
                        count={data.presenters}
                    />
                    <DepartmentRow
                        key={2}
                        name="Coauthors"
                        count={data.coauthors}
                    />
                    <DepartmentRow
                        key={3}
                        name="Total Unique Participants"
                        count={data.participants}
                    />
                </List>
            </Box>
		</Feature>
	);
}