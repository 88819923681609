import { Heading } from "@chakra-ui/react";
import React from "react";

import MainContainer from '../../components/MainContainer';

export default function Page404(){
  return (
    <MainContainer>
      <Heading as="h1" size="md">Error: Page Not Found</Heading>
    </MainContainer>
  );
}
