import React from "react";

import { Heading, Box } from "@chakra-ui/react";

import { ItemTypes } from "./ItemTypes";
import { useDrop } from "react-dnd";

import Feature from "../../../components/Feature";
import SearchField from "../../../components/SearchField";

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import UnscheduledItem from "./UnscheduledItem";

export default function UnscheduledAbstracts({
	onUnscheduledDrop = null,
	selectedSessionId = -1,
	searchText = "",
	onSearchChange = null,
	abstracts = [],
}) {
	// eslint-disable-next-line no-unused-vars
	const [{ isOver }, drop] = useDrop({
		accept: [ItemTypes.SCHEDULED],
		drop: (monitor) => onUnscheduledDrop(monitor.index),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	});

	if (selectedSessionId === -1) return "";

	const AbstractRow = ({ index, style }) => {
		const { id, title, owner, degree_level, advisors} = abstracts[index];

		return (
			<UnscheduledItem
				key={id}
				id={id}
				index={index}
				title={title}
				owner={owner}
				style={style}
				degreeLevel={degree_level}
				onUnscheduledDrop={onUnscheduledDrop}
				advisors={advisors}
			/>
		);
	};
	return (
		<Feature w="100%">
			<SearchField searchText={searchText} onSearchChange={onSearchChange} />
			<Heading as="h3" size="sm" mt={3}>
				Unscheduled
			</Heading>
			<Box h="300px" ref={drop}>
				<AutoSizer>
					{({ height, width }) => (
						<List
							height={height}
							width={width}
							itemCount={abstracts.length}
							itemSize={25}
						>
							{AbstractRow}
						</List>
					)}
				</AutoSizer>
			</Box>
		</Feature>
	);
}
