import React from "react";

import { ListItem, IconButton, Text } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

export default function RoomItem({
    id = -1,
	showEdit = false,
    number = "",
    building = "",
    onDeleteClick = null,
}) {
	return (
		<ListItem display="flex" justifyContent="space-between">
			<Text>{number} {building}</Text>
			{showEdit && (
				<IconButton
                    w={2}
                    h={4}
                    size="xs"
					colorScheme="red"
					aria-label="Delete room"
					icon={<DeleteIcon />}
                    onClick={() => onDeleteClick(id)}
				/>
			)}
		</ListItem>
	);
}
