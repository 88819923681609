import React, { useContext } from "react";

import { Link } from "react-router-dom";

import { Button, Heading, Spinner } from "@chakra-ui/react";

import { useLocation } from "react-router-dom";
import APP_PAGES from "../../../page-constants";
import { UserContext } from "../../../UserContext";
import { rightsQuery } from "../../../api/queries";
import { useQuery } from "@tanstack/react-query";

export default function SessionTitle({ canEdit = false, id = -1, name = "" }) {
	const route = useLocation()
	const onDetailsPage = route.pathname.includes('details')
	const user = useContext(UserContext)
	const rights = useQuery(rightsQuery)
	return rights.isPending ? <Spinner /> : (
		<>
				<Heading as="h2" mb={2} size="md" display="flex" alignItems="center">
				{!onDetailsPage && (
					<Link className="text-link" to={`/sessions/details/${id}`}>
						{name}
					</Link>
				)}
				{ onDetailsPage && name }
				{ user?.right_id === rights.data.admin.id && onDetailsPage && (
					<Link className="text-link" to={`${APP_PAGES.SESSION_EDIT.path.replace(':id', id)}`}>
						<Button size="sm" ml={3} colorScheme="yellow">Edit Session</Button>
					</Link>
				)}
				{ canEdit && onDetailsPage && (
					<Link className="text-link" to={`${APP_PAGES.SCHEDULE_ABSTRACTS.path.replace(':id', id)}`}>
						<Button size="sm" ml={3} colorScheme="blue">Schedule Abstracts</Button>
					</Link>
				)}

			</Heading>

		</>
	);
}
