import React from "react";

import { Tr, Td, Skeleton } from "@chakra-ui/react";

// see src/styles.css for some custom styles for this component

export default function FacultySkeleton() {
	return (
		<>
		{[...Array(4)].map((_, rowIndex) => (
            <Tr key={rowIndex}>
                {[...Array(6)].map((_, colIndex) => (
                    <Td key={colIndex} py="18px">
                        <Skeleton h="28px" />
                    </Td>
                ))}
            </Tr>
        ))}
		</>
	);
}