import React, { useState } from "react";
import Feature from "../../Feature";

import {
	Tag,
	TagLabel,
	TagCloseButton,
	Wrap,
	WrapItem,
	Divider,
	Stack,
	Spinner,
} from "@chakra-ui/react";

import Modal from "../../Modal";
import FacultyDropdown from "../../FacultyDropdown";
import { useQuery } from "@tanstack/react-query";
import { facultyMembersQuery } from "../../../api/queries";

export default function Advisors({
	// array of all the ids of advisors who have been chosen so far
	selectedAdvisors = [],
	onAdvisorAdd,
	onAdvisorRemove,
}) {

	const { isPending, data: facultyMembers } = useQuery(facultyMembersQuery)
	const [selectedAdvisor, setSelectedAdvisor] = useState(-1);

	return isPending ? <Spinner /> : (
		<Feature
			title={"Advisors"} isRequired>
			<Wrap my={3}>
				{selectedAdvisors.map((id) => (
					<WrapItem key={id}>
						<Tag
							size="lg"
							borderRadius="full"
							variant="solid"
							colorScheme="blue"
						>
							<TagLabel>
								{facultyMembers.find((adv) => adv.id === id).first_name
									+ " "
									+ facultyMembers.find((adv) => adv.id === id).last_name}
							</TagLabel>
							<TagCloseButton onClick={() => onAdvisorRemove(id)} />
						</Tag>
					</WrapItem>
				))}
			</Wrap>
			<Divider orientation="horizontal" />

			<Modal
				title="Add an Advisor"
				openName="Add Advisor"
				saveName="Add"
				save={() => onAdvisorAdd(selectedAdvisor)}
				w="fit-content"
			>
				<Stack padding={3}>
					<FacultyDropdown
						selectedFaculty={selectedAdvisor}
						onFacultyChange={id => setSelectedAdvisor(id)}
						faculties={facultyMembers}
						width="100%"
						placeholder="Select an advisor"
						isRequired
					/>
				</Stack>
			</Modal>

		</Feature>
	);
}