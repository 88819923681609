import React, { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Heading, HStack, Stack, Box, Switch, FormControl, FormLabel, Button, Input, Spinner } from "@chakra-ui/react";

import ListFaculty from "./components/ListFaculty";

import AdminSideBar from "../../components/AdminSideBar";

import {APP_PAGES} from "../../page-constants.js";

import { useQuery } from "@tanstack/react-query";
import { departmentsQuery, facultyMembersForDepartmentQueryFactory, rightsQuery } from "../../api/queries";
import { UserContext } from "../../UserContext";

export default function FacultyList() {

	const user = useContext(UserContext)
	const { isPending: departmentsPending, data: departments } = useQuery(departmentsQuery)
	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)
	// show all faculty for admins, only one department for coordinators
	const { isPending: facultyMembersPending, data: facultyMembers } = useQuery({
		...facultyMembersForDepartmentQueryFactory(user.right_id === rights?.admin.id ? null : user.faculty?.department_id ?? null),
		enabled: !!user && !!rights
	})
	const [guestOnly, setGuestOnly] = useState(false);
	const [showArchived, setShowArchived] = useState(false);
	const [coordinatorsOnly, setCoordinatorsOnly] = useState(false);

	const loading = departmentsPending || facultyMembersPending;

	const navigate = useNavigate();

	const stateRef = useRef();
	stateRef.current = facultyMembers;

	const [searchText, setSearchText] = useState('');

	function filteredFaculties(){
		if (!facultyMembers) {
			return [];
		}
		return facultyMembers
			.filter(fac => fac.status === "Guest" || guestOnly === false)
			.filter(fac => fac.status !== "Archived" || showArchived)
			.filter(fac => fac.coordinator_of || coordinatorsOnly === false)
			.filter(fac => fac.full_name.toLowerCase().includes(searchText.toLowerCase()))
			.sort((a,b) => a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase()));
	}

	//if(loading) return <Spinner />;

	return (
		<AdminSideBar w="100%">
			<Heading as="h2" size="md">
				Faculty Manager
			</Heading>
			<Stack spacing={3} w="100%" h="100%">
				<HStack>
					<FormControl w="28ch" display="flex" alignItems="center">
						<FormLabel mb="0">
							Guest Faculty Only
						</FormLabel>
						<Switch onChange={(e) => setGuestOnly(e.target.checked)} />
					</FormControl>
					<FormControl w="28ch" display="flex" alignItems="center">
						<FormLabel mb="0">
							Coordinators Only
						</FormLabel>
						<Switch onChange={(e) => setCoordinatorsOnly(e.target.checked)} />
					</FormControl>
					<FormControl w="28ch" display="flex" alignItems="center">
						<FormLabel mb="0">
							Show Archived
						</FormLabel>
						<Switch onChange={(e) => setShowArchived(e.target.checked)} />
					</FormControl>
				</HStack>
				<HStack>
					<Input
						w="14em"
						type="text" 
						placeholder="Search Faculty"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
					/>
					<Button
						colorScheme='green'
						w="8em"
						onClick={() => {
							navigate(APP_PAGES.FACULTY_CREATE.path);
						}}
					>
						New Faculty
					</Button>
					<Button
						colorScheme='blue'
						w="8em"
						onClick={() => {
							navigate(APP_PAGES.FACULTY_IMPORT.path);
						}}
					>
						Import Faculty
					</Button>
				</HStack>
				<Box w="100%" display="flex" h="100%" flexDirection="column">
					<ListFaculty
						faculties={filteredFaculties()}
						// don't do this until it's loaded
						departments={departments}
						loading={loading}
					/>
				</Box>
			</Stack>
		</AdminSideBar>
	);
}
