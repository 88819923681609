import React from "react";
import PropTypes from "prop-types";

import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Select,
} from "@chakra-ui/react";

export default function FacultyDropdown({
	selectedFaculty = "",
	formError = false,
	onFacultyChange,
	// array of all advisors
	faculties = [],
    isRequired = false,
    placeholder = "Select faculty member",
    showArchived = false,
    width = null,
    label = "Faculty",
    orientation = "column"
})
{

	return (
        <FormControl display="flex" alignItems={orientation === "column" ? "normal" : "center"} flexDirection={orientation} isInvalid={formError && selectedFaculty === ""} isRequired={isRequired}>
            <>
            {label && (
                <FormLabel
                    me="auto"
                    mb={orientation === "column" ? 2 : 0}
                >
                    {label}
                </FormLabel>
            )}
            <Select
                width={width ?? "12em"}
                placeholder={placeholder}
                value={selectedFaculty}
                onChange={(e) => {
                    onFacultyChange(parseInt(e.target.value));
                }}
            >
                {faculties.filter(f => f.status !== "Archived" || showArchived).sort((a,b) => (a.last_name > b.last_name) ? 1 : -1).map((faculty) => (
                    <option key={faculty.id} value={faculty.id}>
                        {faculty.last_name + ", " + faculty.first_name}
                    </option>
                ))}
            </Select>
            <FormErrorMessage>You must select an advisor</FormErrorMessage>
            </>
        </FormControl>
	);
}

FacultyDropdown.propTypes = {
	formError: PropTypes.bool,
	faculties: PropTypes.array,
	selectedFaculty: PropTypes.number,
	onFacultyChange: PropTypes.func,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string,
};
