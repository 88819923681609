import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import { Heading, Spinner, Stack } from "@chakra-ui/react";

import SessionList from "../Sessions/components/SessionList";

import MainContainer from "../../components/MainContainer";
import axios from "axios";
import { UserContext } from "../../UserContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { rightsQuery } from "../../api/queries";

export default function SessionDetails() {

	const { sessionId } = useParams();

	const user = useContext(UserContext)
	const loggedIn = !!user;

	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)
	const { isPending: sessionPending, data: session } = useQuery({
		queryKey: ["session", sessionId],
		queryFn: async () => (await axios.get(`/api/session/${sessionId}`)).data
	})
	const queryClient = useQueryClient()

	const loading = rightsPending || sessionPending
	if(loading) return <Spinner />

	const canEdit = loggedIn && (user?.right_id >= rights.department_coordinator.id);

	return (
		<MainContainer>
			<Heading as="h1" size="lg">
				Session Details
			</Heading>
			<Stack spacing={3} my="1.5em">
				<SessionList
					sessions={[session]}
					loading={loading}
					//TODO: check statement below to see if it is even necessary. Modding the options component allowed this function to work.
					canEdit={canEdit}
					showWinnerCheckboxes={canEdit}
					onRefresh={() => queryClient.invalidateQueries({queryKey: ['session', sessionId]})}
				/>
			</Stack>
		</MainContainer>
	);
}
