import React, { useMemo } from "react";

import { Input, FormControl, FormLabel, FormHelperText, InputRightAddon, InputGroup } from "@chakra-ui/react";

import {debounce} from 'lodash';

import { SearchIcon } from "@chakra-ui/icons";

export default function SearchField({
	debounceDuration = 300,
	onSearchChange = null,
	placeholder = "Search...",
	label = "",
	helperText = "",
	id = "search-field",
	w="12em"
}) {

	const debouncedChangeHandler = useMemo(() => {
		return debounce(onSearchChange, debounceDuration);
	}, [debounceDuration, onSearchChange])

	return (
		<FormControl>
			{label &&
			<FormLabel w={w} htmlFor={id}>{label}</FormLabel>
			}
			<InputGroup>
			<Input
				id={id}
				w={w}
				type="text"
				placeholder={placeholder}
				onChange={e => debouncedChangeHandler(e.target.value)}
			/>
			<InputRightAddon children={<SearchIcon />} />
			</InputGroup>
			{ helperText && 
			<FormHelperText w={w}>{helperText}</FormHelperText>
			}
		</FormControl>
	);
}
