import React from "react";

import { Select, Stack, HStack } from "@chakra-ui/react";

export default function BuildingField({
	buildings = [],
	rooms = [],
	selectedRoom = -1,
	selectedBuilding = -1,
	onBuildingChange = null,
	onRoomChange = null,
	showRoomInput = false,
	required = false,
}) {
	let filteredRooms = [];
	if (showRoomInput)
		filteredRooms = rooms.filter(
			(room) => room.building.id === selectedBuilding
		);
	return (
		<Stack spacing={3}>
			<HStack>
				<Select
					placeholder="Select a Building"
					onChange={(e) => onBuildingChange(parseInt(e.target.value) || -1)}
					value={selectedBuilding}
				>
					{buildings.map((building) => (
						<option key={building.id} value={building.id}>
							{building.name}
						</option>
					))}
				</Select>
				{required && <span className="required-indicator"></span>}
			</HStack>
			{showRoomInput && (
				<HStack>
					<Select
						placeholder="Select a Room"
						onChange={(e) => onRoomChange(parseInt(e.target.value) || -1)}
						value={selectedRoom}
					>
						{filteredRooms.map((room) => (
							<option key={room.id} value={room.id}>
								{room.number}
							</option>
						))}
					</Select>
					{required && <span className="required-indicator"></span>}
				</HStack>
			)}
		</Stack>
	);
}
