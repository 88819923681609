import React from "react";

import { Box, Skeleton } from "@chakra-ui/react";

export default function SessionSkeleton() {
	return (
		<Box mb={8} width="100%">
            <Box ml={2}>
            <Skeleton w="15em" h="25px" my="5px"/>
			<Skeleton w="45em" h="300px" my="5px"/>
            </Box>
		</Box>
	);
}
