import React, {useState} from "react";

import {
	Box, 
	Flex,
	Button, 
	ButtonGroup,
	Badge,
	Stack,
	CloseButton,
	Avatar } from "@chakra-ui/react";
import { simpleDateFormat } from "../../../utils";


export default function FacultyCard({
	firstName = "",
	lastName = "",
	netId = "",
	email = "",
	statusId = "",
	statuses = [],
	departmentId = -1,
	departments = [],
	location = "BYU",
	hireDate = "",
	id = -1,
	onClose,
	onSave,
})

{
	// date data comes in like YYYYMMDD
	function formatDate(date){
		const hyphenated = date.substring(0,4) + '-' + date.substring(4,6) + '-' + date.substring(6,8);
		return simpleDateFormat(hyphenated);
	}

	const [saved, setSaved] = useState(false);

	return (
		<Box
			p="6"
			borderWidth="2px"
			borderRadius="lg"
			overflow="hidden"
		>
			<Box
				fontWeight="semibold"
				as="h4"
				display="flex"
				position="relative"
			>
				{firstName} {lastName}
				<Badge ml={2} my="auto" h="100%" colorScheme="green">
					{departments.find(d => d.id === departmentId).name}
				</Badge>
				<CloseButton
					ml="auto"
					size="sm"
					position="relative"
					left="10px"
					top="-10px"
					onClick={() => onClose(id)}
				>
				</CloseButton>
			</Box>

			<Box
				color="gray.500"
				fontWeight="semibold"
			>
				{email || "No email"}
				<br />
				{netId || "No net id"}
				<br />
				{statuses.find(s => s.id === statusId).description}
				<br />
				Hired {formatDate(hireDate)}
				</Box>

			<Stack spacing={6} mt={4} direction={{base:"column", sm: "row"}} justifyContent="space-between">
				<Button colorScheme="gray" onClick={() => onClose(id)}>Remove Suggestion</Button>
				<Button 
					colorScheme="green"
					onClick={() => {
						onSave(id);
						setSaved(true);
					}}
				 	disabled={saved}
				>
					{ saved ? "Saved" : "Save as Faculty"}
				</Button>
			</Stack>
		</Box>
	);
}
