import { Table, Thead, Th, Tr, Td, Checkbox, Tbody, Box, useToast, Select } from "@chakra-ui/react";
import React from "react";
import { minutesToTimeString, generateTimeSlots } from "../../../utils";
import APP_PAGES from "../../../page-constants";
import axios from "axios";
import WinnerSelect from "../../ChairWinners/components/WinnerSelect";
import { rankText } from "../../Winners/components/WinnerIcon";

export default function SessionTable({
	session,
	showWinnerCheckboxes = false,
	onRefresh,
}) {

	const toast = useToast();

	function peopleString(people){
		if(!people) return "";
		const peopleString = people.map(p => p.first_name + " " + p.last_name).join(", ");
		return peopleString;
	}

	async function setWinner(id, value){
		if(id === "") return;
		axios.patch(`/api/abstract/${id}`, {winner: value || null}).then((res) => {
			onRefresh();
			if(res.data.winner){
				toast({
					title: "Abstract Marked as Winner",
					description: `"${res.data.title}" has been declared ${rankText(value)} place winner`,
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			}
			else{
				toast({
					title: "Abstract Removed From Winners",
					description: `"${res.data.title}" is no longer a session winner`,
					status: "warning",
					duration: 5000,
					isClosable: true,
				});
			}
		});
	}

	async function viewAbstract(id){
		// only have a hyperlink if this time slot is filled
		if(!id) return;
		window.open(`${APP_PAGES.ABSTRACT_DETAIL.redirect}/${id}`, "_blank")
		// navigate(`${APP_PAGES.ABSTRACT_DETAIL.redirect}/${id}`);
	}

	return (
		<Box overflowX="auto">
			<Table>
				<Thead>
					<Tr>
						<Th>Time</Th>
						<Th>Presenters</Th>
						<Th>Advisors</Th>
						<Th className="optionalColumn">Abstract Title</Th>
						{showWinnerCheckboxes && (
							<Th>Winner</Th>
						)}
					</Tr>
				</Thead>
				<Tbody>
					{
					generateTimeSlots(session).map((slot, index) => 
						<Tr
							className={slot.abstract?.id ? "sessionAbstractRow" : ""}
							key={index}
						>
						<Td onClick={() => viewAbstract(slot.abstract?.id)} whiteSpace="nowrap">{minutesToTimeString(slot.time)}</Td>
						<Td onClick={() => viewAbstract(slot.abstract?.id)}>{peopleString(slot.abstract?.presenters)}</Td>
						<Td onClick={() => viewAbstract(slot.abstract?.id)}>{peopleString(slot.abstract?.advisors)}</Td>
						<Td onClick={() => viewAbstract(slot.abstract?.id)} className="optionalColumn">{slot.abstract?.title}</Td>
						{showWinnerCheckboxes && slot.abstract && (
							<Td>
								<WinnerSelect
									onChange={(e) => setWinner(slot.abstract?.id, e.target.value)}
									value={slot.abstract?.winner ?? ""}
									session={session}
								/>
							</Td>
						)}
					</Tr>
					)}
				</Tbody>
			</Table>
		</Box>
	);
}
