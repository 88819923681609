import {
    Card,
    CardHeader,
    CardBody,
    Stack,
    StackDivider,
    Text,
    Box,
    Heading,
    HStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import APP_PAGES from "../../../page-constants";
import WinnerIcon from "./WinnerIcon";

export default function WinnerCard({
    session,
    winners = [],
}){

    function PeopleString(people){
		if(!people) return "";
		const peopleString = people.map(p => p.first_name + " " + p.last_name).join(", ");
		return peopleString;
	}

    return (
        <Card m={3}	borderWidth="2px" borderRadius="lg">
        <CardHeader pb={1}>
          <Heading size='md'>{session?.name}</Heading>
        </CardHeader>

        <CardBody>
                <Stack divider={<StackDivider />} spacing='3'>
                    {winners.sort((a, b) => a.winner - b.winner).map(w => (
                        <Link key={w.id} to={`${APP_PAGES.ABSTRACT_DETAIL.redirect}/${w.id}`}>
                            <HStack>
                                <WinnerIcon place={w.winner} />
                                <Box>
                                    <Heading size='md' mb='1'>
                                        {PeopleString(w.presenters)}
                                    </Heading>
                                <Text fontSize='sm' fontWeight="semibold">
                                    {w.title}
                                </Text>
                                </Box> 
                            </HStack>
                        </Link>
                    ))}
                    {winners.length === 0 && (
                        <Text fontSize='sm'>
                            No winners announced
                        </Text>
                    )}
                </Stack>
        </CardBody>
      </Card>      
    )
}