import React from "react";
import {createRoot} from "react-dom/client";

import App from "./App";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import { BrowserRouter as Router } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./styles.css";

export const theme = extendTheme({
	colors: {
		grey: {
			100: "#e6e6e6",
		},
		blue: {
			400: "#0062b8",
			600: "#002e5d",
		},
	},
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// on failure, try again with exponential backoff (doubling each retry delay until 30 seconds)
			retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	}
})

//Chakra automatically provides a reset/css
const root = createRoot(document.getElementById("root"));
root.render(
	<ChakraProvider theme={theme}>
		<QueryClientProvider client={queryClient}>
			<Router>
				<App />
			</Router>
		</QueryClientProvider>
	</ChakraProvider>
);
