/**
 * The BYU API store consumer key that will be used to connect to the API
 * See: https://developer.byu.edu/docs/consume-api/use-api/choose-grant-type/authorization-code-grant-type
 * And see: api.byu.edu/store
 */
export const CLIENT_ID = {
    // keys created by josh moody
    // any future developer will need to generate
    // new keys (see root README for more info)
    LOCALHOST: "X_OfHRlYLiIgN9ikbFAWZdc6rB0a",
    BETA: "_1hlfrj3Isw_N2j7fkeoeiqpwCga",
    PRODUCTION: "_ezSa3vwnktdhUsfF4pQXkKXfSYa"
}