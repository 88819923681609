import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Center, useDisclosure } from "@chakra-ui/react";
import Abstract from "./components/Abstract";
import APP_PAGES from "../../page-constants";
import MainContainer from "../../components/MainContainer";
import AbstractDeleteModal from "./components/AbstractDeleteModal";
import CoauthorManager from "./components/CoauthorManager";
import ChangeOwnerManager from "./components/ChangeOwnerManager";
import AboutAbstract from "./components/AboutAbstract";
import PresenterModal from "./components/PresenterModal";
import { UserContext } from "../../UserContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { rightsQuery, settingsQuery } from "../../api/queries";
import { strToDate } from "../../utils";

export default function AbstractDetail() {
	const user = useContext(UserContext)
	const { id } = useParams();
	const queryClient = useQueryClient()

	function isPresenter(){
		if(!user || !abstract) return false;
		if(!abstract?.presenters) return false;
		return abstract.presenters.some(p => p.id === user.id)
				|| abstract.presenters.some(presenter => presenter.net_id === user?.net_id)
	}

	function isAdvisor(){
		if(!user || !abstract) return false
		return user.faculty && (abstract.advisor_ids?.includes(user.faculty?.id) ?? false)
	}

	function isFaculty(){
		if(!user || !abstract || !rights) return false
		return user.right_id >= rights?.faculty?.id
	}

	function isAfterConference(){
		const conference_date = strToDate(settings.conference_date);
		if(abstract.year < conference_date.getFullYear()) return true;
		const today = new Date();
		function addDays(date, days) {
			var result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		}
		const day_after_conference = addDays(conference_date, 1);
		return day_after_conference <= today;
	}

	const { isPending: rightsPending, isError: rightsError, data: rights } = useQuery(rightsQuery)
	const { isPending: settingsPending, isError: settingsError, data: settings } = useQuery(settingsQuery)
	const { isPending: abstractPending, isError: abstractError, data: abstract } = useQuery({
		queryKey: ["abstract", id],
		queryFn: async () => (await axios.get(`/api/abstract/${id}`)).data,
		retry: 1,
	})
	const { isPending: judgeCommentsPending, data: judgeComments } = useQuery({
		queryKey: ["judge-comments", id],
		queryFn: async () => (await axios.get(`/api/judge-comment?abstract=${id}`)).data,
		enabled: !!abstract && !!user && !!rights && isAfterConference() && (isPresenter() || isAdvisor() || isFaculty())
	})

	const editAbstractMutation = useMutation({
		// mutationFn can only have one parameter. Learned that the hard way in production.
		mutationFn: async ({ data, action, closeWindow=true }) => {
			const url = `/api/abstract/${id}/${action}`;
			const res = await axios.post(url, data);
			if(closeWindow) onModalClose();
			return res.data;
		},
		onSuccess: () => queryClient.invalidateQueries({queryKey: ["abstract", id]}),
		onError: (error) => console.log(error),
	})
	
	const loading = rightsPending
		|| settingsPending
		|| abstractPending

	const error = rightsError
		|| settingsError
		|| abstractError

	const {
		isOpen: isModalOpen,
		onOpen: onModalOpen,
		onClose: onModalClose
	} = useDisclosure();

	const [modalType, setModalType] = useState("");

	function openModalOfType(type){
		const match = ["presenter", "author", "delete", "change owner", "info"]
			.find(t => type.toLowerCase().includes(t));
		setModalType(match);
		onModalOpen();
	}

	const navigate = useNavigate();

	function handleDelete() {
		axios.delete(`/api/abstract/${id}`).then(() => {
			navigate(APP_PAGES.MY_ABSTRACTS.path);
		}).catch((error) => console.error(error));
	}

	function handleAbstractUpdate(updatedAbstract, options){
		queryClient.invalidateQueries({queryKey: ["abstract", id]})
		if(options?.closeWindow === true) onModalClose();
	}

	if(error) return "Abstract not found"

	// can edit abstract title/body/advisors
	const canEdit = isPresenter()
		|| isAdvisor()
		|| (user?.right_id ?? 1) >= (rights?.department_coordinator?.id ?? 9999) // hacky but works

	const canDelete = (String(user?.id) === String(abstract?.owner?.id))
		|| (user?.right_id ?? 1) >= (rights?.department_coordinator?.id ?? 9999)

	const canShare = canDelete; // can add coauthors/presenters

	// can change owner
	const canTransfer = canDelete;

	return (
		<MainContainer>
			<Center>

				<Abstract
					loading={loading}
					abstract={abstract}
					judgeComments={judgeComments ?? []}
					canEdit={canEdit}
					canDelete={canDelete}
					canShare={canShare}
					canTransfer={canTransfer}
					canViewJudgeNames={isFaculty() || isAdvisor()}
					onOpenSubmenu={openModalOfType}
				/>
				{ !loading && (
					<>
					<PresenterModal
						isOpen={isModalOpen && modalType === "presenter"}
						onClose={onModalClose}
						abstractId={abstract?.id}
						isSubmitting={editAbstractMutation.isLoading}
						canTransfer={canTransfer}
						abstract={abstract}
						afterSubmit={handleAbstractUpdate}
					/>
	
					<CoauthorManager
						isOpen={isModalOpen && modalType === "author"}
						onClose={onModalClose}
						onSave={coauthors => editAbstractMutation.mutate({ data: coauthors, action: "set_coauthors"})}
						abstractId={abstract.id}
						isSubmitting={editAbstractMutation.isLoading}
					/>
	
					<ChangeOwnerManager
						isOpen={isModalOpen && modalType === "change owner"}
						onClose={onModalClose}
						onSave={newOwner => editAbstractMutation.mutate({ data: newOwner, action: "change_owner"})}
						abstract={abstract}
					/>
	
					<AbstractDeleteModal
						isDeleteOpen={isModalOpen && modalType === "delete"}
						onDeleteClose={onModalClose}
						isCentered={true}
						isAdminOrDepCoord={user?.right_id >= rights.department_coordinator.id}
						isApplicationOpen={new Date(settings.application_open) <= new Date()}
						onDelete={handleDelete}
					/>
	
					<AboutAbstract
						isOpen={isModalOpen && modalType === "info"}
						onClose={onModalClose}
						abstract={abstract}
					/>
					</>
				)}

			</Center>
		</MainContainer>
	);
}
