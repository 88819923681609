import MainContainer from "../../components/MainContainer";
import { 
    Heading,
    Container, 
    Select, 
    Center, 
    Button, 
    Stack, 
    Input, 
    FormControl, 
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    UnorderedList,
    ListItem,
    Text,
    Spinner,
    useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { minutesToTimeString } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { sessionsQueryFactory, settingsQuery } from "../../api/queries";

export default function SessionFind(){

    const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)
    const { isPending: sessionsPending, data: sessions } = useQuery({
        ...sessionsQueryFactory(settings?.relevant_year),
        enabled: !!settings,
    })
    

    const { isOpen, onOpen, onClose } = useDisclosure()
    
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [role, setRole] = useState("student");
    const [foundSessions, setFoundSessions] = useState([]);

    const toast = useToast();

    const loading = settingsPending || sessionsPending
    if(loading) return <Spinner />

    // case insenstive searches
    const region = 'us'
    const searchOptions = {sensitivity: 'base'}
    // and this, kids, is why functional programming is sO mUcH fUuUuUN
    function searchSessions(){
        
        if(!firstName || !lastName) return [];

        if(role === "chair")
            return sessions.filter(s => (s.chair?.full_name.localeCompare(`${firstName} ${lastName}`, region, searchOptions) === 0));
        else if(role === "judge")
            return sessions.filter(s => 
                s.judges.some(j => (j.full_name.localeCompare(`${firstName} ${lastName}`, region, searchOptions)===0))
            )

        // some poor sucker is going to have to debug this at some point
        else if(role === "student"){
            // search for students in sessions by name
            return sessions.filter(s =>
                s.abstracts.map(a =>
                    // a list of all the names of people presenting in the session
                    a.presenters.map(p => p.full_name)
                    // a list of session times that have a presenter that matches the name (list of true/false)
                    ).filter(lst => lst.some(name => name.localeCompare(`${firstName} ${lastName}`, region, searchOptions) === 0))
                    .length > 0
            )
        }
        else return [];
    }

    function timesPresentingInSession(session){
        return session.abstracts.filter(a =>
            a.presenters.map(p => p.full_name)
            .some(name => name.localeCompare(`${firstName} ${lastName}`, region, searchOptions) === 0))
            .map(a => {
                return {
                    time: session.start_time + (a.session_position * session.minutes_per_abstract),
                    abstract: a
                }
            })
            .sort((a,b) => a.time - b.time)
    }

    function handleSubmit(){
        if(!firstName || !lastName) {
            toast({
				title: "Cannot Search.",
				description: `Please enter your ${!firstName ? "first" : ""} ${!(firstName || lastName) ? "and" : ""} ${!lastName ? "last" : ""} name.`,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
            return;
        }
        onOpen();
        setFoundSessions(searchSessions());
    }

    function roleDescription(role){
        if(role === "chair"){
            return "the chair for"
        }
        else if(role === "judge"){
            return "judging"
        }
        else if(role === "student"){
            return "presenting in"
        }
        else {
            return "involved in"
        }
    }

    return (
    <>
        <MainContainer>
            <Container maxW="container.lg">
                <Center>
                    <Stack>
                        <Heading as="h1" size="lg">
                            Find Your Session
                        </Heading>
                        <FormControl isRequired>
                            <FormLabel>First Name</FormLabel>
                            <Input placeholder="First Name" value={firstName} onChange={event => setFirstName(event.target.value)} />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Last Name</FormLabel>
                            <Input placeholder="Last Name" value={lastName} onChange={event => setLastName(event.target.value)} />
                        </FormControl>
                        {/*
                        <FormControl required>
                            <FormLabel>Net Id (optional)</FormLabel>
                            <Input placeholder="Net Id" value={netId} onChange={event => setNetId(event.target.value)}/>
                        </FormControl>
                        */}
                        <FormControl>
                            <FormLabel>Role</FormLabel>
                            <Select value={role} onChange={(e) => {setRole(e.target.value)}}>
                                <option value="student">Student</option>
                                <option value="judge">Judge</option>
                                <option value="chair">Chair</option>
                            </Select>
                        </FormControl>
                        <Button type="submit" colorScheme="blue" onClick={handleSubmit}>Search</Button>
                    </Stack>
                </Center>
            </Container>
        </MainContainer>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Search Results</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <>
                    You are {roleDescription(role)} {foundSessions.length} session{foundSessions.length === 1 ? '' : 's'}:
                    <UnorderedList>
                    {foundSessions.map(s => (
                        <ListItem key={s.id}>
                            <Text as="b">
                                {s.name} - {s.room.building.short_name} {s.room.number} - {minutesToTimeString(s.start_time)}
                            </Text>
                            <UnorderedList listStyleType="circle">
                                {timesPresentingInSession(s).map(st => (
                                    <ListItem key={st.abstract.id}>{minutesToTimeString(st.time)} - {st.abstract.title}</ListItem>
                                ))}
                            </UnorderedList>
                        </ListItem>
                    ))}
                    </UnorderedList>
                    </>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} colorScheme="gray" onClick={onClose}>
                    Close
                    </Button>
                </ModalFooter>
            </ModalContent>
      </Modal>
    </>
    )
}