import React from "react";
import { Box, Heading } from "@chakra-ui/react";

//isrequired=true renders a red star next to the feature title
export default function Feature({ children, title, isRequired=false, ...rest }) {
	return (
		<Box display={'grid'} p={5} shadow="md" borderWidth="1px" {...rest}>
			{title && (
				<Heading as="h2" mb={3} fontSize="xl">
					{title}
					{isRequired &&
						<span role='presentation'
							aria-hidden='true'
							className='required-indicator'></span>
					}
				</Heading>
			)}
			{children}
		</Box>
	);
}
