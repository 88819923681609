import { ListItem, Flex } from "@chakra-ui/react";
import React from "react";

export default function DepartmentRow({ name = "", count = 0 }) {
	return (
		<ListItem>
			<Flex justifyContent="space-between" style={{ position: 'relative' }}>
				<div style={{
					position: 'absolute',
					top: '68%',
					left: 0,
					width: '100%',
					borderTop: '2px dotted #ddd',
				}} />
				<div
					style={{
						maxWidth: "85%",
						textOverflow: 'ellipsis',
						whiteSpace:'nowrap',
						overflow:'hidden',
						backgroundColor:"white",
						paddingRight:"10px",
						zIndex: 1
					}}>
				{name}</div>
				<div style={{
						backgroundColor:"white",
						paddingLeft:"10px",
						zIndex: 1
					}}>
				{count}</div>
			</Flex>
		</ListItem>
	);
}
