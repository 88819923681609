import React from "react";

import { Table, Thead, Th, Tbody, Tr, Box, Td } from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

import SkeletonAbstractTable from "./SkeletonAbstractTable";

export default function AbstractTable({
	loading,
	abstracts,
	onViewDetails,
	onSort,
}) {

	return (
		<Box h="25rem" overflow='auto' flexGrow={1}>
			<Table w="100%">
				<Thead className="sticky-table-header">
					<Tr>
						<Th w="80ch">
							<span onClick={() => onSort("title")}>
								Title <ChevronDownIcon />
							</span>
						</Th>
						<Th>
							<span onClick={() => onSort("user")}>
								Student <ChevronDownIcon />
							</span>
						</Th>
						<Th>
							<span onClick={() => onSort("department")}>
								Department <ChevronDownIcon />
							</span>
						</Th>
						<Th>
							<span onClick={() => onSort("advisor")}>
								Advisors <ChevronDownIcon />
							</span>
						</Th>
						<Th>
							<span onClick={() => onSort("degree_level")}>
								Degree Level <ChevronDownIcon />
							</span>
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{loading && (
						<SkeletonAbstractTable/>
					)}
					{!loading && abstracts.map((abstract) => (
						<Tr key={abstract.id} className="abstractRow" cursor="pointer" onClick={() => onViewDetails(abstract.id)}>
							<Td>
								{abstract.title}
							</Td>
							<Td>
								{abstract.presenters.map(p => p.full_name).join(", ")}
							</Td>
							<Td>
								{abstract.department.name}
							</Td>
							<Td>
								{abstract.advisors.map(a => a.full_name).join(', ')}
							</Td>
							<Td>
								{abstract.degree_level.name}
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</Box>
	);
}
