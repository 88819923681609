import React, { useEffect, useState, useRef } from "react";

import { Heading, Stack, useToast, Box } from "@chakra-ui/react";

import Buttons from "../FacultyList/components/Buttons";
import ListRooms from "./components/ListRooms";
import AddRoom from "../RoomManager/components/AddRoom"

import AdminSideBar from "../../components/AdminSideBar";
import axios from "axios";
import { useFormState } from "../../hooks";

export default function RoomManager() {
	const [buildings, setBuildings] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [form, setForm] = useFormState({
		number: 0,
		buildingId: -1,
	});
	const [data, setData] = useFormState({
		updated: [],
		toDelete: [],
	});

	const [formError, setFormError] = useFormState({
		number: false,
		building: false,
	});

	const [showNew, setShowNew] = useState(false);
	const [showEdit, setShowEdit] = useState(false);

	const [loading, setLoading] = useState(true);
	const toast = useToast();

	const stateRef = useRef();
	stateRef.current = rooms;

	function handleSave() {
		if (showEdit) {
			data.toDelete.forEach((room) => {
				deleteRoom(room.id);
			});
			setShowEdit(false);
		} else {
			if (!validateForm()) {
				toast({
					title: "Cannot add room.",
					description: "Please fill out all required fields.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return;
			}
			postRoom({
				number: form.number,
				building_id: form.buildingId,
			});
		}
	}

	function handleCancel() {
		if (showEdit) {
			setShowEdit(false);
			setData({
				updated: rooms,
				toDelete: [],
			});
		} else {
			setShowNew(false);
			// this would reset the form
			// but we want to preserve
			// form state for productivity reasons
			//setForm();
			setFormError();
		}
	}

	function handleDelete(id) {
		const updatedCopy = [...data.updated];
		const toDelete = [...data.toDelete];
		const room = updatedCopy.filter((room) => room.id === id)[0];
		toDelete.push(room);
		const index = updatedCopy.indexOf(room);
		updatedCopy.splice(index, 1);
		setData({
			updated: updatedCopy,
			toDelete,
		});
	}

	function validateForm() {
		if (form.number >= 1000 && form.buildingId !== -1) return true;
		const errors = {
			number: false,
			building: false,
		};
		if (form.number === "" || form.number < 1000) errors.number = true;
		if (form.buildingId === -1) errors.building = true;

		setFormError(errors);
		return false;
	}

	function postRoom(facData) {
		axios.post("/api/room", facData).then((res) => {
			setData({ updated: [res.data, ...data.updated] });
			setRooms([res.data, ...rooms]);
			setShowNew(false);
			setFormError();
			// this would reset the form
			// but we want to preserve
			// form state for productivity reasons
			//setForm();
		}).catch((err) => {
			toast({
				title: "Could not create room",
				description: "Does this building already have this room number?",
				status: "error",
				duration: 5000,
				isClosable: true,
			})
		});
	}

	function deleteRoom(id) {
		axios
			.delete(`/api/room/${id}`)
			.then(() => {
				const filtered = stateRef.current.filter(
					(faculty) => faculty.id !== id
				);
				setRooms(filtered);
			});
	}

	useEffect(() => {
		function loadData() {
			Promise.all([
				axios.get(`/api/building`),
				axios.get(`/api/room`),
			])
				.then(function ([buildings, rooms]) {
					const sorted = rooms.data.sort((a,b) => a.number - b.number);
					setRooms(sorted);
					setData({ updated: sorted });
					setBuildings(buildings.data);
					// this makes it auto-select the Jesse Knight Building
					// if it exists in the system
					// since that's where all the meetings are held
					const defaultBuildingName = "Jesse Knight Building".toLowerCase();
					const defaultBuilding = buildings.data.find((element) => (
						element.name.toLowerCase() === defaultBuildingName)
					);
					if(defaultBuilding) setForm({buildingId: defaultBuilding.id});
				})
				.finally(() => setLoading(false));
		}

		loadData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AdminSideBar>
			<Heading as="h2" size="md">
				Room Manager
			</Heading>
			<Stack spacing={3} mt={3}>
				<Box maxWidth={400}>
					{!showNew && (
						<ListRooms
							rooms={data.updated}
							showEdit={showEdit}
							onDeleteClick={handleDelete}
							loading={loading}
						/>
					)}
					{showNew && (
						<AddRoom
							show={showNew}
							buildingId={form.buildingId}
							buildingNumber={form.number}
							buildings={buildings}
							numberError={formError.number}
							buildingError={formError.building}
							onNumberChange={(value) => setForm({ number: value })}
							onBuildingChange={(value) => setForm({ buildingId: value })}
							//childToParent={childToParent}
						/>
					)}
					<Buttons
						showNew={showNew}
						showEdit={showEdit}
						onNewClick={() => setShowNew(!showNew)}
						onEditClick={() => setShowEdit(!showEdit)}
						onSaveClick={handleSave}
						onCancelClick={handleCancel}
					/>
				</Box>
			</Stack>
		</AdminSideBar>
	);
}
