import { Heading } from "@chakra-ui/layout";
import React from "react";

import Feature from "../../../components/Feature";
import { Grid, List } from "@chakra-ui/react";
import { generateTimeSlots } from "../../../utils";
import Scroll from "../../../components/Scroll";

import SessionTimeColumn from "./SessionTimeColumn";
import AbstractItem from "./AbstractItem";
import UnassignedItem from "./UnassignedItem";

export default function ScheduledAbstracts({
	session = null,
	onScheduledDrop = null,
}) {

    if (!session)
        return "Select a session to schedule abstracts";

	const timeSlots = generateTimeSlots(session);
        
	return (
		<Feature flex="1" flexBasis="500px" overflow="hidden">
			<Heading as="h3" size="sm" mt={3}>
				Scheduled
			</Heading>

			<Scroll
				scrollStart={5}
				numItems={timeSlots.length}
				forceOverflowAuto={true}
				height="300px"
			>
				<Grid gridTemplateColumns="5em minmax(0,1fr)" gridColumnGap="0.5em">
					<SessionTimeColumn sessionTimes={timeSlots.map(slot => slot.time)} />
					<List>
					{timeSlots.map(slot => slot.abstract).map((abstract, i) => {
						return abstract ? 
							<AbstractItem
								key={i}
								index={i}
								advisors={abstract.advisors.map(ad => ad.full_name)}
								user={abstract.owner.last_name + ", " + abstract.owner.first_name}
								title={abstract.title}
								degreeLevel={abstract.degree_level.name}
								id={abstract.id}
							/> : 
							<UnassignedItem
								key={i}
								index={i}
								onScheduledDrop={onScheduledDrop}
							/>
					})}
					</List>
				</Grid>
			</Scroll>
		</Feature>
	);
}
