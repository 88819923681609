import React from "react";

import DepartmentRow from "./DepartmentRow";

import { Heading, List, Box } from "@chakra-ui/react";

export default function SubmissionsByDepartment({ data = [] }) {
	const sortedData = [...data].sort((a, b) => b.abstractCount - a.abstractCount);
	return (
		<Box width="100%">
			<Heading as="h2" size="sm" fontSize={20}>
				Abstracts By Department
			</Heading>
			<br/>
			<List spacing={1.2}>
				{sortedData.map((department, i) => (
					<div key={i}>
					{ department.name!=="College" ?
					<DepartmentRow
						name={department.name}
						count={department.abstractCount}
					/> :
					<></>
					}
					</div>
				))}
			</List>
		</Box>
	);
}
