import React, { useContext, useEffect, useState } from "react";

import ScheduledAbstracts from "./components/ScheduledAbstracts";
import UnscheduledAbstracts from "./components/UnscheduledAbstracts";

import Options from "./components/Options";

import { DndProvider } from "react-dnd";
// html5 backend doesn't support touch screens
// but the TouchBackend is borderline unusable
// (doesn't show items currently being dragged)
// so I think it's best to just not support mobile
// :'(
import { HTML5Backend } from "react-dnd-html5-backend";

import AdminSideBar from "../../components/AdminSideBar";

import { Heading, Skeleton, Flex, Spinner, } from "@chakra-ui/react";

import { ItemTypes } from "./components/ItemTypes";

import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { abstractsQueryFactory, departmentsQuery, rightsQuery, sessionsQueryFactory, settingsQuery } from "../../api/queries";
import { UserContext } from "../../UserContext";
import { editAbstract } from "../../api/mutationFns";

export default function ScheduleAbstractManager() {
	const { isPending: settingsPending, data: settings } = useQuery(settingsQuery);
	const { isPending: departmentsPending, data: departments } = useQuery(departmentsQuery);
	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery);
	const { isPending: sessionsPending, data: sessions } = useQuery({
		...sessionsQueryFactory(settings?.relevant_year),
		enabled: !!settings, // depends on settings
	})
	const { isPending: abstractsPending, data: abstracts } = useQuery({
		...abstractsQueryFactory(settings?.relevant_year),
		enabled: !!settings,
	})
	const user = useContext(UserContext);
	const queryClient = useQueryClient();

	const loading = settingsPending
					|| departmentsPending
					|| rightsPending
					|| sessionsPending
					|| abstractsPending

	const editAbstractMutation = useMutation({
		mutationFn: editAbstract,
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: abstractsQueryFactory(settings.relevant_year).queryKey})
			queryClient.invalidateQueries({queryKey: sessionsQueryFactory(settings.relevant_year).queryKey})
		},
	});

	const [unscheduledSearchText, setUnscheduledSearchText] = useState("");
	const [dataScienceOnly, setDataScienceOnly] = useState(false);
	const [threeMinuteOnly, setThreeMinuteOnly] = useState(false);
	
	// id is a string, not an integer. That threw me off for a while
	const {id} = useParams();
	const navigate = useNavigate();
	const [selectedSessionId, setSelectedSessionId] = useState(id ? parseInt(id) : -1);
	const [selectedDepartmentId, setSelectedDepartmentId] = useState(-1);

	// auto-select the correct department for filtering
	// EXCEPT when filtering a session in department "College"
	// since those sessions should show all abstracts
	function autoSelectDepartmentFilter(){
		if(!sessions) return;
		// auto-select the department filter
		// according to the department of the session
		const session = sessions.find(sesh => sesh.id === selectedSessionId);
		let sessionDepartment = -1;
		// auto select department unless college department
		if(session) {
			const collegeDepId = departments.find(dep => dep.name==="College" || dep.name === "Data Science").id;
			if(session.department.id !== collegeDepId){
				sessionDepartment = session.department_id;
				setDataScienceOnly(false);
			}
			else{
				setDataScienceOnly(true);
			}
		}
		setSelectedDepartmentId(sessionDepartment);
	}

	useEffect(() => {
		if(!loading) autoSelectDepartmentFilter()
	}, [loading])

	if(loading) return <Spinner />

	const selectedSession = sessions.find(sesh => sesh.id === selectedSessionId);

	function handleScheduledDrop(type, srcIndex, destIndex) {
		//console.log(type, srcIndex, destIndex, filteredUnscheduledAbstracts(), selectedSession.abstracts);
		const abstract = type === ItemTypes.UNSCHEDULED ? filteredUnscheduledAbstracts()[srcIndex] : selectedSession.abstracts.find(a => a.session_position === srcIndex)
		editAbstractMutation.mutate({
			...abstract,
			session_id: selectedSessionId,
			session_position: destIndex,
		})
	}

	function handleUnscheduledDrop(index) {
		const abstract = selectedSession.abstracts.find(a => a.session_position === index);
		editAbstractMutation.mutate({
			...abstract,
			session_id: null,
			session_position: null,
		});
	}

	function filteredSessions(sessions){
		if(user?.right_id === rights.admin.id){
			return sessions;
		}
		else{
			return sessions.filter(session => user?.faculty ? session.department.id===user.faculty.department_id : true)
		}
	}

	function filteredUnscheduledAbstracts(){
		return abstracts
			.filter(a => a.department_id === selectedDepartmentId || selectedDepartmentId === -1)
			.filter(a => threeMinuteOnly === false || a.three_minute_session === true)
			.filter(a => dataScienceOnly === false || a.data_science_session === true)
			.filter(a => unscheduledSearchText === "" || abstractString(a).includes(unscheduledSearchText.toLowerCase()))
			.filter(a => a.session === null)
			.sort((a,b) => a.owner.full_name.localeCompare(b.owner.full_name))
	}

	function abstractString(abstract){
		const str = abstract.owner.full_name + ", "
			+ abstract.presenters.map(p => p.full_name).join(", ") + ", "
			+ abstract.coauthors.map(c => c.full_name).join(", ") + ", "
			+ abstract.advisors.map(ad => ad.full_name).join(", ") + ", "
			+ abstract.title
		return str.toLowerCase();
	}

	return (
		<AdminSideBar>
			<Heading as="h1" size="md">
				Schedule Abstracts
				{selectedSession?.name ? " — " + selectedSession?.name : ""}
			</Heading>
			{loading && <Skeleton mt={3} w="40em" h="20em" />}
			{!loading && (
				<>
					<DndProvider backend={HTML5Backend}>
						<Flex
							wrap="wrap"
							style={{gap: '1.5em'}}
							mb="1.5em"
						>
							<Options
								sessions={filteredSessions(sessions)}
								departments={departments}
								dataScienceOnly={dataScienceOnly}
								threeMinuteOnly={threeMinuteOnly}
								selectedSessionId={selectedSessionId}
								selectedDepartmentId={selectedDepartmentId}
								onDepartmentChange={(value) => setSelectedDepartmentId(value)}
								onSessionChange={(value) => {
									setSelectedSessionId(value);
									navigate(`/sessions/schedule/${value}`, {replace: true});
								}}
								onThreeMinuteChange={(value) => setThreeMinuteOnly(value)}
								onDataScienceChange={(value) => setDataScienceOnly(value)}
								isAdmin={!!user && (user?.right_id >= rights.department_coordinator.id)}
							/>
							<ScheduledAbstracts
								session={selectedSession}
								onScheduledDrop={handleScheduledDrop}
							/>
						</Flex>

							<UnscheduledAbstracts
								abstracts={filteredUnscheduledAbstracts()}
								selectedSessionId={selectedSessionId}
								onSearchChange={(value) => setUnscheduledSearchText(value)}
								searchText={unscheduledSearchText}
								onUnscheduledDrop={handleUnscheduledDrop}
							/>
					</DndProvider>
				</>
			)}
		</AdminSideBar>
	);
}
