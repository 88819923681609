import React from "react";

import {
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	FormControl,
	FormLabel,
} from "@chakra-ui/react";

export default function NumberField({
	defaultValue = 1,
	onNumberChange = null,
	label = null,
    minValue = 0,
	maxValue = 1000,
}) {
	return (
		<FormControl>
			{label && <FormLabel>{label}</FormLabel>}
			<NumberInput
				defaultValue={defaultValue}
                min={minValue}
				max={maxValue}
				onChange={onNumberChange}
			>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>
		</FormControl>
	);
}
