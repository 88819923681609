import React, { useEffect, useState } from "react";
import { Heading, Stack, Button, useToast } from "@chakra-ui/react";
import axios from "axios";
import YearField from "../../components/YearField";
import Feature from "../../components/Feature";
import { Document, Packer, Paragraph, TextRun, PageBreak, UnderlineType } from 'docx'
//import * as fs from 'fs'
import { saveAs } from "file-saver";
import {GetSessionData} from "./GetSessionData.tsx";
import { generateTimeSlots } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { settingsQuery, yearsQuery } from "../../api/queries";


export default function Downloads() {
	const { isPending: settingsPending, data: settings } = useQuery(settingsQuery);
	const [loading, setLoading] = useState(new Set());
	const { isPending: yearsPending, data: years } = useQuery(yearsQuery)
	
	const isLoading = settingsPending || yearsPending;

	const [selectedYear, setSelectedYear] = useState(-1);
	const toast = useToast();

	const participantKey = 'participants'
	const winnersKey = 'winners'
	const allKey = 'all'
	const roomScheduleKey = 'rooms'

	// same as downloadCSV but with error handling stripped out
	// because I doubt that will ever happen
	// and even if it does, the faculty will just tell us in person
	// if it's broken
	async function downloadParticipantList() {
		//const year = selectedYear === -1 ? new Date().getFullYear() : selectedYear;
		if (selectedYear === -1) {
			toast({
				title: "Cannot download CSV.",
				description: "Please select a year.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return;
		}
		setLoading(new Set([...loading, participantKey]));
		const year = selectedYear;
		try {
			const response = await axios.get(`/api/participant-list?year=${year}`)
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "participant-list.csv");
			document.body.appendChild(link);
			link.click();
		}
		catch {
			toast({
				title: "Error downloading participant list",
				description: "Please contact sciencetech@byu.edu",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} finally {
			setLoading(new Set([...loading].filter(k => k !== participantKey)))
		}

	}

	async function downloadWinners() {
		//const year = selectedYear === -1 ? new Date().getFullYear() : selectedYear;
		if (selectedYear === -1) {
			toast({
				title: "Cannot download CSV.",
				description: "Please select a year.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return;
		}
		setLoading(new Set([...loading, participantKey]));
		const year = selectedYear;
		try {
			const response = await axios.get(`/api/winner-list?year=${year}`)
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "session-winners.csv");
			document.body.appendChild(link);
			link.click();
		} catch {
			toast({
				title: "Error downloading winners",
				description: "Please contact sciencetech@byu.edu",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		finally {
			setLoading(new Set([...loading].filter(k => k !== winnersKey)))
		}
	}

	function downloadCSV() {
		if (selectedYear !== -1) {
			setLoading(new Set([...loading, allKey]))
			axios({
				url: `/api/csv?year=${selectedYear}`,
				method: "GET",
				responseType: "blob",
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "data.csv");
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => console.error(error.response.data))
				.finally(() => setLoading(new Set([...loading].filter(k => k !== allKey))));
		} else {
			toast({
				title: "Cannot download CSV.",
				description: "Please select a year.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
	}

	const downloadRoomSched = async () => {
		if(selectedYear === -1) {
			toast({
				title: "Cannot download DOCX.",
				description: "Please select a year.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return;
		};
		setLoading(new Set([...loading, roomScheduleKey]))
		try {
			const response = await axios.get(`api/session?year=${selectedYear ?? settings.relevant_year}`)
			let sessions = response.data
			let children = []
		
			sessions.forEach((sesh) => {
				const times = generateTimeSlots(sesh)
				const timeSlots = GetSessionData(times, times.length)
				
				let items = [
					new TextRun({break:1, size:40, font: 'Calibri', allCaps: true, text: `Chair: ${sesh.chair? (sesh.chair.first_name + ' ' + sesh.chair.last_name) : "NA"}`}),
					new TextRun({break:2, size:40, font: 'Calibri', allCaps: true, text: `Judge 1: ${sesh.judges.length > 0 ? sesh.judges[0].full_name : "NA"}`}),
					new TextRun({break:2, size:40, font: 'Calibri', allCaps: true, text: `Judge 2: ${sesh.judges.length > 1 ? sesh.judges[1].full_name : "NA"}`}),
					new TextRun({break:2, size:40, font: 'Calibri', allCaps: true, text: `Location: ${sesh.location}`}),
					new TextRun({break:1})
				]
				
				let a = new Paragraph({
					text: {underline: {type: UnderlineType.SINGLE},break:0, size:46, font: 'Calibri', allCaps: true, text: `${sesh.name}`},
					alignment: 'center'
				})
				let b = new Paragraph({
					alignment: 'center',
					children: items
				})
				let c = new Paragraph({
					text: {
						children: [timeSlots]
					}
				})
				let d = new Paragraph({
					text: {
						children: [new PageBreak()]
					}
				})
				
				
				children.push(a)
				children.push(b)
				children.push(c)
				children.push(d)
			})

			let doc =  new Document({
				sections: [
					{
						children: [
							new Paragraph({
								text: {
									children: children
								}
							})
						]
					}
				]
			})
			
			Packer.toBlob(doc).then(blob => {
				//console.log(blob);
				saveAs(blob, "SRC Sessions.docx");
				//console.log("Document created successfully");
			})


		} catch(e){
			console.log(e)
		} finally {
			//setLoading(new Set([...loading, roomScheduleKey]))
			setLoading(new Set([...loading].filter(k => k !== roomScheduleKey)));
		}
	}

	return (
		<Feature>
			<Heading as="h2" size="md" fontSize={20}>
				Downloads
			</Heading>
			<Stack mt={3}>
				<YearField
					years={years}
					selected={selectedYear}
					onYearChange={(value) => setSelectedYear(value)}
				/>
				<Button
					w="11.5em"
					size="sm"
					colorScheme="blue"
					isLoading={loading.has(allKey)}
					loadingText="Downloading..."
					onClick={downloadCSV}
				>
					All Data (csv)
				</Button>
				<Button
					w="11.5em"
					size="sm"
					colorScheme="blue"
					isLoading={loading.has(roomScheduleKey)}
					loadingText="Downloading..."
					onClick={downloadRoomSched}>
					Session Info (docx)
				</Button>	
				<Button
					w="11.5em"
					size="sm"
					colorScheme="blue"
					isLoading={loading.has(participantKey)}
					loadingText="Downloading..."
					onClick={downloadParticipantList}>
					Participant List (csv)
				</Button>
				<Button
					w="11.5em"
					size="sm" 
					colorScheme="blue"
					isLoading={loading.has(winnersKey)}
					loadingText="Downloading..."
					onClick={downloadWinners}>
					Session Winners (csv)
				</Button>	
			</Stack>
		</Feature>
	);
}
