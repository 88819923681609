import React from "react";
import SessionField from "../../../components/SessionField";
import Feature from "../../../components/Feature";

import {
	Checkbox,
	Stack,
	FormHelperText,
	FormControl,
	Heading,
	HStack,
	Button
} from "@chakra-ui/react";
import DepartmentField from "../../../components/DepartmentField";
import { Link } from "react-router-dom";

export default function Options({
	dataScienceOnly = false,
	threeMinuteOnly = false,
	sessions = [],
	departments = [],
	selectedSessionId = -1,
	selectedDepartmentId = -1,
	onDepartmentChange = null,
	onSessionChange = null,
	onDataScienceChange = null,
	onThreeMinuteChange = null,
	isAdmin = false,
}) {
	return (
		<Feature>
			<Stack spacing={3} mt={3}>
				<Heading as="h3" size="sm">
					Session
				</Heading>
				<HStack>
					<SessionField
						selected={selectedSessionId}
						sessions={sessions}
						onSessionChange={onSessionChange}
					/>
					<Link to={`/session/edit/${selectedSessionId}`}>
					{selectedSessionId > -1 && isAdmin &&
					<Button colorScheme="yellow">Edit Session</Button>
					}
					</Link>
				</HStack>
				{selectedSessionId !== -1 && (
					<>
						<Heading as="h3" size="sm">
							Filter Abstracts
						</Heading>
						<DepartmentField
                            placeholder="All Departments"
							selected={selectedDepartmentId}
							departments={departments}
							onDepartmentChange={onDepartmentChange}
						/>
						<FormControl>
							<Checkbox
								colorScheme="green"
								isChecked={dataScienceOnly}
								onChange={(e) => onDataScienceChange(e.target.checked)}
							>
								Filter By Data Science
							</Checkbox>
							<FormHelperText>
								Students interested in participating in a data science session
							</FormHelperText>
						</FormControl>
						<FormControl>
							<Checkbox
								colorScheme="red"
								isChecked={threeMinuteOnly}
								onChange={(e) => onThreeMinuteChange(e.target.checked)}
							>
								Filter By 3 Minute
							</Checkbox>
							<FormHelperText>
								Students who submitted a 3 minute presentation abstract
							</FormHelperText>
						</FormControl>
					</>
				)}
			</Stack>
		</Feature>
	);
}
