import React, { useEffect, useState, useRef } from "react";

import { Heading, Stack, useToast, Box } from "@chakra-ui/react";

import AddBuilding from "./components/AddBuilding";
import Buttons from "../FacultyList/components/Buttons";
import ListBuildings from "./components/ListBuildings";

import AdminSideBar from "../../components/AdminSideBar";
import axios from "axios";
import { useFormState } from "../../hooks";

export default function BuildingManager() {
	const [buildings, setBuildings] = useState([]);
	const [form, setForm] = useFormState({
		name: "",
		shortName: "",
		departmentId: -1,
	});
	const [data, setData] = useFormState({
		updated: [],
		toDelete: [],
	});

	const [formError, setFormError] = useFormState({
		name: false,
		shortName: false,
		department: false,
	});

	const [showNew, setShowNew] = useState(false);
	const [showEdit, setShowEdit] = useState(false);

	const [loading, setLoading] = useState(true);
	const toast = useToast();

	const stateRef = useRef();
	stateRef.current = buildings;

	function handleSave() {
		if (showEdit) {
			data.toDelete.forEach((building) => {
				deleteBuilding(building.id);
			});
			setShowEdit(false);
		} else {
			if (!validateForm()) {
				toast({
					title: "Cannot add building.",
					description: "Please fill out all required fields.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return;
			}
			postBuilding({
				name: form.name,
				short_name: form.shortName,
			});
		}
	}

	function handleCancel() {
		if (showEdit) {
			setShowEdit(false);
			setData({
				updated: buildings,
				toDelete: [],
			});
		} else {
			setShowNew(false);
			setForm();
			setFormError();
		}
	}

	function handleDelete(id) {
		const updatedCopy = [...data.updated];
		const toDelete = [...data.toDelete];
		const building = updatedCopy.filter((building) => building.id === id)[0];
		toDelete.push(building);
		const index = updatedCopy.indexOf(building);
		updatedCopy.splice(index, 1);
		setData({
			updated: updatedCopy,
			toDelete,
		});
	}

	function validateForm() {
		if (form.name !== "" && form.shortName !== "") return true;
		const errors = {
			name: false,
			shortName: false,
		};
		if (form.name === "") errors.name = true;
		if (form.shortName === "") errors.shortName = true;
		setFormError(errors);
		return false;
	}

	function postBuilding(buildingData) {
		axios.post("/api/building", buildingData).then((res) => {
			setData({ updated: [res.data, ...data.updated] });
			setBuildings([res.data, ...buildings]);
			setShowNew(false);
			setFormError();
			setForm();
		});
	}

	function deleteBuilding(id) {
		axios
			.delete(`/api/building/${id}`)
			.then(() => {
				const filtered = stateRef.current.filter(
					(building) => building.id !== id
				);
				setBuildings(filtered);
			});
	}

	useEffect(() => {
		function loadData() {
			axios
				.get(`/api/building`)
				.then((res) => {
					const sorted = res.data.sort((a, b) =>
						a.name.toLowerCase().localeCompare(b.name.toLowerCase())
					);
					setBuildings(sorted);
					setData({ updated: sorted });
				})
				.finally(() => setLoading(false));
		}

		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AdminSideBar>
			<Heading as="h2" size="md">
				Building Manager
			</Heading>
			<Stack spacing={3} mt={3}>
				<Box maxWidth={400}>
					{!showNew && (
						<ListBuildings
							buildings={data.updated}
							showEdit={showEdit}
							onDeleteClick={handleDelete}
							loading={loading}
						/>
					)}
					{showNew && (
						<AddBuilding
							show={showNew}
							name={form.name}
							shortName={form.shortName}
							nameError={formError.name}
							shortNameError={formError.shortName}
							onNameChange={(value) => setForm({ name: value })}
							onShortNameChange={(value) => setForm({ shortName: value })}
						/>
					)}
					<Buttons
						showNew={showNew}
						showEdit={showEdit}
						onNewClick={() => setShowNew(!showNew)}
						onEditClick={() => setShowEdit(!showEdit)}
						onSaveClick={handleSave}
						onCancelClick={handleCancel}
					/>
				</Box>
			</Stack>
		</AdminSideBar>
	);
}
