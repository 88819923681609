import React, { useState } from "react";

import RightField from "../../components/RightField";

import AdminSideBar from "../../components/AdminSideBar";

import SearchField from "../../components/SearchField";
import FacultyDropdown from "../../components/FacultyDropdown";
import axios from "axios";

import {
	Heading,
	Stack,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Box,
	Text,
	Spinner,
	HStack,
	FormControl,
	FormLabel,
	Select,
	useToast,
} from "@chakra-ui/react";
import { facultyMembersQuery, rightsQuery, usersQuery } from "../../api/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { editUser } from "../../api/mutationFns";

export default function UserManager() {
	const toast = useToast()
	const queryClient = useQueryClient()
	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)
	const { isPending: facultyMembersPending, data: facultyMembers } = useQuery(facultyMembersQuery)
	const { isPending: usersPending, data: users } = useQuery({
		...usersQuery,
		enabled: !!axios.defaults.headers.common["Authorization"], // get rid of an error on page refresh
	})
	const loading = rightsPending 
					|| facultyMembersPending
					|| usersPending
					|| usersPending

	const editUserMutation = useMutation({
		mutationFn: editUser,
		onSuccess: (data, error) => {
			toast({
				title: "User updated",
				description: `The user was updated successfully` + data,
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			queryClient.invalidateQueries({queryKey: usersQuery.queryKey});
		}
	})

	const [searchText, setSearchText] = useState("");
	const [rightId, setRightId] = useState(-1);

	if(loading) return <Spinner />

	const filteredRights = {...rights }
	delete filteredRights["visitor"]
	const filteredUsers = users.filter((user) =>
		(rightId === -1 || user.right_id === rightId) && (
			user.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
			user.email?.toLowerCase().includes(searchText.toLowerCase())
		)
	).sort((a,b) => a.full_name > b.full_name ? 1 : -1)

	const MAX_SEARCH_RESULTS = 100; // performance optimization

	return (
		<AdminSideBar>
			<Stack spacing={3} mt={3}>
			<Heading as="h2" size="md">
				User Manager
			</Heading>
			<HStack align="flex-start">
				<SearchField
					label="Search for users by name or email"
					searchText={searchText}
					onSearchChange={(value) => setSearchText(value)}
				/>
				<FormControl display="flex" alignItems="center">
					<Select
						id="right-select"
						w="16em"
						placeholder="All Rights"
						onChange={(e) => setRightId(parseInt(e.target.value) || -1)}
						value={rightId}
					>
						{Object.keys(rights).map(k => (
							<option key={rights[k].id} value={rights[k].id}>{rights[k].name}</option>
						))}
					</Select>
				</FormControl>
			</HStack>
			{filteredUsers.length === 0 && (
				<Text>No results</Text>
			)}
			{filteredUsers.length > 0 && (
			<Box overflow="hidden auto" overflowX="auto" h="30em" w="100%" flexGrow="1">
				<TableContainer>
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th>Name</Th>
								<Th>Email</Th>
								<Th>Rights</Th>
								<Th>Associated Faculty</Th>
							</Tr>
						</Thead>
						<Tbody>
							{filteredUsers.slice(0, MAX_SEARCH_RESULTS).map(user => (
								<Tr key={user.id}>
									<Td>{user.full_name}</Td>
									<Td>{user.email}</Td>
									<Td>
										<RightField
											rights={filteredRights}
											selected={user?.right_id}
											onRightChange={(right_id) => editUserMutation.mutate({ id: user.id, right_id })}
										/>
									</Td>
									<Td>
										<FacultyDropdown
											label={null}
											placeholder="None"
											selectedFaculty={user.faculty?.id ?? -1}
											faculties={facultyMembers}
											onFacultyChange={(faculty_id) => editUserMutation.mutate({ id: user.id, faculty_id })}
										/>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>

				{filteredUsers.length > MAX_SEARCH_RESULTS && (
				<Text>Results limited to first {MAX_SEARCH_RESULTS} matches</Text>
				)}
			</Box>				
			)}
		</Stack>
		</AdminSideBar>
	);
}
