import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    Button,
    ModalBody,
} from "@chakra-ui/react";

import PresenterManager from "../../../components/PresenterManager/PresenterManager";

export default function PresenterModal({
    isOpen,
    onClose,
    onAdd,
    onRemove,
    isSubmitting = false,
    abstract,
    afterSubmit,
}){


    if(!abstract.presenters) return;

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Manage Presenters</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <PresenterManager
                    isSubmitting={isSubmitting}
                    abstract={abstract}
                    afterSubmit={afterSubmit}
                />
            </ModalBody>
            <ModalFooter>
            <Button variant='ghost' onClick={onClose}>Close</Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    )
}