import React from "react";

import { Select, HStack, FormControl, FormLabel } from "@chakra-ui/react";

export default function DepartmentField({
	departments = [],
	selected = -1,
	onDepartmentChange = null,
	placeholder = "Select a Department",
	style = {},
	required = false,
	label = "",
	id = "department-field"
}) {


	function DepartmentOptions() {
		const list = [];
		try{
			departments.forEach((department, i) => {
				// filter out the first department (assumed to be user)
				// sorting happens on the backend but if that ever changes, you might need to update this component
				// to sort the departments by ID
				if (i > 0) {
					list.push(
						<option key={department.id} value={department.id}>
							{department.name}
						</option>
					);
				}
			});
		} catch(error){ console.error(error) }
		return list;
	}

	return (
		<HStack align="flex-start">
			<FormControl display="flex" alignItems="center">
				{ label && 
				<FormLabel htmlFor={id} my={0}>{label}</FormLabel>
				}
				<Select
					style={style}
					w="16em"
					placeholder={placeholder}
					onChange={(e) => onDepartmentChange(parseInt(e.target.value) || -1)}
					value={selected}
				>
					<DepartmentOptions />
				</Select>
				{required && 
				<span className="required-indicator"></span>}
			</FormControl>
		</HStack>
	);
}
