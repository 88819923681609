import React from "react";

import { Select } from "@chakra-ui/react";

export default function YearField({
	years = [],
	selected = -1,
	onYearChange = null,
	removeFirst = false,
}) {
	if (removeFirst) years = years.slice(0, -1);
	years.sort((a, b) => {return b - a})
	return (
		<Select
			w="12em"
			placeholder="Select a Year"
			onChange={(e) => onYearChange(parseInt(e.target.value) || -1)}
			value={selected}
		>
			{years.map((year) => (
				<option key={year} value={year}>
					{year}
				</option>
			))}
		</Select>
	);
}
