import React, { useEffect, useState } from "react";

import DepartmentRow from "./DepartmentRow";
import Feature from "../../../components/Feature";
import { Heading, List, Box } from "@chakra-ui/react";

export default function Submissions({ abstracts = [] }) {

	const [data, setData] = useState({
        threeMinute: 0,
        dataScience: 0,
    });

    useEffect(() => {
        setData({
            threeMinute: abstracts.filter(abstract => abstract.three_minute_session).length,
            dataScience: abstracts.filter(abstract => abstract.data_science_session).length
        });
    }, [abstracts]);

	return (
		<Feature display="flex" w="100%">
			<Box width="100%">
                <Heading as="h2" size="sm" fontSize={20}>
                    Abstracts Types
                </Heading>
                <br/>
                <List spacing={1.2}>
                    <DepartmentRow
                        key={0}
                        name="Three Minute"
                        count={data.threeMinute}
                    />
                    <DepartmentRow
                        key={1}
                        name="Data Science"
                        count={data.dataScience}
                    />
                </List>
            </Box>
		</Feature>
	);
}