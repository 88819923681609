import {
  Heading,
  UnorderedList,
  ListItem,
  Link,
  OrderedList,
} from "@chakra-ui/react"

import ReactMarkdown from "react-markdown";

export default function InformationContent({
  markdown = "",
  className = "",
}){

  const h1 = ({children}) => <Heading as="h1" size="2xl">{children}</Heading>
  const h2 = ({children}) => <Heading as="h2" size="xl">{children}</Heading>
  const h3 = ({children}) => <Heading as="h3" size="lg">{children}</Heading>
  const h4 = ({children}) => <Heading as="h4" size="md">{children}</Heading>
  const h5 = ({children}) => <Heading as="h5" size="sm">{children}</Heading>
  const h6 = ({children}) => <Heading as="h6" size="xs">{children}</Heading>
  const a = ({href, children}) => <Link target="_blank" color="blue.400" href={href}>{children}</Link>
  // debating whether it looks better with or without chakra elements
  const ul = ({children}) => <UnorderedList>{children}</UnorderedList>
  const li = ({children}) => <ListItem>{children}</ListItem>
  const ol = ({children}) => <OrderedList>{children}</OrderedList>
  
    return (
      <ReactMarkdown className={className}
        components={{
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          a,
          ul,
          ol,
          li,
        }}
      >
        {markdown}
      </ReactMarkdown>
    )
}