import Homepage from "./scenes/Home";
import AbstractsPrevious from "./scenes/AbstractsPrevious";
import AbstractsSubmitted from "./scenes/AbstractsSubmitted";
import Sessions from "./scenes/Sessions";
import Profile from "./scenes/Profile";
import AdminTools from "./scenes/AdminTools";

import AbstractDetail from "./scenes/AbstractDetail";
import AbstractCreate from "./scenes/AbstractCreate";
import AbstractEdit from "./scenes/AbstractEdit";

import SessionCreate from "./scenes/SessionCreate";
import SessionEdit from "./scenes/SessionEdit";

import ScheduleAbstractManager from "./scenes/ScheduleAbstractManager";
import RoomManager from "./scenes/RoomManager";
import FacultyList from "./scenes/FacultyList";
import FacultyForm from "./scenes/FacultyForm";
import FacultyImporter from "./scenes/FacultyImporter";

import UserManager from "./scenes/UserManager";
import BuildingManager from "./scenes/BuildingManager";
import AbstractsMy from "./scenes/AbstractsMy";
import DepartmentManager from "./scenes/DepartmentManager";
import SessionDetails from "./scenes/SessionDetails";
import SessionChangeRequests from "./scenes/SessionChangeRequests";
import Winners from "./scenes/Winners";
import SessionFind from "./scenes/SessionFind";
import Instructions from "./scenes/Instructions";
import InstructionsEdit from "./scenes/InstructionsEdit";
import TaTrainingCheckIn from "./scenes/Stats/components/TaTrainingCheckIn"
import JudgeComments from "./scenes/JudgeComments";
import ChairWinners from "./scenes/ChairWinners";

export const APP_PAGES = {
	HOMEPAGE: {
		component: Homepage,
		path: "/",
		navBarTitle: "Homepage",
	},
	// TA_TRAINING_CHECKIN: {
	// 	component: TaTrainingCheckIn,
	// 	path: "/ta-check-in",
	// 	navBarTitle: "TA Check-In",
	// 	// loggedIn: true,
	// },
	MY_ABSTRACTS: {
		component: AbstractsMy,
		path: "/abstracts/my-abstracts",
		navBarTitle: "My Abstracts",
		navBarDropdown: "Abstracts",
		loggedIn: true,
	},
	ABSTRACTS_SUBMITTED: {
		component: AbstractsSubmitted,
		path: "/abstracts/submitted/",
		navBarTitle: `All $year Abstracts`,
		navBarDropdown: "Abstracts",
	},
	SCHEDULE: {
		component: Sessions,
		path: "/sessions/departments/:department?",
		default_path: "/sessions/departments/all",
		name: "/sessions/",
		navBarTitle: "Sessions",
	},
	SESSION_DETAILS: {
		component: SessionDetails,
		path: "/sessions/details/:sessionId",
		name: "/sessions/details/",	
	},
	ABSTRACTS_PREVIOUS: {
        component: AbstractsPrevious,
        path: "/abstracts/previous/",
        navBarTitle: "Previous Conferences",
		navBarDropdown: "Abstracts",
    },
	ABSTRACT_DETAIL: {
		component: AbstractDetail,
        redirect: "/abstract/detail",
		path: "/abstract/detail/:id",
	},
	ABSTRACT_CREATE: {
		component: AbstractCreate,
		path: "/abstract/create",
	},
	ABSTRACT_EDIT: {
		component: AbstractEdit,
        redirect: "/abstract/edit",
		path: "/abstract/edit/:id",
	},
	SESSION_CREATE: {
		component: SessionCreate,
		path: "/sessions/create",
	},
	SESSION_EDIT: {
		component: SessionEdit,
		path: "/session/edit/:id",
	},
	SESSION_FIND: {
		component: SessionFind,
		path: '/sessions/find',
	},
    SCHEDULE_ABSTRACTS: {
		component: ScheduleAbstractManager,
		path: "/sessions/schedule/:id",
		rightId: 5,
	},
	PROFILE: {
		component: Profile,
		path: "/profile",
		navBarTitle: "Profile",
		loggedIn: true,
	},
	ADMIN_TOOLS: {
		component: AdminTools,
		path: "/admin-tools",
		rightId: 5,
		navBarTitle: "Admin Tools",
        loggedIn: true,
		adminToolsTitle: "Dashboard",
	},
	FACULTY_LIST: {
		component: FacultyList,
		path: "/admin-tools/faculty/list",
        rightId: 5,
		adminToolsTitle: "Manage Faculty",
	},
	FACULTY_CREATE: {
		component: FacultyForm,
		path: "/admin-tools/faculty/create",
        rightId: 5,
	},
	FACULTY_IMPORT: {
		component: FacultyImporter,
		path: "/admin-tools/faculty/import",
        rightId: 6,
	},
	FACULTY_EDIT: {
		component: FacultyForm,
		path: "/admin-tools/faculty/edit/:id",
        rightId: 5,
	},
	DEPARTMENT_MANAGER: {
		component: DepartmentManager,
		path: "/admin-tools/department-manager",
		rightId: 6,
		adminToolsTitle: "Manage Departments",
	},
    BUILDING_MANAGER: {
        component: BuildingManager,
        path: "/admin-tools/building-manager",
        rightId: 6,
        adminToolsTitle: "Manage Buildings",
    },
	ROOM_MANAGER: {
		component: RoomManager,
		path: "/admin-tools/room-manager",
        rightId: 6,
		adminToolsTitle: "Manage Rooms",
	},
    RIGHTS_MANAGER: {
		component: UserManager,
		path: "/admin-tools/user-manager",
        rightId: 6,
		adminToolsTitle: "Manage Users",
	},
	INSTRUCTIONS: {
		component: Instructions,
		path: "/instructions/:title",
	},
	INSTRUCTIONS_EDIT: {
		component: InstructionsEdit,
		path: "/admin-tools/edit-instructions",
		adminToolsTitle: "Manage Instructions",
		rightId: 6,
	},
	SESSION_CHANGE_REQUESTS: {
		component: SessionChangeRequests,
		path: "/admin-tools/session-change-requests",
		adminToolsTitle: "Session Change Requests",
		rightId: 5,
	},
	JUDGE_COMMENTS: {
		component: JudgeComments,
		path: "/judge-comments",
	},
	CHAIR_WINNERS: {
		component: ChairWinners,
		path: "/chair-winners",
	},
	WINNERS: {
		component: Winners,
		path: "/winners/:year",
		redirect: "/winners"
	},
};

export default APP_PAGES;
// TA_TRAINING_CHECKIN: {
	// 	component: TaTrainingCheckIn,
	// 	path: "ta-check-in",
	// 	navBarTitle: "TA Check-In",
	// 	loggedIn: true,
	// },