import React, { useContext } from "react";

import Stats from "../Stats";

import AdminSideBar from "../../components/AdminSideBar";
import { UserContext } from "../../UserContext";
import { useQuery } from "@tanstack/react-query";
import { rightsQuery } from "../../api/queries";
import { Skeleton } from "@chakra-ui/react";

export default function AdminTools(){
	const user = useContext(UserContext)

	const { isPending, data: rights } = useQuery(rightsQuery);

	if(isPending) return <Skeleton />;
	
	// shows all tools for admins, stats only for department coordinators
    return (
		<AdminSideBar showSideBar={user?.right_id === rights.admin.id}>
            <Stats />
		</AdminSideBar>
	);
}