import React, { useState, useEffect } from "react";

import {
	Button,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	useToast,
	Select,
	Skeleton,
} from "@chakra-ui/react";
import Feature from "../../../components/Feature";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { settingsQuery } from "../../../api/queries";
import { editSetting } from "../../../api/mutationFns";

export default function SetSRCYear() {
	const toast = useToast();
	const queryClient = useQueryClient()
    const { isPending, data: settings } = useQuery(settingsQuery)
	const [yearToOpen, setYearToOpen] = useState(settings?.relevant_year);
	useEffect(() => {
		if(!yearToOpen) setYearToOpen(settings?.relevant_year)
	}, [settings, yearToOpen])

	const editRelevantYearMutation = useMutation({
		mutationFn: (value) => editSetting("relevant_year", value),
		onSuccess: (newSettings) => {
			queryClient.invalidateQueries({queryKey: settingsQuery.queryKey})
			toast({
				title: "Year updated.",
				description:
					"This site will now show and accept abstracts for year " + newSettings.relevant_year,
				status: "success",
				duration: 5000,
				isClosable: true,
			});
		}
	})

	function setSRCYear() {
		if(parseInt(yearToOpen) < 0){
			toast({
				title: "Invalid year",
				description: "Please select a year before continuing.",
				duration: 5000,
				isClosable: true
			})
			return;
		}
		const confirmed = window.confirm("You are about to update the year abstracts are being submitted for.\nDo you wish to continue?")
		if(confirmed) editRelevantYearMutation.mutate(yearToOpen)
	}

	const currentYear = new Date().getFullYear();
	const nextYear = currentYear + 1;
	const yearChoices = [currentYear, nextYear];


	return isPending ? <Skeleton/> : (
		<Feature>
			<Stat>
				<StatLabel fontSize={20}>SRC Year</StatLabel>
				<StatNumber fontSize="1.2em">{settings.relevant_year}</StatNumber>
				<StatHelpText>
					What year abstracts are being accepted for.
				</StatHelpText>
			</Stat>
			<Select
				onChange={(e) => setYearToOpen(e.target.value)}
				value={yearToOpen}
				mb={2}
				w={'12em'}
			>
				{yearChoices.map(year => (
					<option key={year} value={year}>{year}</option>
				))}
			</Select>
			<Button colorScheme="green" size="sm" w='fit-content' onClick={() => setSRCYear()}>
				Update Year
			</Button>

		</Feature>
	);
}
