import {
    Box,
    Heading,
    Text,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Textarea,
    HStack,
    Button,
    useToast,
    Link
 } from "@chakra-ui/react"
import axios from "axios";
import { useEffect } from "react";

 import { useState } from "react";
import AdminSideBar from "../../components/AdminSideBar";
import CustomMarkdown from "../Instructions/components/CustomMarkdown";

export default function InstructionsEdit(){

    const [infos, setInfos] = useState([]);

    const toast = useToast();

    useEffect(() => {
        async function loadData(){
            const res = await axios.get(`/api/instructions`);
            setInfos(res.data);
        }
        loadData();
    }
    ,[])

    const handleInputChange = (infoId, event) => {
        const val = event.target.value;
        setInfos(infos.map(info => info.id === infoId ? {
            ...info,
            body: val
        } : info))
    }

    async function updateInstructions(info){
        const res = await axios.patch(`/api/instructions/${info.title}`, info)
        if(200 <= res.status <= 300){
            toast({
                title: "Instructions Updated Successfully",
                description: "The instructions for \"" + info.title + "\" have been updated",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        }
        else{
            toast({
                title: "Instructions Update Failed",
                description: "The instructions for \"" + info.title + "\" were not updated",
                status: "danger",
                duration: 5000,
                isClosable: true,
            });
        }
    }

    return (
        <AdminSideBar>
            <Heading as="h1" size="lg">
                Edit Instructions
            </Heading>
            <Tabs>
                <TabList>
                    {infos.map(info => (
                        <Tab textTransform="capitalize">{info.title}</Tab>
                    ))}
                </TabList>

                <TabPanels>
                    {infos.map(info => (
                        <TabPanel>
                            <HStack align="stretch" mb={2}>
                            <Textarea
                                value={info.body}
                                minHeight="50ch"
                                minWidth="33%"
                                onChange={(event) => handleInputChange(info.id, event)}
                            />
                            <Box className="hide-on-mobile" height="50ch" overflow="scroll">
                                <CustomMarkdown
                                    markdown={info.body}
                                />
                            </Box>
                            </HStack>
                            <Text color="gray.600" my={3}>
                                Instruction pages support <Link color="blue.500" href="https://www.markdownguide.org/cheat-sheet/" target="_blank">markdown</Link>
                            </Text>
                            <Button colorScheme="green" onClick={() => updateInstructions(info)}>Save</Button>
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </AdminSideBar>
    )
}