import { Input, Box, Button, Card, CardBody, Flex, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Text, Textarea, useDisclosure, useToast, CardHeader, UnorderedList, ListItem } from "@chakra-ui/react";
import MainContainer from "../../components/MainContainer";
import { useQuery } from "@tanstack/react-query";
import { sessionsQueryFactory, settingsQuery } from "../../api/queries";
import { useEffect, useState } from "react";
import { sortSessions } from "../../utils";
import axios from "axios";
import {  } from "@chakra-ui/icons";
import { rankText } from "../Winners/components/WinnerIcon";
import WinnerSelect from "./components/WinnerSelect";

export default function ChairWinners() {

    const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)
    const { isPending: sessionsPending, data: sessions } = useQuery({
        ...sessionsQueryFactory(settings?.relevant_year),
        enabled: !!settings,
    })


    const loading = settingsPending
                    || sessionsPending;
    
    const [selectedSessionId, setSelectedSessionId] = useState("")
    const [winners, setWinners] = useState({})
    const { isOpen: confirmIsOpen, onOpen: confirmOnOpen, onClose: confirmOnClose } = useDisclosure()
    const [passwordVerified, setPasswordVerified] = useState(false)
    const [password, setPassword] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const toast = useToast()

    useEffect(() => {
        if(sessions && selectedSessionId){
            setWinners(selectedSession.abstracts.reduce((acc, abstract) => {
                acc[abstract.id] = abstract.winner;
                return acc;
            }, {}));
        }
    }, [selectedSessionId])

    if(loading) return <Spinner />

    const selectedSession = sessions.find(s => s.id.toString() === selectedSessionId.toString()) ?? null;
    const chair = selectedSession?.chair;

    async function verifyPassword(){
        const res = await axios.post("/api/verify-judge-password/", {judge_password: password})
        if(res.data.verified){
            setPasswordVerified(true)
            toast({
                title: "Password Verified",
                description: "You can now assign session winners.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        }
        else{
            toast({
                title: "Incorrect Password",
                description: "Please enter the correct password or contact sciencetech@byu.edu for help.",
                status: "error",
                duration: 10000,
                isClosable: true,
            })
        }
    }

    async function submitWinners(){

        const realWinners = Object.values(winners).filter((winner) => (winner !== null && winner !== ""));

        const stringWinners = realWinners.map(winner => winner.toString());

        const duplicateWinners = stringWinners.filter((value, index, self) => self.indexOf(value) !== index);

        if (duplicateWinners.length > 0) {
            toast({
                title: "Error",
                description: "You cannot select the same place for multiple winners.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        
        const promises = selectedSession.abstracts
            .map(a => axios.patch(`/api/chair-winners/${a.id}`,
                {
                    winner: winners[a.id] || null,
                    judge_password: password
                }));
        
        setSubmitting(true)
        Promise.allSettled(promises)
            .then(responses => {
                const someFailed = responses.some(result => result.status === 'rejected');
                if(!someFailed){
                    toast({
                        title: "Winners Submitted",
                        description: "The winners were successfully submitted. You can safely leave this page. For questions, contact your department coordinator.",
                        status: "success",
                        duration: 30000,
                        isClosable: true,
                    })
                    setSubmitted(true)
                }
            })
            .catch(error => {
                // handled by axios globally
            })
            .finally(() => {
                confirmOnClose()
                setSubmitting(false)
            })
    }

    if(!passwordVerified) return (
        <MainContainer>
            <Flex flexDirection="column" mx="auto">
                <form onSubmit={e => e.preventDefault()}>
                    <Heading>Authorization</Heading>
                    <Text mb={3}>
                        Enter the judge password.
                        This password should have been sent to you before the conference.
                    </Text>

                    <label htmlFor="judge-password-field">Password</label>
                    <Input type="password" id="judge-password-field" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <Button type="submit" colorScheme="green" my={3} onClick={verifyPassword}>
                        Authorize
                    </Button>
                </form>
            </Flex>
        </MainContainer>
    )

    return (
    <>
    <MainContainer>
        <Flex flexDirection="column" mx="auto">
            <Heading>Winner Selection</Heading>
            <Text py={2} color="gray.700">
                Chairs: use this page to assign winners for your session.
            </Text>

            <label htmlFor="select-session" style={{whiteSpace: "nowrap"}}>Select Your Session: </label>
            <Select
                disabled={submitted}
                placeholder="Select Session"
                value={selectedSessionId ?? ""}
                onChange={(e) => { 
                    setSelectedSessionId(e.target.value) 
                }}
                id="select-session">
                { sessions.sort(sortSessions).map(session => (
                    <option key={session.id} value={session.id}>{session.name}</option>
                ))}
            </Select>

            { selectedSession && (
            <>
                <Box mt={4}>
                    <Heading size="md">{chair?.full_name ?? "[No chair assigned to this session]"}</Heading>
                    <Text>If this isn't you, check that you selected the right session.</Text>
                </Box>

                <Heading as="h3" size="md" mt={3}>Number of winners</Heading>
                <Text maxW="60ch">
                    The {selectedSession.department.name} department has <strong>{selectedSession.department.winners_per_session}</strong> winner(s) per session.
                    { selectedSession.minutes_per_abstract <= 5 && (
                        <> However, this is a three-minute session, which may have different rules. Ask your department coordinator if you have any questions.</>
                    )}
                </Text>

                { selectedSession?.abstracts
                    .sort((a,b) => a.session_position - b.session_position)
                    .map(abstract => (
                    <Box mt={4} key={abstract.id}>
                        <Card maxWidth="80ch" variant="outline" borderColor={"gray.600"}>
                            <CardBody>
                                <HStack spacing={6}>
                                    <Heading size="md" whiteSpace="wrap">
                                        {abstract.presenters.map(p => p.full_name).join(", ")}
                                    </Heading>
                                    <WinnerSelect
                                        value={winners[abstract.id] ?? ""}
                                        onChange={(e) => setWinners({...winners, [abstract.id]: e.target.value})}
                                        session={selectedSession}
                                    />
                                </HStack>
                                <Text mt={2}>
                                    {abstract.title}
                                </Text>
                            </CardBody>
                        </Card>
                    </Box>
                ))}
                <Button
                    mt="4"
                    ml="auto"
                    colorScheme="green"
                    disabled={!selectedSessionId || submitted || submitting}
                    onClick={confirmOnOpen}>
                    {submitting ? "Submitting..." : submitted ? "Submitted" : "Submit Winners"}
                </Button>
            </>
            )}
        </Flex>
    </MainContainer>

    <Modal isOpen={confirmIsOpen} scrollBehavior={'inside'} onClose={confirmOnClose} isCentered>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Confirm Submission</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text>
                    You are about to submit winners for <strong>{selectedSession?.name ?? "[no session selected]"}</strong>:
                </Text>
                <Box mt={2}>
                    <UnorderedList>
                        {selectedSession?.abstracts
                        .sort((a,b) => winners[b.id] ?? "" - winners[a.id] ?? "")
                        .map(a => 
                            <ListItem key={a.id}>
                                <strong>{winners[a.id] ? rankText(winners[a.id]) : "Not winner"}</strong>: {a.title}
                            </ListItem>
                        )}
                    </UnorderedList>
                </Box>
                <Text>
                    <br />
                    Are you sure you want to submit?
                </Text>
            </ModalBody>
            <ModalFooter>
                <Button variant="ghost" onClick={confirmOnClose}>Cancel</Button>
                <Button colorScheme="green" onClick={submitWinners}>
                    Submit
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
    </>
    )
}