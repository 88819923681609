
import { 
    HamburgerIcon,
    EditIcon,
    SettingsIcon,
    DeleteIcon,
    RepeatIcon,
    InfoIcon,
} from "@chakra-ui/icons";

import {
    Menu,
    MenuButton,
    MenuList,
    IconButton,
    MenuItem,
} from "@chakra-ui/react";

import { useNavigate } from "react-router";
import APP_PAGES from "../../../page-constants";

export default function AbstractMenu({
	abstract = {},
	canDelete = false,
	canEdit = false,
    canShare = false,
    canTransfer = false,
    onOpenSubmenu
}){

    const navigate = useNavigate();

    return (
    <Menu>
        <MenuButton
            as={IconButton}
            icon={<HamburgerIcon />} />
        <MenuList>
            <MenuItem
                icon={<InfoIcon />}
                onClick={() => onOpenSubmenu("info")}
            >
                More Details
            </MenuItem>
            {canEdit &&
                <MenuItem
                    icon={<EditIcon />}
                    onClick={() => navigate(`${APP_PAGES.ABSTRACT_EDIT.redirect}/${abstract.id}`)}
                >
                    Edit Abstract
                </MenuItem>
            }
            {canShare && (
                <>
                <MenuItem
                    icon={<SettingsIcon />}
                    onClick={() => onOpenSubmenu("author")}
                >
                    Manage Co-Authors
                </MenuItem>

                <MenuItem
                    icon={<SettingsIcon />}
                    onClick={() => onOpenSubmenu("presenter")}
                >
                    Manage Presenters
                </MenuItem>
                </>
            )}
            {canTransfer && (
                <MenuItem
                    icon={<RepeatIcon />}
                    onClick={() => onOpenSubmenu("change owner")}
                >
                    Change Owner
                </MenuItem>
            )}
            {canDelete && (
            <MenuItem
                icon={<DeleteIcon />}
                onClick={() => onOpenSubmenu("delete")}
            >
                Delete Abstract
            </MenuItem>
            )}
        </MenuList>
    </Menu>
    )
}