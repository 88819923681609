import MainContainer from "../../components/MainContainer";
import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Heading, Container, Center, Skeleton, Stack, Box, Spinner } from "@chakra-ui/react";
import WinnerCard from "./components/WinnerCard";
import { strToDate } from "../../utils";
import DepartmentField from "../../components/DepartmentField";
import { UserContext } from "../../UserContext";
import { useQuery } from "@tanstack/react-query";
import { departmentsQuery, rightsQuery, sessionsQueryFactory, settingsQuery } from "../../api/queries";

export default function Winners(){
    
    const params = useParams();
    const year = parseInt(params.year);
    const user = useContext(UserContext)

    const { isPending: sessionsPending, data: sessions } = useQuery({...sessionsQueryFactory(year)})
    const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)
    const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)
    const { isPending: departmentsPending, data: departments } = useQuery(departmentsQuery)
    
    const loading = sessionsPending
                    || settingsPending
                    || rightsPending
                    || departmentsPending
    
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(-1);

    if(loading) return <Spinner />

    function getWinnersFromSession(session){
        return session.abstracts.filter(
            a => a.winner
        )
    }

    // convert now into the proper time zone
	const today = new Date();
	const conference_date = strToDate(settings.conference_date);
	
	// winners are visible one day after the conference
	function addDays(date, days) {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	  }

    // if you're changing this functionality,
    // make sure you also change the functionality on the homepage
    // for showing the winner button
	const winners_publicized_date = addDays(conference_date, 1);  

    // purely a frontend restriction on viewing winners
    // if someone wants to reverse engineer the api to 
    // view winners a day early
    // then they deserve it
    if(year === settings.relevant_year && winners_publicized_date > today && (user?.right_id ?? 1) < rights.department_coordinator.id)
    return (
    <MainContainer>
        <Container maxW="container.lg">
            <Center>
                <Heading as="h1" size="lg">
                    SRC {year} Winners
                </Heading>
            </Center>
            <Center>
                Winners will be announced shortly
            </Center>
        </Container>
    </MainContainer>
    )

    if(loading) return (
        <MainContainer>
            <Container maxW="container.lg">
                <Center>
                    <Heading as="h1" size="lg">
                        SRC {year} Winners
                    </Heading>
                </Center>
                <Stack>
                    <Skeleton height='60px' />
                    <Skeleton height='60px' />
                    <Skeleton height='60px' />
                </Stack>
            </Container>
        </MainContainer>
    )

    return (
        <MainContainer>
            <Container maxW="container.lg">
                <Center>
                    <Heading as="h1" size="lg">
                        SRC {year} Winners
                    </Heading>
                </Center>

                {sessions.length === 0 && (
                    <Center>No sessions found</Center>
                )}

                {sessions.length > 0 && (
                <DepartmentField
                	departments={departments}
                    selected={selectedDepartmentId}
                    onDepartmentChange={(id) => setSelectedDepartmentId(id)}
                    placeholder = "All Departments"
                    id = "department-field"
                />
                )}
                

                <Box height="60vh" overflow="auto">
                {sessions
                .filter(s => s.department.id === selectedDepartmentId || selectedDepartmentId === -1)
                .sort((a,b) => a.name < b.name ? -1 : 1)
                .map(s => (
                    <WinnerCard
                        key={s.id}
                        session={s}
                        winners={getWinnersFromSession(s)}
                    />
                ))}
                </Box>
            </Container>
        </MainContainer>
    )
}