import React, {useState} from "react";
import PropTypes from "prop-types";

import {
	FormControl,
	FormLabel,
	FormHelperText,
	Textarea,
	Stack,
	Input,
	Checkbox,
	Link,
} from "@chakra-ui/react";

import Feature from "../../Feature";
import { InlineTex } from "react-tex";

import PDF from "../../../assets/pdf/latex.pdf";

export const EXAMPLE_STRING = "$$\\int_{a}^{b} f(x)dx = F(b) - F(a)$$";

export default function AbstractContent({
	title = "",
	body = "",
	formError = false,
	onTitleChange,
	onBodyChange,
}) {

	const [preview, setPreview] = useState(false);

	return (
		<Feature title="Abstract">
			<Stack spacing={3}>
				<FormControl
					id="title"
					isRequired
					isInvalid={formError && title === ""}
				>
					<FormLabel>Title</FormLabel>
					<Input
						value={title}
						placeholder="Enter a title..."
						onChange={(e) => onTitleChange(e.target.value)}
					></Input>
				</FormControl>
				<FormControl id="body" isRequired isInvalid={formError && body === ""}>
					<FormLabel>Body</FormLabel>
					<Textarea
						value={body}
						placeholder="Enter an abstract body..."
						rows={5}
						onChange={(e) => onBodyChange(e.target.value)}
					/>
					<FormHelperText>
						You can use <Link color="blue.500" href={PDF} isExternal>Latex</Link> to include mathematical symbols in your abstract.
						<br />
						Wrap Latex symbols in double $'s (e.g. {EXAMPLE_STRING} )
					</FormHelperText>
				</FormControl>
				<FormControl id="preview">
					<Checkbox
						colorScheme="green"
						isChecked={preview}
						onChange={() => setPreview(!preview)}
					>
						Preview Latex
					</Checkbox>
				</FormControl>
				{preview ? <InlineTex texContent={body} /> : ""}
			</Stack>
		</Feature>
	);
}

AbstractContent.propTypes = {
	title: PropTypes.string,
	body: PropTypes.string,
	preview: PropTypes.bool,
	formError: PropTypes.bool,
	onTitleChange: PropTypes.func,
	onBodyChange: PropTypes.func,
	previewToggle: PropTypes.func,
};
