import React from "react";

import { Select } from "@chakra-ui/react";

export default function SessionField({
	sessions = [],
	selected = -1,
	onSessionChange = null,
    hide = false,
}) {
    if (hide)
        return "";
	return (
		<Select
			w="12em"
			placeholder="Select a Session"
			onChange={(e) => onSessionChange(parseInt(e.target.value) || -1)}
			value={isNaN(selected) ? -1 : selected}
		>
			{sessions.map((session) => (
				<option key={session.id} value={session.id}>
					{session.name}
				</option>
			))}
		</Select>
	);
}
