import APP_PAGES from "../../../page-constants"
import {Link as RouterLink} from "react-router-dom"
import { Button, Heading, Link, Stack, Text } from "@chakra-ui/react"
import Feature from "../../../components/Feature"
export default function SessionChangeRequests(){
    return (
        <Feature>
            <Heading as="h3" size="md">
                Session Change Requests
            </Heading>
            <Stack mt={3}>
                <Text color="gray.600">View students who have requested to change their session details (data science/three minute) after the submission period has ended.</Text>
                <Link as={RouterLink} to={APP_PAGES.SESSION_CHANGE_REQUESTS.path}><Button colorScheme="blue">Session Change Requests</Button></Link>
            </Stack>
        </Feature>
    )
}