import {
    Container,
    Center,
    Stack,
    Heading,
 } from "@chakra-ui/react"
import axios from "axios";
import { useEffect } from "react";

 import { useState } from "react";
 import { useParams } from "react-router-dom";
import MainContainer from "../../components/MainContainer";
import CustomMarkdown from "./components/CustomMarkdown";

export default function Instructions(){

    const {title} = useParams();
    const [info, setInfo] = useState([]);

    useEffect(() => {
        async function loadData(){
            const res = await axios.get(`/api/instructions/${title}`);
            setInfo(res.data);
        }
        loadData();
    }
    ,[title])

    return (
        <MainContainer>
		<Container maxW="container.md">
            <Center w="100%">
                <Stack w="100%" spacing={5}>
                    <Heading as="h1" size="xl" textTransform="capitalize">
                        {title} instructions
                    </Heading>

                    <CustomMarkdown
                        markdown={info.body}
                    />
                </Stack>
            </Center>
        </Container>
        </MainContainer>
    )
}