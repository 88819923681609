import React from "react";

import { Tr, Td, Skeleton } from "@chakra-ui/react";

// see src/styles.css for some custom styles for this component

export default function Abstract() {
	return (
		<>
		{[...Array(4)].map((_, rowIndex) => (
            <Tr key={rowIndex}>
                {[...Array(5)].map((_, colIndex) => (
                    <Td key={colIndex} py="10px">
                        <Skeleton h="20px" />
                    </Td>
                ))}
            </Tr>
        ))}
		</>
	);
}
