import React, { useEffect } from "react";
import {useState} from "react";

import {
	Button,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	useToast,
	Box,
	Spinner,
} from "@chakra-ui/react";
import Feature from "../../../components/Feature";
import { simpleDateFormat } from "../../../utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { editSetting } from "../../../api/mutationFns";
import { settingsQuery } from "../../../api/queries";

export default function ConferenceDateSetter() {
	const toast = useToast();
    const { isPending: settingsPending, data: settings } = useQuery(settingsQuery)
	const queryClient = useQueryClient()

	const editConferenceDateMutation = useMutation({
		mutationFn: (value) => editSetting("conference_date", value),
		onSuccess: () => {
			queryClient.invalidateQueries(settingsQuery.queryKey);
			toast({
				title: "Conference Date Updated",
				description:
					"The conference date has been successfully updated.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
		}
	})

	// "YYYY-MM-DD" format
	const [newDate, setNewDate] = useState(settings?.conference_date);
	useEffect(() => {
		if(!newDate) setNewDate(settings?.conference_date)
	}, [settings, newDate])

	return settingsPending ? <Spinner /> : (
		<Feature>
			<Stat>
				<StatLabel fontSize={20}>Conference Date</StatLabel>
				<StatNumber fontSize="1.2em">{simpleDateFormat(settings.conference_date)}</StatNumber>
				<StatHelpText>This date is shown on the SRC homepage.</StatHelpText>
			</Stat>
			<Box>
				<input type="date" value={newDate} onChange={(val) => {
					setNewDate(val.target.value);
				}} />
				<br />
				<Button mt="2" colorScheme="green" size="sm" onClick={() => editConferenceDateMutation.mutate(newDate)}>
					Update Conference Date
				</Button>
			</Box>
		</Feature>
	);
}
