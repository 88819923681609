import React from "react";
import { Skeleton, Stack, Select, FormLabel, FormControl, Text,
	FormHelperText, Flex, NumberInput,
	NumberInputField, NumberIncrementStepper, NumberInputStepper, NumberDecrementStepper, FormErrorMessage } from "@chakra-ui/react";

import BuildingField from "../../../components/BuildingField";
import NameField from "../../../components/NameField";
import DepartmentField from "../../../components/DepartmentField";
import FacultyDropdown from "../../../components/FacultyDropdown/index";

export default function DataFields({
	loading = false,
	setData = null,
	data = {},
	departments = [],
	faculties = [],
	buildings = [],
	rooms = [],
	endTime = "",
	originalData = {},
}) {

	if (loading) return <Skeleton h="10em" w="20em" my={3} />;
	return (
		<Stack spacing={3} my="1.5em">
			<NameField
				name={data.name}
				onNameChange={(value) => setData({ name: value })}
				required={true}
			/>
			<DepartmentField
				departments={departments}
				selected={data.department_id}
				onDepartmentChange={(value) => setData({ department_id: value })}
				required={true}
			/>
			<BuildingField
				buildings={buildings}
				rooms={rooms}
				selectedBuilding={data.building_id}
				selectedRoom={data.room_id}
				onRoomChange={(value) => setData({ room_id: value })}
				onBuildingChange={(value) => setData({ building_id: value })}
				showRoomInput={true}
				required={true}
			/>
			<Select
				onChange={(e) => setData({minutes_per_abstract: parseInt(e.target.value)})}
				value={data.minutes_per_abstract}
			>
				<option value={5}>3 Minute Abstracts</option>
				<option value={15}>15 Minute Abstracts</option>
			</Select>

			<FormControl display="flex" alignItems="center">
				<FormLabel mb={0} me="auto">Time&nbsp;Slots</FormLabel>
				<NumberInput
					value={data.num_slots}
					min={1}
					max={50}
					onChange={value => setData({ num_slots: value})}
				>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
			</FormControl>

			{originalData?.num_slots > data.num_slots && (
				<Text color="red">
					Warning:
					<br />
					You are trying to remove a time slot
					<br />
					with a scheduled abstract. Please
					<br />
					clear the affected time slot before
					<br />
					reducing the number of slots, or else
					<br />
					the system will prevent this number
					<br />
					from decreasing.
				</Text>
			)}

			<FormControl>
				<Flex flexDirection="row" alignItems="center" justifyContent="space-between">
					<FormLabel mb={0} htmlFor="start-time">Start Time</FormLabel>
					<input
						name="start-time"
						type="time"
						value={data.start_time}
						onChange={e => setData({start_time: e.target.value})}
					/>
				</Flex>

				<FormHelperText>Calculated End Time: {endTime}</FormHelperText>
			</FormControl>

			<FacultyDropdown
				label="Chair"
				placeholder="Select Chair"
				orientation="row"
				faculties={faculties}
				onFacultyChange={(value) => setData({ chair_id: value })}
				selectedFaculty={data.chair_id ? data.chair_id : -1}
			/>

			<FacultyDropdown
				label="First Judge"
				placeholder="Select Judge"
				orientation="row"
				faculties={faculties}
				selectedFaculty={data.judge_ids.length > 0 ? data.judge_ids[0] : -1}
				onFacultyChange={(value) => setData({ judge_ids:  [value, data.judge_ids.length > 1 ? data.judge_ids[1] : -1]})}
			/>

			{data.judge_ids.length > 0 && (
				<FacultyDropdown
					label="Second Judge"
					placeholder="Select Judge"
					orientation="row"
					faculties={faculties}
					selectedFaculty={data.judge_ids.length > 1 ? data.judge_ids[1] : -1}
					onFacultyChange={(value) => setData({ judge_ids:  [data.judge_ids[0], value]})}
				/>
			)}
		</Stack>
	);
}
