import React, {useState, useEffect, useContext} from "react"
import PropTypes from "prop-types";
import Feature from "../../Feature";
import { Skeleton, useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { rightsQuery } from "../../../api/queries";

import {
	Stack,
	Checkbox,
	FormControl,
	FormLabel,
	Select,
	FormHelperText,
	FormErrorMessage,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button
} from "@chakra-ui/react";
import axios from "axios";
import { settingsQuery } from "../../../api/queries";
import { UserContext } from "../../../UserContext";

export default function AbstractInfo({
	abstract,
	departments = [],
	degreeLevels = [],
	formError = false,
	onDegreeChange,
	onDepartmentChange,
	onThreeMinChange,
	onDataScienceChange,
	onChangeRequestsChange,
	editMode = false,
}) {
	departments = departments.filter(
		(department) => department.id !== 1 && department.id !== 4
	);

	const [dataSci_3min_check, setCheck] = useState(false)

	const [waitingForResponse, setWaitingForResponse] = useState(false);

	const toast = useToast();

	const settings = useQuery(settingsQuery)
	const user = useContext(UserContext)

	const submissionDeadline = new Date(settings.submission_deadline)
	const now = new Date()
	const disableSessionChanges = editMode && (now > submissionDeadline);

	const { isPending: rightsPending, data: rights } = useQuery(rightsQuery)

	useEffect(() => {
		setCheck(abstract.three_minute_session && abstract.data_science_session)
	}, [abstract.three_minute_session, abstract.data_science_session])

	const handleClose = () => {
		// validate data sci / 3 min if BEFORE the deadline
		// (don't validate AFTER deadline)
		// because it traps users in a loop of being unable to change
		// the checkboxes no matter what they do
		if(!disableSessionChanges){
			onThreeMinChange(false)
			onDataScienceChange(false)
		}
		setCheck(false)
	};

	async function createChangeRequest(request_type, requested_value){
		setWaitingForResponse(true);
		const request = {
			abstract: abstract.id,
			request_type,
			requested_value,
		}

		const res = await axios.post(`/api/session-change-request`, request)
		const newRequests = abstract.session_change_requests;
		newRequests.push(res.data)
		onChangeRequestsChange(newRequests);

		toast({
			title: "Request Created",
			description: "Request successfully created. You are now being considered for a " + (request_type === "data" ? "data science session" : "three minute session"),
			status: "success",
			duration: 5000,
			isClosable: true,
		});
		setWaitingForResponse(false);
	}

	async function deleteChangeRequest(pendingRequest){
		setWaitingForResponse(true);
		const res = await axios.delete(`/api/session-change-request/${pendingRequest.id}`, {data: {approved: false, disable_email: true}})
		onChangeRequestsChange(abstract.session_change_requests.filter(r => r.id !== pendingRequest.id));
		toast({
			title: "Request Withdrawn",
			description: "Request withdrawn. You are no longer being considered for a " + (pendingRequest.request_type === "data" ? "data science session" : "three minute session"),
			status: "warning",
			duration: 5000,
			isClosable: true,
		});
		setWaitingForResponse(false);
	}

	const departmentAllowsThreeMinute = departments.find(d => d.id === abstract.department.id)?.allows_three_min_sessions === true;

	const pendingThreeMinChangeRequest = abstract.session_change_requests?.find(r => r.request_type === '3min');
	const pendingDataSciChangeRequest = abstract.session_change_requests?.find(r => r.request_type === 'data');

	const DataSciChangeButton = () => {
		if(disableSessionChanges === false) return <></>
		if(!editMode) return <></>
		if(!pendingDataSciChangeRequest){
			return 	<Button colorScheme="blue" w="38ch" my={2} onClick={() => createChangeRequest("data", !abstract.data_science_session)} disabled={waitingForResponse}>
						{!waitingForResponse && <>Request change to {abstract.data_science_session ? 'normal' : 'data science'} session</>}
						{waitingForResponse && <>Submitting Request...</>}
					</Button>
		}
		else{
			return <Button colorScheme="yellow" w="38ch" my={2} onClick={() => deleteChangeRequest(pendingDataSciChangeRequest)} disabled={waitingForResponse}>
						{!waitingForResponse && <>Withdraw change request {pendingDataSciChangeRequest.requested_value ? "to" : "from"} data science</>}
						{waitingForResponse && <>Submitting Request...</>}
					</Button>
		}
	}

	const ThreeMinChangeButton = () => {
		if(disableSessionChanges === false) return <></>
		if(!editMode) return <></>
		if(!pendingThreeMinChangeRequest){
			return 	<Button colorScheme="blue" w="38ch" my={2} onClick={() => createChangeRequest("3min", !abstract.three_minute_session)} disabled={waitingForResponse}>
						{!waitingForResponse && <>Request change to {abstract.three_minute_session ? '15' : '3'} minute session</>}
						{waitingForResponse && <>Submitting Request...</>}
					</Button>
		}
		else{
			return <Button colorScheme="yellow" w="38ch" my={2} onClick={() => deleteChangeRequest(pendingThreeMinChangeRequest)} disabled={waitingForResponse}>
						{!waitingForResponse && <>Withdraw change to {pendingThreeMinChangeRequest.requested_value ? "3" : "15"} minute session</>}
						{waitingForResponse && <>Submitting Request...</>}
					</Button>
		}
	}

	if(rightsPending) return <Skeleton />

	return (
		<Feature title="Abstract Info">
			<Stack spacing={3} py={3}>
			<Modal blockScrollOnMount={false} isOpen={dataSci_3min_check} onClose={handleClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>CONFLICT</ModalHeader>
					<ModalCloseButton />
					<ModalBody>Multi-Disciplinary Data Science can't be 3 minutes. Please select one.</ModalBody>

					<ModalFooter>
						<Button variant="ghost" onClick={handleClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
				<FormControl isInvalid={formError && abstract.degree_level.id === ""} isRequired>
					<FormLabel>Degree Level</FormLabel>
					<Select
						placeholder="Select a degree level"
						value={abstract.degree_level.id}
						onChange={(e) => onDegreeChange(parseInt(e.target.value))}
					>
						{degreeLevels.map((degree) => (
							<option key={degree.id} value={degree.id}>
								{degree.name}
							</option>
						))}
					</Select>
					<FormErrorMessage>You must select a degree level</FormErrorMessage>
				</FormControl>
				<FormControl isRequired>
					<FormLabel>Department</FormLabel>
					<Select
						placeholder="Select a department"
						value={abstract.department.id}
						onChange={(e) => onDepartmentChange(parseInt(e.target.value))}
					>
						{departments.map((department) => (
							<option key={department.id} value={department.id}>
								{department.name}
							</option>
						))}
					</Select>
					<FormErrorMessage>You must select a department</FormErrorMessage>
				</FormControl>
				{
					// this form element only appears on comp sci abstracts
					departmentAllowsThreeMinute &&
					(
						<>
						<FormControl>
							<FormLabel>Presentation Type</FormLabel>
								<Checkbox
								id="num3m"
								colorScheme="red"
								isChecked={abstract.three_minute_session}
								onChange={(e) => onThreeMinChange(e.target.checked)}
								disabled={disableSessionChanges}
							>
								3 Minute Presentation
							</Checkbox>
							<FormHelperText>
								Select if your presentation will be only 3 minutes.
							</FormHelperText>
						</FormControl>
						<ThreeMinChangeButton />
						</>
				)}
			</Stack>
			{/* Only allow faculty to select data science (this may change in the future) */}
			{ user?.right_id >= rights.faculty.id &&
				<>
				<FormControl maxWidth="90ch">
					<FormLabel>Session Options</FormLabel>
					<Checkbox
						id="data_science_session"
						colorScheme="green"
						isChecked={abstract.data_science_session}
						onChange={(e) => onDataScienceChange(e.target.checked)}
						disabled={disableSessionChanges}
					>
						Multi-Disciplinary Data Science
					</Checkbox>
					<FormHelperText>
						Only advisors can see this option. Select if you are interested in participating in this inter-department session focused on data science. Abstracts not selected for data science sessions will be presented in their regular department sessions. Data science research generally involves drawing insights from large amounts of data (which may or may not be gathered by the the researcher) using statistical analysis, computational modeling, machine learning, and similar tools.
					</FormHelperText>
				</FormControl>
				<DataSciChangeButton />
				</>
			}
		</Feature>
	);
}

AbstractInfo.propTypes = {
	departments: PropTypes.array,
	advisors: PropTypes.array,
	degrees: PropTypes.array,
	degree: PropTypes.number,
	advisor: PropTypes.number,
	formError: PropTypes.bool,
	onDegreeChange: PropTypes.func,
	onDepartmentChange: PropTypes.func,
	onAdvisorChange: PropTypes.func,
	num3mToggle: PropTypes.func,
	onDataScienceChange: PropTypes.func,
};

/*
<Modal show={dataSci_3min_check} onHide={handleClose}>
				<Modal.Header className="modal-header" background-color="yellow" closeButton>
					<Modal.Title>Conflict</Modal.Title>
					</Modal.Header>
					<Modal.Body>Multi-Disciplincary Data Science can't be 3 minutes. Please select one. </Modal.Body>
					<Modal.Footer className="modal-footer">
					<Button variant="secondary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
			*/