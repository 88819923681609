import APP_PAGES from "../../../page-constants"
import {Link as RouterLink} from "react-router-dom"
import { Button, Heading, Link, Stack, Text } from "@chakra-ui/react"
import Feature from "../../../components/Feature"
export default function FacultyManagerLink(){
    return (
        <Feature>
            <Heading as="h3" size="md">
                Manage Faculty
            </Heading>
            <Stack mt={3}>
                <Text color="gray.600">Manage faculty records for your department, including guest faculty (alumni judges and chairs)</Text>
                <Link as={RouterLink} to={APP_PAGES.FACULTY_LIST.path}><Button colorScheme="blue">Manage Faculty</Button></Link>
            </Stack>
        </Feature>
    )
}