import React from "react";

import { Input, Box, FormLabel } from "@chakra-ui/react";

export default function NameField({
	placeholder = "Enter a name...",
	name = "",
	onNameChange = null,
	required = false,
	label = ""
}) {
	return (
		<Box>
		{label !== "" &&
			<FormLabel>{label}</FormLabel>
		}
		<Input
			width="15em"
			placeholder={placeholder}
			value={name}
			onChange={(e) => onNameChange(e.target.value)}
		/>
		{required &&
			<span className="required-indicator"></span>
		}
		</Box>
	);
}
